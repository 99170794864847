import { Button } from '@innovationdepartment/proxima-ui';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import { CategoryShoppersViewProps } from 'types/audiences';
import { useFormState } from 'react-final-form';
import LoadingSpinner from 'ui/LoadingSpinner';
import CreateSeedAudiencesNames from '../Layout/CreateSeedAudiencesNames';
import CreateSeedAudiencesDataSources from '../Layout/CreateSeedAudiencesDataSources';
import CreateSeedAudienceAdPlatform from '../Layout/CreateSeedAudiencesAdPlatform';
import CreateSeedAudiencesLookalikeSize from '../Layout/CreateSeedAudiencesLookalikeSize';
import CreateSeedAudiencesModalSection from '../Layout/CreateSeedAudiencesModalSection';

const CategoryShoppersView = (props: CategoryShoppersViewProps) => {
  const { loading, submitting, galaxies, canCreate } = props;
  const { valid } = useFormState();

  let buttonLabel = 'Generate & send to platform';
  const isLoading = loading || submitting || !canCreate;
  const showSpinner = loading && canCreate;
  const hasAvailableGalaxies = galaxies.length > 0;
  const isDisabled = !canCreate || isLoading;

  if (!hasAvailableGalaxies) buttonLabel = 'Galaxies not availble';
  else if (!canCreate) buttonLabel = 'Ad account not connected';

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <Button
          submit
          disabled={!valid || isLoading}
          startIcon={showSpinner && <LoadingSpinner />}
          label={buttonLabel}
        />
      }
    >
      <CreateSeedAudiencesModalSection>
        <CreateSeedAudiencesDataSources {...props} />
        <CreateSeedAudienceAdPlatform />
        <CreateSeedAudiencesLookalikeSize loading={loading} disabled={isDisabled} />
      </CreateSeedAudiencesModalSection>
      <CreateSeedAudiencesModalSection>
        <CreateSeedAudiencesNames {...props} />
      </CreateSeedAudiencesModalSection>
    </ModalBaseChildrenWrapper>
  );
};

export default CategoryShoppersView;
