import styled from '@emotion/styled';

export default styled.div<{ focused: boolean }>`
  position: relative;
  height: 246px;
  overflow: hidden;

  & > div {
    transition: transform 0.2s;
    width: 100%;
  }

  & > div:last-of-type {
    transform: ${({ focused }) => (focused ? 'translateX(100%)' : 'translateX(0)')};
  }

  & > div:first-of-type {
    position: absolute;
    transform: ${({ focused }) => (focused ? 'translateX(0)' : 'translateX(-100%)')};
  }
`;
