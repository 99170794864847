import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

const Spinner = styled.svg<{ size: number }>`
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke: ${colors.neutral100};
  stroke-linecap: round;
  animation: dash 3s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    80% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const LoadingSpinner = ({ size = 20 }: { size?: number }) => (
  <Spinner data-testid="loading-spinner" size={size} viewBox="0 0 8 8">
    <Circle cx="4" cy="4" r="2" fill="none" stroke-width="1" />
  </Spinner>
);

export default LoadingSpinner;
