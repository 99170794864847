const MagnifyingGlass = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="40" fill="#F5F5F5" />
    <g clipPath="url(#clip0_264_91567)">
      <path
        d="M53.1337 51.2546L45.747 43.868C47.2096 42.0046 48.0033 39.7035 48.0003 37.3346C48.0003 35.225 47.3747 33.1627 46.2027 31.4086C45.0306 29.6544 43.3647 28.2873 41.4156 27.4799C39.4665 26.6726 37.3218 26.4614 35.2527 26.8729C33.1836 27.2845 31.283 28.3004 29.7912 29.7922C28.2994 31.2839 27.2835 33.1845 26.872 35.2537C26.4604 37.3228 26.6716 39.4675 27.4789 41.4166C28.2863 43.3657 29.6535 45.0316 31.4076 46.2036C33.1617 47.3757 35.224 48.0013 37.3337 48.0013C39.7025 48.0043 42.0036 47.2106 43.867 45.748L51.2537 53.1346C51.3156 53.1971 51.3894 53.2467 51.4706 53.2806C51.5519 53.3144 51.639 53.3318 51.727 53.3318C51.815 53.3318 51.9021 53.3144 51.9834 53.2806C52.0646 53.2467 52.1384 53.1971 52.2003 53.1346L53.1337 52.2013C53.1962 52.1393 53.2457 52.0656 53.2796 51.9844C53.3134 51.9031 53.3309 51.816 53.3309 51.728C53.3309 51.64 53.3134 51.5528 53.2796 51.4716C53.2457 51.3904 53.1962 51.3166 53.1337 51.2546ZM37.3337 45.3346C35.7514 45.3346 34.2047 44.8654 32.8891 43.9864C31.5735 43.1073 30.5481 41.8579 29.9426 40.3961C29.3371 38.9343 29.1787 37.3258 29.4874 35.7739C29.7961 34.2221 30.558 32.7966 31.6768 31.6778C32.7956 30.559 34.2211 29.797 35.7729 29.4884C37.3248 29.1797 38.9333 29.3381 40.3951 29.9436C41.8569 30.5491 43.1064 31.5745 43.9854 32.8901C44.8645 34.2057 45.3337 35.7524 45.3337 37.3346C45.3337 39.4564 44.4908 41.4912 42.9905 42.9915C41.4902 44.4918 39.4554 45.3346 37.3337 45.3346Z"
        fill="#ABB8BA"
      />
    </g>
    <defs>
      <clipPath id="clip0_264_91567">
        <rect width="32" height="32" fill="white" transform="translate(24 24)" />
      </clipPath>
    </defs>
  </svg>
);

export default MagnifyingGlass;
