import { BrandSectionProps } from 'types/brandOverview';
import PageLayoutSectionView from '../../PageSectionLayout.View';
import LoadingSpinner from 'ui/LoadingSpinner';
import Tag from 'ui/Tag';
import { Text } from '@innovationdepartment/proxima-ui';

const BrandProfileView = ({ flavorCategory, apiLoading }: BrandSectionProps) => {
  let content = (
    <Text variant="body2" color="neutral80">
      -
    </Text>
  );

  if (apiLoading.flavorCategory) {
    content = <LoadingSpinner />;
  } else if (flavorCategory?.name) {
    content = <Tag label={flavorCategory.name} variant="tag1Medium" />;
  }

  return (
    <PageLayoutSectionView
      sections={[
        {
          label: 'Primary category',
          content,
        },
      ]}
    />
  );
};

export default BrandProfileView;
