import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'types/featureFlag';

/* TODO(Jenky): might implement longer logic, but for now lets use this wrapper instead of LD's */
const useFeatureFlag = () => {
  const flags = useFlags<FeatureFlags>();

  return flags;
};

export default useFeatureFlag;
