import { BillingMappings, BrandInfoProps } from 'types/brandOverview';
import { SubscriptionType, SubscriptionStatusEnum } from '@innovationdepartment/proxima-sdk-axios';
import { formatDate } from 'utils';
import BrandOverviewPlanView from './BrandOverviewPlan.View';

const BrandOverviewPlan = ({ brandSubscription, apiLoading }: BrandInfoProps) => {
  const stripeMetadata = (brandSubscription.metadata as Record<string, string>) ?? {};
  let plan = '-';
  let nextBillingDate;

  let nextBillingDateLabel = 'Next billing date';

  const planInMetadata = stripeMetadata.plan in BillingMappings;
  const nextBillingDateInMetadata = Boolean(brandSubscription.currentPeriodEnd);
  const isTrial =
    stripeMetadata.plan === SubscriptionType.FreeTrial ||
    brandSubscription.status === SubscriptionStatusEnum.Trialing;
  const isActive = brandSubscription.status === SubscriptionStatusEnum.Active || isTrial;

  if (isActive) {
    if (planInMetadata) {
      // use the conventional naming of our predefined plans
      plan = BillingMappings[stripeMetadata.plan as keyof typeof BillingMappings];
    } else if (stripeMetadata.plan) {
      // sometimes 'plan' comes as a custom plan - we'll use that as a fallback
      plan = stripeMetadata.plan;
    }

    if (nextBillingDateInMetadata) {
      nextBillingDate = formatDate(new Date(brandSubscription.currentPeriodEnd as string));
    }

    if (isTrial) {
      nextBillingDateLabel = 'Trial ends';
    }
  } else {
    plan = 'No plan';
  }

  return (
    <BrandOverviewPlanView
      plan={plan}
      nextBillingDate={nextBillingDate}
      nextBillingDateLabel={nextBillingDateLabel}
      loading={apiLoading.billing}
    />
  );
};

export default BrandOverviewPlan;
