import { BrandWarning } from '@innovationdepartment/proxima-sdk-axios';
import PageLayoutSectionView from '../BrandOverview/Layout/PageSectionLayout.View';
import { formatDateTimeFromIso } from 'utils/formatDate';

type BrandWarningSectionViewProps = {
  brandWarnings: BrandWarning[];
};

const BrandWarningSectionView = (props: BrandWarningSectionViewProps) => {
  const { brandWarnings } = props;
  const sections = brandWarnings.slice(0, 3).map((warning) => ({
    label: warning.message,
    content: formatDateTimeFromIso(warning.createdAt),
  }));
  return <PageLayoutSectionView alignRight sections={sections} />;
};

export default BrandWarningSectionView;
