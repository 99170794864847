import { Link } from 'react-router-dom';

const NotFound = () => (
  <div style={{ padding: 24 }}>
    <h2>Page Not Found</h2>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Link to="/brands">Brands</Link>
    </div>
  </div>
);

export default NotFound;
