import LoadingSpinner from 'ui/LoadingSpinner';
import PageLayoutSectionView from '../../PageSectionLayout.View';
import AudienceUsageAudienceSpendLabel from './AudienceUsage.AudienceSpendLabel';
import { AudiencesSectionProps } from 'types/brandOverview';
import { formatCurrency, formatDate } from 'utils';

const AudienceUsageView = (props: AudiencesSectionProps) => {
  const { audienceSpend, apiLoading, audienceSpendSummary, audienceStats } = props;

  const getContent = (data: number, loading: boolean) => {
    if (loading) return <LoadingSpinner />;
    return formatCurrency(data);
  };

  const initialAudienceSpendDate = audienceSpendSummary.initialSpendDate
    ? formatDate(new Date(audienceSpendSummary.initialSpendDate))
    : '-';

  return (
    <PageLayoutSectionView
      alignRight
      sections={[
        {
          label: 'Total audiences generated',
          content: audienceStats.totalAudiences?.total ?? 0,
        },
        {
          label: 'Audiences active',
          content: audienceStats.activeAudiences?.total ?? 0,
        },
        {
          label: 'First audience spend date',
          content: initialAudienceSpendDate,
        },
        // {
        //   label: 'Total spend on Proxima audiences',
        //   content: getContent(
        //     audienceSpendSummary.lifetimeSpend ?? 0,
        //     apiLoading.audiencesSpendSummary as boolean,
        //   ),
        // },
        {
          label: <AudienceUsageAudienceSpendLabel {...props} />,
          content: getContent(audienceSpend.totalSpend ?? 0, apiLoading.audiencesSpend as boolean),
        },
      ]}
    />
  );
};

export default AudienceUsageView;
