import { useBackgroundConditions } from 'hooks';
import { useState } from 'react';
import IntegrationDisconnectionWarningBanner from 'ui/IntegrationDisconnectionWarningBanner';
import ProximaBackground from 'ui/ProximaBackground';

const RouterWrapperView: React.FC = ({ children }) => {
  const { showNavbar, showBackdrop } = useBackgroundConditions();
  const [addPadding, setAddPadding] = useState(false);

  return (
    <ProximaBackground
      showCurve={showNavbar}
      showBackdrop={showBackdrop}
      addBottomPadding={addPadding}
    >
      {children}
      <IntegrationDisconnectionWarningBanner onAddPadding={setAddPadding} />
    </ProximaBackground>
  );
};

export default RouterWrapperView;
