import { Brand } from '@innovationdepartment/proxima-sdk-axios';
import { useState } from 'react';
import { BRANDS_LIST_PAGE_LIMIT } from 'constants/defaults';
import useProximaSDK from './useProximaSDK';

type BrandRequest = {
  direction: 'next' | 'previous';
  page?: number;
  search?: string;
  plans?: string;
  spend?: string;
};

type FetchBrand = {
  search?: string;
  spend?: string;
  plans?: string;
};

export default function useBrandsElasticSearch() {
  const [pagination, setPagination] = useState({
    pageNumber: 0, // brand pages in api start at 1
    totalPages: 1,
    hasNext: true,
    hasPrevious: false,
  });
  const [loading, setLoading] = useState(false);
  const brandsApi = useProximaSDK('BrandsApi');

  const handleBrandsRequest = async (params: BrandRequest) => {
    let brands: Brand[] = [];
    const { direction = 'next', page, search, plans, spend } = params;
    const isRequestingNext = direction === 'next';

    const pageNumber = page ?? pagination.pageNumber;

    /* make sure we don't go past the range of pages */
    const pageToFetch = isRequestingNext
      ? Math.min(pageNumber + 1, pagination.totalPages)
      : Math.max(pageNumber - 1, 1);

    try {
      setLoading(true);
      const response = await brandsApi.getAllBrands({
        pageSize: BRANDS_LIST_PAGE_LIMIT,
        pageNumber: pageToFetch,
        search,
        plans,
        spend,
      });

      const { data, total = BRANDS_LIST_PAGE_LIMIT } = response.data;

      const totalPages = Math.ceil(total / BRANDS_LIST_PAGE_LIMIT) || 1;

      brands = data as Brand[];

      /* booleans that help which buttons to show in pagination */
      const hasNext = pageToFetch < totalPages;
      const hasPrevious = pageToFetch > 1;

      const nextPagination = {
        pageNumber: pageToFetch,
        totalPages,
        hasNext,
        hasPrevious,
      };

      setPagination(nextPagination);
    } catch (error) {
      // TODO: handle error
    }

    setLoading(false);
    return brands;
  };

  const getBrands = async (params: FetchBrand = {}) =>
    handleBrandsRequest({ ...params, direction: 'next', page: 0 });

  const getNextBrandBatch = async (params: FetchBrand = {}) =>
    handleBrandsRequest({ ...params, direction: 'next' });

  const getPreviousBrandBatch = async (params: FetchBrand = {}) =>
    handleBrandsRequest({ ...params, direction: 'previous' });

  const { hasNext, hasPrevious } = pagination;

  return {
    loading,
    hasNext,
    hasPrevious,
    getBrands,
    getNextBrandBatch,
    getPreviousBrandBatch,
  };
}
