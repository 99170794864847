import PageLayoutSectionView from '../../PageSectionLayout.View';
import LoadingSpinner from 'ui/LoadingSpinner';

type BrandOverviewPlanProps = {
  plan: React.ReactNode;
  loading?: boolean;
  nextBillingDateLabel?: string;
  nextBillingDate?: string;
};
const BrandOverviewPlanView = ({
  plan,
  nextBillingDate,
  nextBillingDateLabel,
  loading,
}: BrandOverviewPlanProps) => {
  let billingSections = [
    {
      label: 'Current plan',
      content: loading ? <LoadingSpinner /> : plan,
    },
    // {
    //   label: 'Previous plan',
    //   content: '-', // TODO(Jenky): we're missing this data
    // },
    {
      label: nextBillingDateLabel,
      content: nextBillingDate,
    },
  ];

  // if loading, just leave the upper (current plan) section
  if (loading) {
    billingSections = billingSections.slice(0, 1);
  }

  // remove empty content sections
  billingSections = billingSections.filter((section) => Boolean(section.content));

  return <PageLayoutSectionView sections={billingSections} />;
};

export default BrandOverviewPlanView;
