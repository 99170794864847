import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

export const ContentWrapper = styled.div<{ flow?: 'column' | 'row'; gap?: number }>`
  display: flex;
  flex-flow: ${({ flow = 'row' }) => flow};
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ gap = 8 }) => gap}px;

  & > * {
    flex: 1;
  }
`;

export const ConnectionBadge = styled.div<{ connected?: boolean }>`
  display: flex;
  padding: 2px 8px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid ${colors.neutral20};
  position: relative;

  &:before {
    content: '';
    margin-right: 2px;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ connected }) => (connected ? colors.green40 : colors.red50)};
  }
`;

export const FieldWrapper = styled.div<{ shrink?: boolean }>`
  position: relative;
  width: ${({ shrink }) => (shrink ? 'auto' : '100%')};
`;
