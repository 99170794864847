import FiltersView from './Filters.View';

type FiltersProps = {
  onFilterPlan: (selectedPlans: string[]) => void;
  onFilterSpend: (selectedItems: string[]) => void;
  clearFilters: () => void;
  hasSelectedFilters: boolean;
};

const Filters = ({
  onFilterPlan,
  onFilterSpend,
  clearFilters,
  hasSelectedFilters,
}: FiltersProps) => (
  <FiltersView
    hasSelectedFilters={hasSelectedFilters}
    onFilterPlan={onFilterPlan}
    onFilterSpend={onFilterSpend}
    clearFilters={clearFilters}
  />
);

export default Filters;
