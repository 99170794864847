import {
  BrandItem,
  FullProfile,
  PartialProfile,
  UserBrandAccess,
} from '@innovationdepartment/proxima-sdk-axios';

export enum UserRole {
  Admin = 'admin',
  ReadOnly = 'readOnly',
}

export enum UserStatus {
  Active = 'Active',
  Invited = 'Invited',
}

export type User = FullProfile &
  PartialProfile &
  UserBrandAccess & { status: UserStatus; associatedBrands: BrandItem[] };

export type UserActionProps = {
  userId: string;
  onClose: () => void;
  setLoading: (loading?: boolean) => void;
  fetchBrandUsers: () => void;
  verified?: boolean;
  disabled?: boolean;
  showActions?: boolean;
};

export type FormattedUser = User & { menu: UserActionProps | null };
