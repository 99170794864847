import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

type BrandOverviewHeaderItemViewProps = {
  /**
   * allows to copy the content to clipboard
   */
  label: string;
  onCopyToClipboard?: () => void;
  /**
   * use to readjust when title is pushed by its content
   */
  top?: number;
  content: string | React.ReactNode;
};

const Wrapper = styled.div<{ top?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > span {
    ${({ top }) => top && `margin-top: ${top}px;`}
  }
`;

const TextWrapper = styled.div<{ canCopy?: boolean }>`
  ${({ canCopy }) => (canCopy ? 'cursor: pointer;' : '')}
`;

const BrandOverviewHeaderItemView = ({
  label,
  content,
  onCopyToClipboard,
  top,
}: BrandOverviewHeaderItemViewProps) => {
  let textContent = content;

  if (typeof content === 'string') {
    const canCopy = !!onCopyToClipboard;
    textContent = (
      <TextWrapper canCopy={canCopy} onClick={onCopyToClipboard}>
        <Text variant="body2" color="neutral80">
          {content}
        </Text>
      </TextWrapper>
    );
  }

  return (
    <Wrapper top={top}>
      <Text variant="body3" color="neutral60">
        {label}
      </Text>
      {textContent}
    </Wrapper>
  );
};

export default BrandOverviewHeaderItemView;
