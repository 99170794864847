import { Text } from '@innovationdepartment/proxima-ui';
import { LookalikeAudienceTableRowProps, AudienceTableCellRendererProps } from 'types/audiences';
import VerticalWrapper from './AudienceRowCell.Wrapper';

const AudienceRowCellLookalikeAudience = ({
  row,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps>) => (
  <VerticalWrapper height={36}>
    <Text variant="table1Link" color="neutral100">
      {row?.name}
    </Text>
  </VerticalWrapper>
);

export default AudienceRowCellLookalikeAudience;
