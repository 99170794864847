import { CreativeType } from '../types';
import styled from '@emotion/styled';
import { colors, ImageIcon, Text, Tooltip, VideoIcon } from '@innovationdepartment/proxima-ui';

const RenderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${colors.neutral10};
  padding: 2px 4px;
  border-radius: 4px;
`;

const performanceAdsCellRenderCreativeType = (props: { value: CreativeType }) => {
  const { value } = props;

  if (!value) return null;

  let icon = <ImageIcon />;
  let label = 'Static';

  switch (value as CreativeType) {
    case CreativeType.VIDEO:
      icon = <VideoIcon />;
      label = 'Video';
      break;
    default:
      /* defaulting to static for now, until we figure out the rest of the creatives */
      icon = <ImageIcon />;
      label = 'Static';
      break;
  }

  const tooltipLabel = (
    <Text variant="tag2Semibold" color="white">
      {label}
    </Text>
  );

  return (
    <Tooltip placement="right" arrow title={tooltipLabel}>
      <RenderWrapper>
        {icon}
        <Text variant="tag2Semibold" color="neutral90">
          {label}
        </Text>
      </RenderWrapper>
    </Tooltip>
  );
};

export default performanceAdsCellRenderCreativeType;
