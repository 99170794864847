import styled from '@emotion/styled';
import { Button, FormField, Text } from '@innovationdepartment/proxima-ui';
import { useFormState } from 'react-final-form';

type EditDataIngestionPermissionsModalViewProps = {
  onClose: () => void;
  isSubmitting: boolean;
};

const ButtonGroup = styled.div`
  display: inline-flex;
  justify-content: end;
  width: 100%;
  button:first-of-type {
    margin-right: 1rem;
  }
  padding: 20px 0 4px 0;
`;

const Container = styled.div`
  width: 382px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 0 40px 0;
`;

const InputContainer = styled.div`
  width: unset;
`;

const EditDataIngestionPermissionsModalView = (
  props: EditDataIngestionPermissionsModalViewProps,
) => {
  const { isSubmitting, onClose } = props;

  const formState = useFormState();
  const { valid, dirty } = formState;

  return (
    <Container>
      <ContentContainer>
        <Text variant="body1Semibold" color="neutral90">
          Customer lists
        </Text>
        <InputContainer data-testid="isFileUploadAllowed-toggle">
          <FormField name="isFileUploadAllowed" type="switch" size="small" />
        </InputContainer>
      </ContentContainer>
      <ButtonGroup>
        <Button variant="outlined" color="secondary" label="Cancel" onClick={onClose} />
        <Button submit size="small" disabled={!valid || !dirty || isSubmitting} label="Apply" />
      </ButtonGroup>
    </Container>
  );
};

export default EditDataIngestionPermissionsModalView;
