import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

import { useBrandStore } from 'stores';

const BrandNameWrapper = styled.div`
  border: 1px solid ${colors.neutral60};
  border-radius: 4px;
  color: ${colors.neutral60};
  font-weight: bold;
  margin-left: 16px;
  padding: 4px 8px;
`;

const LoggedInAsBrandBadge = () => {
  const { brand } = useBrandStore();

  if (!brand?.brandId) return null;

  return <BrandNameWrapper>{brand.name}</BrandNameWrapper>;
};

export default LoggedInAsBrandBadge;
