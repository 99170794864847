import { PropsOf } from '@emotion/react';
import { DatePicker } from '@innovationdepartment/proxima-ui';
import FBTable from '@innovationdepartment/proxima-fbtable/dist/esm/lib/FBTable';
import { Ad } from './fbData/ad';
import { Adset } from './fbData/adset';
import { Campaign } from './fbData/campaign';
import { AdAccount } from './stores/ad-account-store';

export const PAGE_DEFAULT_SIZE = 20;

export type AdManager = 'ad' | 'adset' | 'campaign';
export type FBTableTabs = `${AdManager}s`;
export type PaginationSettings = {
  pageSize?: number;
  pageNumber?: number;
  queryItems?: Record<string, string | string[]>;
};

export type PageSettings = {
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
};

export type StateData<T> = {
  data: T[];
  summary?: any; // TODO(Jenky): Define these props
};

export type CampaignData = StateData<Campaign>;
export type AdsetData = StateData<Adset>;
export type AdData<T = {}> = StateData<Ad & T>;

export type FacebookTableViewProps = {
  initialLoading: boolean;
  loading: boolean;
  adAccount: AdAccount | null;
  lastUpdate?: string;
  // TODO: Address these data types
  campaigns: CampaignData;
  adsets: AdsetData;
  ads: AdData;
  tab: FBTableTabs;
  dateRange: PropsOf<typeof DatePicker>['dateRange'];
  onDateRangeChange: (dates: FacebookTableViewProps['dateRange']) => void; // PropsOf<typeof FacebookTableSearchFilter>['onDateRangeChange'];
  onNext?: () => void;
  onPrevious?: () => void;
  onTabClick: PropsOf<typeof FBTable>['onTabClick'];
  onRowSelect: PropsOf<typeof FBTable>['onRowSelect'];
};
