import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerformanceAdsView from './PerformanceAds.View';
import { usePerformanceAdManager } from 'hooks';
import { DashboardIconSmall, HeatmapSmall, IToggleOption } from '@innovationdepartment/proxima-ui';

type Filters = {
  brand: boolean;
};

type PerformanceView = 'table' | 'tiles';

const PerformanceAds = () => {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState<Filters>({ brand: false });
  const [view, setView] = useState<PerformanceView>('table');
  const { ads, loaded, loading, onRequestNext, onApplyFilters, dateRange, onDateRangeChange } =
    usePerformanceAdManager();

  const onGoBackClick = () => navigate('/brands');

  /* opens/closes filters */
  const onFilterShow = (filter: keyof Filters, show: boolean = true) => {
    setShowFilters((prev) => ({ ...prev, [filter]: show }));
  };

  const viewOptions: IToggleOption[] = [
    { label: 'Switch to Table View', value: 'table', icon: <DashboardIconSmall /> },
    { label: 'Switch to Tiles View', value: 'tiles', icon: <HeatmapSmall /> },
  ];

  const toggleView = (newView: string | number) => {
    if (loading) return;
    if (!newView || newView === view) return;
    setView(newView as PerformanceView);
  };

  return (
    <PerformanceAdsView
      view={view}
      viewOptions={viewOptions}
      toggleView={toggleView}
      dateRange={dateRange}
      onDateRangeChange={onDateRangeChange}
      onApplyFilters={onApplyFilters}
      onShowFilters={onFilterShow}
      showFilters={showFilters}
      onGoBackClick={onGoBackClick}
      ads={ads}
      loaded={loaded}
      loading={loading}
      onRequestNext={onRequestNext}
    />
  );
};

export default PerformanceAds;
