import { useEffect, useState } from 'react';
import {
  BrandItem,
  FullProfile,
  PartialProfile,
  UserBrandAccess,
} from '@innovationdepartment/proxima-sdk-axios';
import chunk from 'lodash/chunk';
import { TableConfig } from '@innovationdepartment/proxima-ui';

import { UserActionProps, User } from 'types/users';

import { useProximaSDK, useShowSpinner } from 'hooks';
import { useBrandStore, useIntegrationsStore } from 'stores';

import InviteUserModal from 'ui/Modals/InviteUserModal';
import UsersView from './Users.View';
import userRowRender from './userRowRender';
import { IntegrationType } from 'types/integrations';
import { getCurrentAndPreviousMetaOwner, formatUserData } from './usersUtils';

const BRAND_BATCH_LIMIT = 30;

const Users = () => {
  const usersApi = useProximaSDK('UsersApi');
  const brandsApi = useProximaSDK('BrandsApi');

  const { brand } = useBrandStore();
  const { getAllIntegrations } = useIntegrationsStore();
  const { brandId = null } = brand ?? {};

  const [hoverUserId, setHoverUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

  useShowSpinner({ show: loading });

  const integrations = getAllIntegrations({ type: IntegrationType.Facebook });

  const [fbOwner, fbPreviousOwner] = getCurrentAndPreviousMetaOwner(integrations, users);

  const fetchBrandUsers = async () => {
    if (!brandId) return;

    const getUsersForBrand = async (): Promise<UserBrandAccess[]> => {
      try {
        const response = await brandsApi.getAllUsersForBrand({ brandId });
        return response.data as UserBrandAccess[];
      } catch (e) {
        setLoading(false);
        return [];
      }
    };

    const getUsersAssociatedInformation = async (
      accessUsers: UserBrandAccess[],
    ): Promise<User[]> => {
      if (!accessUsers.length) return [];

      const getUsersProfileInformation = async (
        userIds: UserBrandAccess[],
      ): Promise<FullProfile[]> => {
        try {
          const response = await usersApi.getProfileBatchPii({
            userId: userIds.map((user) => user.userId).join(','),
          });
          return response.data as FullProfile[];
        } catch (e) {
          /*  */
        }
        return [];
      };

      type UsersWithBrands = { [userId: string]: BrandItem[] };
      const getUsersAssociatedBrands = async (
        usersAccess: UserBrandAccess[],
      ): Promise<UsersWithBrands> => {
        try {
          const userProfileResponse = await usersApi.getAllBrandsForUserBatch({
            userId: usersAccess.map((user) => user.userId).join(','),
          });

          /* fetch brands in chunks, as we're limited with how many PKs we can pass to partiQl */
          const fetchBrandsDataInChunks = async (brandIds: string[]) => {
            const brandsResponse = await brandsApi.getBrandBatch({
              brandId: brandIds.join(','),
            });

            return brandsResponse.data;
          };

          const brandIds = userProfileResponse.data.map((b) => b.brandId);
          const unduppedBrandIds = [...new Set(brandIds)];

          const chunkedBrandIds = chunk(unduppedBrandIds, BRAND_BATCH_LIMIT);
          const chunkedBrands = await Promise.all(chunkedBrandIds.map(fetchBrandsDataInChunks));

          const unfilteredBrands = chunkedBrands.flat();

          const mappedBrandsToUsers = userProfileResponse.data.reduce(
            (acc, user: UserBrandAccess) => {
              if (!acc[user.userId]) acc[user.userId] = [];
              /* filter only brands that the user is part of */
              const brands = unfilteredBrands.filter((b: BrandItem) => b.brandId === user.brandId);

              /* include them as part of the array */
              acc[user.userId].push(...brands);
              return acc;
            },
            {} as UsersWithBrands,
          );

          return mappedBrandsToUsers;
        } catch (err) {
          /*  */
        }
        return {};
      };

      /* get users associated information */
      const [usersWithInfo, usersAssociatedBrands] = await Promise.all([
        getUsersProfileInformation(accessUsers),
        getUsersAssociatedBrands(accessUsers),
      ]);

      return usersWithInfo.map((profile) => {
        const user = (accessUsers.find((access) => profile.userId === access.userId) ??
          {}) as PartialProfile & FullProfile;

        const associatedBrands = usersAssociatedBrands[user.userId] ?? [];

        associatedBrands.sort((a, b) => a.name.localeCompare(b.name));

        return { ...user, ...profile, associatedBrands } as User;
      });
    };

    setLoading(true);
    const usersFromBrand = await getUsersForBrand();
    const usersWithInformation = await getUsersAssociatedInformation(usersFromBrand);
    setUsers(usersWithInformation);
    setLoading(false);
  };

  const handleOpenModal = () => setInviteUserModalOpen(true);
  const handleCloseModal = (invitedEmail?: string) => {
    setInviteUserModalOpen(false);
    if (invitedEmail) fetchBrandUsers();
  };

  const formatUser = (user: User) => {
    const { userId, roleId } = user;

    const menu: UserActionProps = {
      userId,
      onClose: () => setHoverUserId(null),
      setLoading: (isLoading?: boolean) => setLoading(!!isLoading),
      fetchBrandUsers,
      showActions: hoverUserId === userId,
    };

    return formatUserData({
      ...user,
      roleId,
      isFBOwner: userId === fbOwner,
      isFBPreviousOwner: userId === fbPreviousOwner,
      menu,
    });
  };

  const usersListConfig: TableConfig<User> = {
    onRowMouseEnter: (row: any) => {
      setHoverUserId(row.userId);
    },
    onRowMouseLeave: () => {
      setHoverUserId(null);
    },
    formatter: formatUser,
    cellRenderer: userRowRender as TableConfig<User>['cellRenderer'],
    columns: {
      order: [
        'name',
        'email',
        'status',
        'role',
        'phoneNumber',
        'associatedBrands',
        'lastLoginAt',
        'menu',
      ],
      name: { label: 'Name', width: 200 },
      email: { label: 'Email address' },
      phoneNumber: { label: 'Phone number' },
      status: { label: 'Status' },
      associatedBrands: { label: 'Associated brands' },
      role: { label: 'Role' },
      lastLoginAt: { label: 'Last login date', width: 154 },
      menu: { label: '', width: 56 },
    },
  };

  useEffect(() => {
    if (!brandId) return;
    fetchBrandUsers();
  }, [brandId]);

  return (
    <>
      <UsersView users={users} tableConfig={usersListConfig} addUserButtonClick={handleOpenModal} />
      <InviteUserModal open={inviteUserModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Users;
