import { AudiencesSectionProps } from 'types/brandOverview';
import { DatePicker, Text } from '@innovationdepartment/proxima-ui';
import * as date from 'date-fns';
import styled from '@emotion/styled';

const last30DaysFirstDay = date.startOfDay(date.sub(new Date(), { days: 30 }));
const last30DaysLastDay = date.startOfDay(date.endOfYesterday());

const dateRange = { endDate: last30DaysLastDay, startDate: last30DaysFirstDay };

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  /* makes date picker dropdown responsive */
  & .MuiInputBase-root {
    width: fill-available;
  }

  /* aligns text next to dropdown */
  & + * {
    align-self: flex-end;
    margin-bottom: 8px;
  }
`;

const AudienceUsageAudienceSpendLabel = (props: AudiencesSectionProps) => {
  const { onAudienceDateRangeSelect } = props;

  return (
    <HeaderWrapper>
      <Text variant="body2Semibold" color="neutral60">
        Proxima audience spend
      </Text>
      <DatePicker
        disableFutureDates
        dateRange={dateRange}
        onDateRangeSelect={onAudienceDateRangeSelect}
      />
    </HeaderWrapper>
  );
};

export default AudienceUsageAudienceSpendLabel;
