import { AllowedColors, IMenuItem, MoreMenu, colors } from '@innovationdepartment/proxima-ui';
import {
  AudienceTableCellRendererProps,
  LookalikeAudienceTableRowProps,
  LookalikeAudienceTableRowExtraFunctions,
  LookalikeAudienceStatus,
} from 'types/audiences';

const LookalikeAudienceRowCellActions = (
  props: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps> &
    LookalikeAudienceTableRowExtraFunctions,
) => {
  const { row, onShowRenameLookalikeAudienceModal, onShowDeleteLookalikeAudienceModal } = props;

  if (!row) {
    return null;
  }

  const { displayStatus } = row;
  const audienceDelivered = displayStatus === LookalikeAudienceStatus.Delivered;
  const audienceDeleteFailed = displayStatus === LookalikeAudienceStatus.DeleteFailed;

  const handleClick = (action: string) => {
    switch (action) {
      case 'delete':
        onShowDeleteLookalikeAudienceModal(row);
        break;
      case 'rename':
        onShowRenameLookalikeAudienceModal(row);
        break;
      default:
        break;
    }
  };

  const items: IMenuItem[] = [
    { label: 'Rename', value: 'rename', disabled: !audienceDelivered },
    {
      label: 'Delete',
      value: 'delete',
      disabled: !audienceDelivered && !audienceDeleteFailed,
      textColor: colors.red70 as AllowedColors,
    },
  ];

  return (
    <MoreMenu
      items={items}
      hide={!row?.isHovered}
      onClick={(value) => handleClick(value.toString())}
    />
  );
};

export default LookalikeAudienceRowCellActions;
