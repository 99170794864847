import styled from '@emotion/styled';
import {
  Text,
  colors,
  MagicStarBig,
  Button,
  AllowedColors,
  LinkIcon,
} from '@innovationdepartment/proxima-ui';
import LoggedInAsBrand from 'ui/LoggedInAsBrand';

const Header = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.bigBangBlack};
  gap: 16px;
`;

const LeftHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;

  & path {
    fill: ${colors.white};
  }
`;

const ButtonContainer = styled.div`
  & svg {
    margin-left: -4px;
    & path {
      fill: ${colors.neutral100};
    }
  }
`;

type BrandListHeaderProps = {
  onPerformanceAdsClick: () => void;
  isPerformanceLibraryEnabled?: boolean;
};

const BrandListHeader = (props: BrandListHeaderProps) => {
  const { onPerformanceAdsClick, isPerformanceLibraryEnabled } = props;

  return (
    <Header>
      <LeftHeaderContainer>
        <MagicStarBig />
        <Text color="offWhite" variant="h5">
          Nova
        </Text>
      </LeftHeaderContainer>
      <LoggedInAsBrand />
      {isPerformanceLibraryEnabled && (
        <ButtonContainer>
          <Button
            overrideColors={{
              backgroundColor: 'linear-gradient(to right, #45EDBA, #89D0E9)',
              textColor: colors.neutral100 as AllowedColors,
            }}
            label="Performance Library"
            onClick={onPerformanceAdsClick}
            endIcon={<LinkIcon />}
          />
        </ButtonContainer>
      )}
    </Header>
  );
};

export default BrandListHeader;
