import styled from '@emotion/styled';

type HideCardProps = { hide: boolean; useContainerDimension?: boolean };

const getDimensionValue = ({ hide, useContainerDimension }: HideCardProps) => {
  if (hide) return '0px';
  if (useContainerDimension) return '100%';
  return 'auto';
};

export default styled.div<HideCardProps>`
  position: relative;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  width: ${getDimensionValue};
  height: ${getDimensionValue};
`;
