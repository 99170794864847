import styled from '@emotion/styled';
import { Button, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import LoadingSpinner from 'ui/LoadingSpinner';

type DeleteConfirmationViewProps = {
  confirm: () => void;
  close: () => void;
  brandName: string;
  isLoading: boolean;
};

const ButtonGroup = styled.div`
  display: inline-flex;
  justify-content: end;
  width: 100%;
  button:first-of-type {
    margin-right: 1rem;
  }
`;

const TextWrapper = styled.div`
  & p {
    font-weight: 400;
  }
`;

const LoadingSpinnerWrapper = styled.div`
  display: block;
  width: 30vw;
  text-align: center;
`;

const DeleteConfirmationView = ({
  confirm,
  close,
  brandName,
  isLoading,
}: DeleteConfirmationViewProps) => (
  <TextWrapper>
    {isLoading ? (
      <LoadingSpinnerWrapper>
        <LoadingSpinner size={100} />
      </LoadingSpinnerWrapper>
    ) : (
      <>
        <SpaceDivider y={2} />
        <Text variant="body1">
          If you delete the brand {brandName}, you&apos;ll lose its campaign data and billing
          information. You won&apos;t be able to log into the account again. Are you sure you want
          to continue?
        </Text>
        <SpaceDivider y={6} />
        <ButtonGroup>
          <Button size="small" variant="outlined" label="Cancel" onClick={close} />
          <Button size="small" color="error" label="Delete" onClick={confirm} />
        </ButtonGroup>
      </>
    )}
  </TextWrapper>
);

export default DeleteConfirmationView;
