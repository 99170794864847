import { FormField, Text } from '@innovationdepartment/proxima-ui';
import { CreateSeedAudiencesDataSourceProps } from 'types/audiences';
import IMask from 'imask';
import { ContentWrapper, FieldWrapper } from './Layout';

const CreateSeedAudiencesDataSourcesCategoryShoppers = (
  props: CreateSeedAudiencesDataSourceProps & { disabled: boolean },
) => {
  const { disabled, canCreate } = props;

  const isDisabled = disabled || !canCreate;

  return (
    <ContentWrapper>
      <ContentWrapper flow="column">
        <Text variant="body2Semibold">Reference brand&apos;s data cap</Text>
        <FieldWrapper>
          <FormField
            type="input"
            fullWidth
            name="referenceBrandProportionTarget"
            disabled={isDisabled}
            format={(value: string) => {
              const mask = new IMask.MaskedNumber({});
              mask.resolve(`${value}`);
              return mask.value;
            }}
            endAdornment={
              <Text variant="body2" color={isDisabled ? 'neutral20' : 'neutral70'}>
                %
              </Text>
            }
          />
        </FieldWrapper>
      </ContentWrapper>
      <span /* flex layout hack */ />
    </ContentWrapper>
  );
};

export default CreateSeedAudiencesDataSourcesCategoryShoppers;
