import { useState } from 'react';
import {
  SeedAudienceTableColumnHeader,
  SeedAudiencesTableProps,
  SeedAudienceSchema,
  SeedAudienceTableRowProps,
  SeedAudienceTableRowExtraFunctions,
  AudienceTableCellRendererProps,
} from 'types/audiences';
import { TableProps } from '@innovationdepartment/proxima-ui';
import SeedAudiencesTableView from './SeedAudiencesTable.View';
import SeedAudiencesTableEmptyContent from './SeedAudiencesTable.EmptyContent';
import LookalikeAudiencesTable from '../LookalikeAudiencesTable';
import seedAudiencesRowRenderer from './seedAudiencesRowRenderer';
import { useFeatureFlag } from 'hooks';

type RowRendererProps = AudienceTableCellRendererProps<SeedAudienceTableRowProps> &
  SeedAudienceTableRowExtraFunctions;

const SeedAudiencesTable = (props: SeedAudiencesTableProps) => {
  const [hoverId, setHoverId] = useState<string | null>(null);
  const {
    seedAudiences,
    onShowRenameSeedAudienceModal,
    onShowRenameLookalikeAudienceModal,
    onShowDeleteLookalikeAudienceModal,
    onShowAudienceSettingsModal,
    onShowDeleteSeedAudienceModal,
    onShowCreateLookalikeAudienceModal,
  } = props;
  const { tiktokIntegration } = useFeatureFlag();

  const headerOrder = [
    'seedAudience',
    'platform',
    'displayStatus',
    'dateCreated',
    'actions',
  ] satisfies SeedAudienceTableColumnHeader[];

  if (!tiktokIntegration) headerOrder.splice(1, 1);

  const config: TableProps<SeedAudienceSchema>['config'] = {
    columns: {
      order: headerOrder,
      seedAudience: {
        label: 'Custom audience',
      },
      displayStatus: {
        label: 'Status',
        width: 200,
      },
      platform: {
        label: 'Platform',
        width: 160,
      },
      dateCreated: {
        label: 'Date created',
        align: 'right',
        width: 140,
      },
      actions: {
        hideLabel: true,
        width: 50,
      },
    },
    cellRenderer(row) {
      return seedAudiencesRowRenderer({
        ...row,
        onShowRenameSeedAudienceModal,
        onShowAudienceSettingsModal,
        onShowDeleteSeedAudienceModal,
        onShowCreateLookalikeAudienceModal,
      } as RowRendererProps);
    },
    // eslint-disable-next-line
    emptyStateRenderer: () => <SeedAudiencesTableEmptyContent />,
    onRowMouseEnter: ({ id }) => {
      setHoverId(id);
    },
    onRowMouseLeave: () => {
      setHoverId(null);
    },
    formatter(row: SeedAudienceSchema) {
      return {
        ...row,
        isHovered: row.id === hoverId,
      };
    },
    // eslint-disable-next-line
    onRowExpand: ({ row }) => (
      <LookalikeAudiencesTable
        lookalikeAudiences={row?.lookalikeAudiences ?? []}
        onShowRenameLookalikeAudienceModal={onShowRenameLookalikeAudienceModal}
        onShowDeleteLookalikeAudienceModal={onShowDeleteLookalikeAudienceModal}
      />
    ),
  };

  return <SeedAudiencesTableView seedAudiences={seedAudiences} config={config} />;
};

export default SeedAudiencesTable;
