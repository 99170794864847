import styled from '@emotion/styled';
import { Text, CellRendererProps, colors } from '@innovationdepartment/proxima-ui';
import { User, UserActionProps } from 'types/users';

import { formatDate } from 'utils';
import UsersActionMenu from './ActionMenu';
import { Link } from 'react-router-dom';

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const BrandsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  * {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.neutral80};
  }
`;

const userRowRender = ({ header, cell }: CellRendererProps<User & { menu: UserActionProps }>) => {
  switch (header) {
    case 'email': {
      return (
        <Text variant="table1Link" color="neutral100">
          {cell}
        </Text>
      );
    }
    case 'status': {
      return (
        <Text variant="table1Link" color="neutral100">
          {cell}
        </Text>
      );
    }
    case 'lastLoginAt': {
      if (typeof cell === 'string') {
        const formattedCell = (cell as unknown) instanceof Date ? formatDate(cell) : cell;
        return (
          <Text variant="table1Link" color="neutral100">
            {formattedCell}
          </Text>
        );
      }
      return null;
    }
    case 'menu': {
      if (typeof cell === 'object') return <UsersActionMenu menu={cell as UserActionProps} />;
      return null;
    }
    case 'associatedBrands': {
      return (
        <BrandsWrapper>
          {(cell as User['associatedBrands']).map((brand) => (
            <Link key={brand.brandId} to={`/brand/${brand.brandId}`}>
              <Text variant="table1Link" color="neutral100">
                {brand.name}
              </Text>
            </Link>
          ))}
        </BrandsWrapper>
      );
    }
    default: {
      return (
        <Text variant="table1Link" color="neutral100">
          <Capitalize>{cell}</Capitalize>
        </Text>
      );
    }
  }
};

export default userRowRender;
