import { ButtonWithPopup } from '@innovationdepartment/proxima-ui';
import { BrandFilterViewProps } from './types';
import BrandFilterMainView from './Layout/BrandFilter.MainView';
import BrandFilterActions from './Layout/BrandFilter.Actions';

const BrandFilterView = (props: BrandFilterViewProps) => {
  const { onOpen, onClose, open, selectedBrands } = props;
  const label = `Brands / ${selectedBrands.size || 'All'}`;
  return (
    <ButtonWithPopup label={label} onOpen={onOpen} onClose={onClose} open={open}>
      <BrandFilterMainView {...props} />
      <BrandFilterActions {...props} />
    </ButtonWithPopup>
  );
};

export default BrandFilterView;
