import { DatePickerProps, Text, useToaster } from '@innovationdepartment/proxima-ui';
import {
  AudienceSpendData,
  AudienceSpendSummaryData,
  FlavorCategory,
  GetLookalikeAudiencesStatsResponse,
  Subscription,
} from '@innovationdepartment/proxima-sdk-axios';
import { useAdManager, useProximaSDK } from 'hooks';
import { useEffect, useState } from 'react';
import { useBrandStore } from 'stores';

import * as date from 'date-fns';
import { BrandOverviewHookProps } from 'types/brandOverview';

const on30DaysAgo = date.startOfDay(date.sub(new Date(), { days: 30 }));
const yesterday = date.startOfDay(date.endOfYesterday());
const dateFormatter = (d: Date | undefined) => date.format(d || new Date(), 'yyyy-MM-dd');

function useBrandOverview() {
  const [loading, setLoading] = useState<BrandOverviewHookProps['apiLoading']>({});
  /* brand profile state */
  const [flavorCategoryId, setFlavorCategoryId] = useState<string>('');
  const [flavorCategories, setFlavorCategories] = useState<FlavorCategory[]>([]);
  const [brandSubscription, setBrandSubscription] = useState<Subscription>({});
  const { getCampaigns } = useAdManager();

  /* audience usage state */
  const [dateRange, setDateRange] = useState<DatePickerProps['dateRange']>({
    endDate: yesterday,
    startDate: on30DaysAgo,
  });
  const [audienceSpend, setAudienceSpend] = useState<AudienceSpendData>({
    averageDailySpend: 0,
    totalSpend: 0,
  });
  const [last30DaysOfAllBrandSpend, setLast30DaysOfAllBrandSpend] = useState(0);
  const [audienceSpendSummary, setAudienceSpendSummary] = useState<AudienceSpendSummaryData>({
    monthlyAverageSpend: 0,
    lifetimeSpend: 0,
    last30DaysSpend: { total: 0, facebook: 0 },
  });
  const [audienceStats, setAudienceStats] = useState<GetLookalikeAudiencesStatsResponse>({
    activeAudiences: { total: 0, facebook: 0 },
    totalAudiences: { total: 0, facebook: 0 },
  });

  /* brand customer settings state */
  const [isFileUploadAllowed, setIsFileUploadAllowed] = useState<boolean>(false);

  const audiencesApi = useProximaSDK('AudiencesApi');
  const brandsApi = useProximaSDK('BrandsApi');
  const audienceSpendApi = useProximaSDK('AudienceSpendApi');
  const billingApi = useProximaSDK('BillingApi');

  const { showToaster } = useToaster();
  const { brand } = useBrandStore();

  const handleLoading = (section: keyof BrandOverviewHookProps['apiLoading'], isLoading = true) => {
    setLoading((prevLoading) => ({ ...prevLoading, [section]: isLoading }));
  };

  const onUpdateBrandCategory = async (newFlavorCategory: FlavorCategory) => {
    if (!newFlavorCategory.id) return;

    handleLoading('brandAudienceSettings');
    await audiencesApi.updateBrandSettings({
      brandId: brand.brandId,
      updateBrandSettingsRequest: { flavorCategoryId: newFlavorCategory.id },
    });
    setFlavorCategoryId(newFlavorCategory.id);
    const flavorCategoryMessage = `Brand category updated to ${newFlavorCategory.name}.`;
    showToaster({ message: flavorCategoryMessage, variant: 'success' });
    handleLoading('brandAudienceSettings', false);
  };

  const onAudienceDateRangeSelect = (newDateRange: DatePickerProps['dateRange']) => {
    setDateRange(newDateRange);
  };

  const onUpsertBrandCustomerSettings = async (updatedIsFileUploadAllowed: boolean) => {
    await brandsApi.upsertBrandCustomerSettings({
      brandId: brand.brandId,
      upsertBrandCustomerSettings: {
        isFileUploadAllowed: updatedIsFileUploadAllowed,
      },
    });

    setIsFileUploadAllowed(updatedIsFileUploadAllowed);

    const enabledOrDisabledText = updatedIsFileUploadAllowed ? 'enabled' : 'disabled';
    const toasterVariant = updatedIsFileUploadAllowed ? 'success' : 'info';

    const brandCustomerSettingsMessage = (
      <Text variant="body2">
        Customer lists {enabledOrDisabledText} for
        <Text variant="body2Semibold"> {brand.name}</Text>
      </Text>
    );

    showToaster({ message: brandCustomerSettingsMessage, variant: toasterVariant });
  };

  /* fetch brand category */
  useEffect(() => {
    const fetchBrandCategory = async () => {
      if (!brand?.brandId) return;
      handleLoading('flavorCategory');
      try {
        const response = await audiencesApi.getBrandSettings(
          { brandId: brand.brandId },
          { skipErrorToaster: true },
        );
        if (response.data.flavorCategoryId) setFlavorCategoryId(response.data.flavorCategoryId);
      } catch (err) {
        /* TODO(Jenky): handle error */
      }
      handleLoading('flavorCategory', false);
    };

    fetchBrandCategory();
  }, []);

  /* fetch available categories */
  useEffect(() => {
    const fetchFlavorCategories = async () => {
      handleLoading('flavorCategories');
      try {
        const response = await audiencesApi.getFlavorCategories();
        setFlavorCategories(response.data);
      } catch (err) {
        /* TODO(Jenky): handle error */
      }

      handleLoading('flavorCategories', false);
    };

    fetchFlavorCategories();
  }, []);

  /* fetch audience spend summary */
  useEffect(() => {
    const getAudienceSpendSummary = async () => {
      if (!brand?.brandId) return;
      handleLoading('audiencesSpendSummary');

      try {
        const response = await audienceSpendApi.getBrandSpendSummary(
          { brandId: brand.brandId },
          { skipErrorToaster: true },
        );
        if (response.data) setAudienceSpendSummary(response.data);
      } catch (err) {
        /* TODO(Jenky): handle error */
      }
      handleLoading('audiencesSpendSummary', false);
    };

    getAudienceSpendSummary();
  }, []);

  /* fetch audiences stats */
  useEffect(() => {
    const fetchAudiencesStats = async () => {
      if (!brand?.brandId) return;
      handleLoading('audiencesStats');
      try {
        const response = await audiencesApi.getLookalikeAudiencesStats({ brandId: brand.brandId });
        if (response.data) setAudienceStats(response.data);
      } catch (err) {
        /* TODO(Jenky): handle error */
      }
      handleLoading('audiencesStats', false);
    };

    fetchAudiencesStats();
  }, []);

  /* fetch brand billing info */
  useEffect(() => {
    const fetchBrandSubscription = async () => {
      if (!brand?.brandId) return;
      handleLoading('billing');
      try {
        const response = await billingApi.getSubscription(
          { brandId: brand.brandId },
          { skipErrorToaster: true },
        );
        setBrandSubscription(response.data);
      } catch (err) {
        /*  */
      }
      handleLoading('billing', false);
    };

    fetchBrandSubscription();
  }, []);

  /* fetch data on date range changes */
  useEffect(() => {
    const getAudienceSpendDateRange = async () => {
      if (!brand?.brandId) return;
      handleLoading('audiencesSpend');

      try {
        const formattedDates = {
          endDate: dateFormatter(dateRange.endDate),
          startDate: dateFormatter(dateRange.startDate),
        };
        const response = await audienceSpendApi.getBrandSpend({
          brandId: brand.brandId,
          ...formattedDates,
        });
        if (response.data) setAudienceSpend(response.data);
      } catch (err) {
        /* TODO(Jenky): handle error */
      }
      handleLoading('audiencesSpend', false);
    };

    getAudienceSpendDateRange();
  }, [dateRange]);

  useEffect(() => {
    const getLast30DaysOfBrandSpend = async () => {
      if (!brand?.brandId) return;
      handleLoading('campaigns');

      try {
        const startDate = dateFormatter(on30DaysAgo);
        const endDate = dateFormatter(yesterday);
        const campaigns = await getCampaigns({ queryItems: { startDate, endDate } });
        setLast30DaysOfAllBrandSpend(campaigns.data?.summary?.spend);
      } catch (err) {
        /* catch error */
      }
      handleLoading('campaigns', false);
    };

    getLast30DaysOfBrandSpend();
  }, []);

  /* fetch brand customer settings */
  useEffect(() => {
    const getFileUploadAllowed = async () => {
      try {
        const { data } = await brandsApi.getBrandCustomerSettings(
          { brandId: brand.brandId },
          { skipErrorToaster: true },
        );

        setIsFileUploadAllowed(data.isFileUploadAllowed);
      } catch (err) {
        /* catch error */
      }
    };

    getFileUploadAllowed();
  }, []);

  const formattedFlavorCategoriesOptions = flavorCategories.map((flavorCategory) => ({
    label: flavorCategory.name as string,
    value: flavorCategory.id as string,
  }));

  return {
    brand,
    brandSubscription,
    flavorCategory: flavorCategories?.find((option) => option.id === flavorCategoryId),
    flavorCategoryOptions: formattedFlavorCategoriesOptions,
    dateRange,
    audienceSpend,
    audienceSpendSummary,
    audienceStats,
    last30DaysOfAllBrandSpend,
    isFileUploadAllowed,
    onUpdateBrandCategory,
    onAudienceDateRangeSelect,
    onUpsertBrandCustomerSettings,
    loading: Object.values(loading).some(Boolean),
    apiLoading: loading,
    brandCustomerSettingsLoading: brandsApi.loading,
  } satisfies Omit<BrandOverviewHookProps, 'brandWarnings'>;
}

export default useBrandOverview;
