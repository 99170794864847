const formatNumberToThousands = (num: number) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
  }).format(num);

const formatNumberToCurrency = (num: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(num);

const formatNumberToCommaSeparated = (num: number) =>
  new Intl.NumberFormat('en-US', {
    notation: 'standard',
  }).format(num);

const formatNumberToPercentage = (num: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(num);

export {
  formatNumberToThousands,
  formatNumberToCurrency,
  formatNumberToCommaSeparated,
  formatNumberToPercentage,
};
