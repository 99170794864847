import styled from '@emotion/styled';
import { useFormState } from 'react-final-form';
import { Button, FormField, Text, SpaceDivider } from '@innovationdepartment/proxima-ui';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';

type RenameLookalikeAudienceModalViewProps = {
  onClose: () => void;
  isSubmitting: boolean;
};

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 640px;
`;

const RenameLookalikeAudienceModalView = (props: RenameLookalikeAudienceModalViewProps) => {
  const { isSubmitting, onClose } = props;
  const formState = useFormState();
  const { valid, dirty } = formState;

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <ActionButtonsWrapper>
          <Button variant="outlined" color="secondary" label="Cancel" onClick={onClose} />
          <Button submit size="small" disabled={!valid || !dirty || isSubmitting} label="Update" />
        </ActionButtonsWrapper>
      }
    >
      <ContentWrapper>
        <SpaceDivider y={2} />
        <Text variant="body2Semibold" color="neutral80">
          Audience name
        </Text>
        <FormField name="name" type="input" size="medium" fullWidth />
        <SpaceDivider y={4} />
      </ContentWrapper>
    </ModalBaseChildrenWrapper>
  );
};

export default RenameLookalikeAudienceModalView;
