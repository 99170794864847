import { FilterWithCheck } from '@innovationdepartment/proxima-ui';
import { SpendActivityFilterOptions } from 'types/brandList';

type SpendActivityFilterViewProps = {
  onFilterSpend: (selectedItems: string[]) => void;
};

const SpendActivityFilterView = ({ onFilterSpend }: SpendActivityFilterViewProps) => (
  <div data-testid="spend-activity-filter">
    <FilterWithCheck
      title="Spend activity"
      styles={{ width: '180px' }}
      items={Object.keys(SpendActivityFilterOptions).map((k) => ({
        text: k,
        value: SpendActivityFilterOptions[k as keyof typeof SpendActivityFilterOptions],
      }))}
      onChange={(selectedItems) => onFilterSpend(selectedItems)}
    />
  </div>
);

export default SpendActivityFilterView;
