import { useAuth0 } from '@auth0/auth0-react';

import { IAccessToken, IAccessTokenArgs } from 'types/hooks/useAccessToken';

const namespace = 'https://dojomojo.com/jwt';

export default function useAccessToken() {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async ({
    refresh = false,
  }: IAccessTokenArgs = {}): Promise<IAccessToken> => {
    const opts: Record<string, unknown> = {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
    };
    if (refresh) {
      opts.ignoreCache = true;
    }

    // get current token (retrieves a new token from Auth0 if local cache is empty)
    const accessToken = await getAccessTokenSilently(opts);
    return { accessToken };
  };

  return {
    getAccessToken,
    namespace,
  };
}
