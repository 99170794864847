import { BrandInfoProps } from 'types/brandOverview';
import PageLayoutSectionView from '../../PageSectionLayout.View';
import { formatDate } from 'utils';

const BrandInfoView = ({ brand }: BrandInfoProps) => (
  <PageLayoutSectionView
    sections={[
      {
        label: 'Brand name',
        content: brand?.name,
      },
      {
        label: 'Brand URL',
        content: brand?.domain,
      },
      {
        label: 'Creation date',
        content: formatDate(brand?.createdAt || new Date().toDateString()),
      },
    ]}
  />
);

export default BrandInfoView;
