import IMask from 'imask';

const MASK = '(000) 000-0000';

export default function formatPhoneNumber(phoneNumber: string = ''): string {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  const mask = new IMask.MaskedPattern({ mask: MASK });
  const formattedPhone = cleanedPhoneNumber.startsWith('1')
    ? cleanedPhoneNumber.slice(1)
    : cleanedPhoneNumber;

  mask.resolve(formattedPhone);

  return mask.value;
}
