import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Modal } from '@innovationdepartment/proxima-ui';
import { BrandLockAudienceOption } from 'types/audiences';
import LoadingSpinner from 'ui/LoadingSpinner';
import LockAccessView from './LockAccess.View';
import LockConfirmationView from './LockConfirmation.View';
import { Brand } from '@innovationdepartment/proxima-sdk-axios';

const LoadingSpinnerWrapper = styled.div`
  display: block;
  width: 30vw;
  text-align: center;
`;

const getFormattedDate = (date: string) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return formattedDate;
};

export type LockConfirmationProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (audienceOption: BrandLockAudienceOption) => Promise<void>;
  brand?: Brand;
  isLoading: boolean;
  isAdAccountActive: boolean;
};

const LockAccess = ({
  open,
  onConfirm,
  onClose,
  brand,
  isLoading,
  isAdAccountActive,
}: LockConfirmationProps) => {
  const timeoutRef = useRef<number>(-1);
  const [step, setStep] = useState<'lockUnlock' | 'confirmation'>('lockUnlock');
  const [brandLockAudienceOption, setBrandLockAudienceOption] = useState<BrandLockAudienceOption>(
    BrandLockAudienceOption.KeepAudiences,
  );

  const handleConfirmClose = () => {
    onClose();
    setStep('lockUnlock');
    setBrandLockAudienceOption(BrandLockAudienceOption.KeepAudiences);
  };

  const handleContinueClick = () => {
    setStep('confirmation');
  };

  const resetModalState = () => {
    onConfirm(brandLockAudienceOption);
    setStep('lockUnlock');
    setBrandLockAudienceOption(BrandLockAudienceOption.KeepAudiences);
  };

  useEffect(() => {
    if (open) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        setStep('lockUnlock');
      }, 250);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [open]);

  if (!brand) return null;

  if (isLoading) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner size={100} />
      </LoadingSpinnerWrapper>
    );
  }

  const formattedCreatedAt = getFormattedDate(brand.createdAt);

  return (
    <Modal
      noPadding={false}
      open={open}
      title="Lock brand access"
      onClose={handleConfirmClose}
      allowCloseOnClickOutside
    >
      {step === 'lockUnlock' && (
        <LockAccessView
          audienceOption={brandLockAudienceOption}
          isAdAccountActive={isAdAccountActive}
          brandName={brand.name}
          createdAt={formattedCreatedAt}
          handleContinueClick={handleContinueClick}
          onSetAudienceOption={setBrandLockAudienceOption}
          onClose={handleConfirmClose}
        />
      )}
      {step === 'confirmation' && (
        <LockConfirmationView
          onConfirm={resetModalState}
          onBack={() => setStep('lockUnlock')}
          brandName={brand.name}
        />
      )}
    </Modal>
  );
};

export default LockAccess;
