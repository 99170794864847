import * as proximaSdk from '@innovationdepartment/proxima-sdk-axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';
import { useState } from 'react';

type ExtractApiMembers<Module> = {
  [Name in keyof Module]: Name extends `${string}Api` ? Name : never;
}[keyof Module];

type SDKApiName = ExtractApiMembers<typeof proximaSdk>;
type EndpointInstance<T extends SDKApiName> = InstanceType<(typeof proximaSdk)[T]> & {
  loading: boolean;
};

const { Configuration } = proximaSdk;

const useProximaSDK = <T extends SDKApiName>(apiName: T): EndpointInstance<T> => {
  const { getAccessTokenSilently } = useAuth0();
  const { brand } = useBrandStore();

  const [loading, setLoading] = useState<Record<string, boolean>>({});

  const proxy = new Proxy(
    {},
    {
      get(_, prop) {
        if (prop === 'loading') {
          // if any element of this sdk instance is loading, return true
          return Object.values(loading).some((value) => value);
        }

        return async (...props: any[]) => {
          // get access token from auth0
          const accessToken = await getAccessTokenSilently({
            brandId: brand?.brandId,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
            // ignoreCache: true, // how to apply this?
          });

          // create proxima sdk configuration
          const config = new Configuration({
            basePath: import.meta.env.VITE_PROXIMA_API_URL,
            accessToken,
            baseOptions: { headers: { Accept: 'application/json' } },
          });

          // create proxima sdk api instance
          const apiInstance = new proximaSdk[apiName](config);

          // call proxima sdk api method
          const method = apiInstance[prop as keyof {}];

          try {
            setLoading((prev) => ({ ...prev, [prop]: true }));

            // return proxima sdk api method result
            // use unknown to avoid typescript error
            // use .call to allow passing in the api instance as the this context
            const result = await (method as unknown as Function).call(
              apiInstance,
              ...(props satisfies any[]),
            );
            setLoading((prev) => ({ ...prev, [prop]: false }));
            return result;
          } catch (error) {
            setLoading((prev) => ({ ...prev, [prop]: false }));
            throw error;
          }
        };
      },
    },
  );

  // return proxima sdk api instance
  // make typescript happy by casting to the correct type called by the api name
  return proxy as EndpointInstance<T>;
};

export default useProximaSDK;
