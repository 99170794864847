import { FacebookTableViewProps } from 'types/fbTable';
import LoadingSpinner from 'ui/LoadingSpinner';
import FacebookTableAdAccountIdView from './FacebookTable.AdAccountId.View';

const FacebookTableAdAccountId = ({
  adAccount,
  loading,
}: Pick<FacebookTableViewProps, 'loading' | 'adAccount'>) => {
  const adAccountId = loading ? <LoadingSpinner size={16} /> : adAccount?.accountId ?? '-';

  return <FacebookTableAdAccountIdView adAccountId={adAccountId} />;
};

export default FacebookTableAdAccountId;
