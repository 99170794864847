export const OkCheck = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_218_107365"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_218_107365)">
      <path
        d="M7.06652 11.0666L11.7665 6.36665L10.8332 5.43331L7.06652 9.19998L5.16652 7.29998L4.23319 8.23331L7.06652 11.0666ZM7.99986 14.6666C7.07763 14.6666 6.21097 14.4916 5.39986 14.1416C4.58875 13.7916 3.88319 13.3166 3.28319 12.7166C2.68319 12.1166 2.20819 11.4111 1.85819 10.6C1.50819 9.78887 1.33319 8.9222 1.33319 7.99998C1.33319 7.07776 1.50819 6.21109 1.85819 5.39998C2.20819 4.58887 2.68319 3.88331 3.28319 3.28331C3.88319 2.68331 4.58875 2.20831 5.39986 1.85831C6.21097 1.50831 7.07763 1.33331 7.99986 1.33331C8.92208 1.33331 9.78875 1.50831 10.5999 1.85831C11.411 2.20831 12.1165 2.68331 12.7165 3.28331C13.3165 3.88331 13.7915 4.58887 14.1415 5.39998C14.4915 6.21109 14.6665 7.07776 14.6665 7.99998C14.6665 8.9222 14.4915 9.78887 14.1415 10.6C13.7915 11.4111 13.3165 12.1166 12.7165 12.7166C12.1165 13.3166 11.411 13.7916 10.5999 14.1416C9.78875 14.4916 8.92208 14.6666 7.99986 14.6666Z"
        fill="#00996D"
      />
    </g>
  </svg>
);
