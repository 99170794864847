import { Text } from '@innovationdepartment/proxima-ui';

type FacebookTableLastUpdatedAtProps = {
  lastUpdatedAt: React.ReactNode;
};

const FacebookTableLastUpdatedAtView = (props: FacebookTableLastUpdatedAtProps) => {
  const { lastUpdatedAt } = props;

  return (
    <Text color="neutral80" variant="body2">
      {lastUpdatedAt}
    </Text>
  );
};

export default FacebookTableLastUpdatedAtView;
