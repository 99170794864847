import { FilterWithCheck } from '@innovationdepartment/proxima-ui';
import { PlanFilterOptions } from 'types/brandList';

type PlanFilterViewProps = {
  onFilterPlan: (selectedPlans: string[]) => void;
};

const PlanFilterView = ({ onFilterPlan }: PlanFilterViewProps) => (
  <div data-testid="plan-filter">
    <FilterWithCheck
      title="Plan"
      styles={{ width: '100px' }}
      items={Object.keys(PlanFilterOptions).map((k) => ({
        text: k,
        value: PlanFilterOptions[k as keyof typeof PlanFilterOptions],
      }))}
      onChange={(selectedItems) => {
        onFilterPlan(selectedItems);
      }}
    />
  </div>
);

export default PlanFilterView;
