import { useLocation } from 'react-router-dom';

const ROUTES_WITHOUT_NAVBAR = [
  '/',
  '/logout',
  '/setup/user',
  '/setup/brand',
  '/setup/facebook',
  '/setup/ads',
  '/email/verify',
  '/auth/callback/shopify',
  '/auth/callback/facebook',
  '/auth/callback/shopify/generate-oauth-url',
  '/auth/all-set',
  '/terms/copyright',
  '/terms/privacy',
  '/terms/use',
  '/brands',
  '/brand',
  '/creatives',
];

const ROUTES_WITH_BLUEGREEN_BACKDROP = [
  '/email/verify',
  '/setup/user',
  '/setup/brand',
  '/setup/facebook',
  '/setup/ads',
];

const useShowNavbar = () => {
  const { pathname } = useLocation();
  const showNavbar = !ROUTES_WITHOUT_NAVBAR.includes(pathname);
  const showBackdrop = ROUTES_WITH_BLUEGREEN_BACKDROP.includes(pathname);

  return {
    showNavbar,
    showBackdrop,
    ROUTES_WITHOUT_NAVBAR,
    ROUTES_WITH_BLUEGREEN_BACKDROP,
  };
};

export default useShowNavbar;
