import styled from '@emotion/styled';

/* used to wrap up ContentSection elements within */
export default styled.div<{ column?: boolean; maxWidth?: number }>`
  flex: 1;
  display: flex;
  gap: 16px;
  align-items: flex-start;

  ${({ column }) => {
    if (!column) return '';

    return `
      flex-direction: column;
      align-items: stretch;
    `;
  }}

  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: stretch;

    & > * {
      max-width: unset !important;
    }
  }
`;
