import { BrandFilterViewProps } from '../types';
import styled from '@emotion/styled';
import BrandFilterBrandsList from './BrandFilter.BrandsList';
import BrandFilterSelectedElements from './BrandFilter.SelectedElements';
import BrandFilterViewSwitcher from './BrandFilter.ViewSwitcher';
import BrandFilterSearch from './BrandFilter.Search';

const BrandFilterMainViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 430px;
`;

const BrandFilterMainView = (props: BrandFilterViewProps) => {
  const { focused } = props;
  return (
    <BrandFilterMainViewContainer>
      <BrandFilterSearch {...props} />
      <BrandFilterViewSwitcher focused={focused}>
        <BrandFilterBrandsList {...props} />
        <BrandFilterSelectedElements {...props} />
      </BrandFilterViewSwitcher>
    </BrandFilterMainViewContainer>
  );
};

export default BrandFilterMainView;
