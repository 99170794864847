import styled from '@emotion/styled';
import { Button } from '@innovationdepartment/proxima-ui';
import { AddOutlined } from '@mui/icons-material';
import { AudiencesHeaderProps } from 'types/audiences';
import LoggedInAsBrandBadge from 'ui/LoggedInAsBrandBadge';

const AudiencesHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

const AudiencesHeader = ({ onShowCreateSeedAudiencesModal }: AudiencesHeaderProps) => {
  const disabled = !onShowCreateSeedAudiencesModal;
  // TODO(Jenky): check with Product
  const label = disabled ? 'Audiences not available' : 'Generate new audiences';

  return (
    <AudiencesHeaderWrapper>
      <LoggedInAsBrandBadge />
      <Button
        disabled={disabled}
        label={label}
        startIcon={<AddOutlined />}
        onClick={() => onShowCreateSeedAudiencesModal?.()}
      />
    </AudiencesHeaderWrapper>
  );
};

export default AudiencesHeader;
