import styled from '@emotion/styled';
import groupBy from 'lodash/groupBy';
import LoadingSpinner from 'ui/LoadingSpinner';
import { BrandListIntegrationProps, IntegrationType } from 'types/integrations';
import BrandListIntegrationStatus from './BrandListIntegrationStatus';
import { useFeatureFlag } from 'hooks';

const BrandListIntegrationsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const BrandListIntegrations = ({
  integrations,
  loading,
  brandId,
  isAdAccountActive,
  isTiktokAdAccountActive,
}: Exclude<BrandListIntegrationProps, 'type'>) => {
  if (loading) return <LoadingSpinner />;
  const { tiktokIntegration } = useFeatureFlag();

  const groupedIntegrations = groupBy(integrations, 'type');

  return (
    <BrandListIntegrationsWrapper>
      <BrandListIntegrationStatus
        brandId={brandId}
        isAdAccountActive={isAdAccountActive}
        integrations={groupedIntegrations}
        type={IntegrationType.Facebook}
      />
      <BrandListIntegrationStatus
        brandId={brandId}
        integrations={groupedIntegrations}
        type={IntegrationType.Shopify}
      />
      {tiktokIntegration && (
        <BrandListIntegrationStatus
          brandId={brandId}
          isAdAccountActive={isTiktokAdAccountActive}
          integrations={groupedIntegrations}
          type={IntegrationType.TikTok}
        />
      )}
    </BrandListIntegrationsWrapper>
  );
};

export default BrandListIntegrations;
