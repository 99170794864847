import { Text } from '@innovationdepartment/proxima-ui';
import { CreateSeedAudiencesDataSourceProps } from 'types/audiences';
import LoadingSpinner from 'ui/LoadingSpinner';
import Tag from 'ui/Tag';
import { ContentWrapper } from './Layout';

const CreateSeedAudiencesDataSourcesBrandCategory = (props: CreateSeedAudiencesDataSourceProps) => {
  const { flavorCategory, loading, tab } = props;

  const isCategoryShoppers = tab === 'categoryShoppers';

  if (!isCategoryShoppers) return null;

  let category = <Tag label={flavorCategory?.name ?? ''} />;

  if (loading) category = <LoadingSpinner />;

  return (
    <ContentWrapper flow="column">
      <Text variant="body2Semibold">Brand category</Text>
      {category}
    </ContentWrapper>
  );
};

export default CreateSeedAudiencesDataSourcesBrandCategory;
