import SectionLayout from 'ui/SectionLayout';
import { AudiencesProps } from 'types/audiences';
import SeedAudiencesTable from './SeedAudiencesTable';
import AudiencesHeader from './Audiences.Header';

const AudiencesView: React.FC<AudiencesProps> = ({
  seedAudiences,
  onShowRenameSeedAudienceModal,
  onShowRenameLookalikeAudienceModal,
  onShowAudienceSettingsModal,
  onShowCreateSeedAudiencesModal,
  onShowDeleteSeedAudienceModal,
  onShowDeleteLookalikeAudienceModal,
  onShowCreateLookalikeAudienceModal,
}) => (
  <SectionLayout
    title="Audiences"
    titleAppendText={
      <AudiencesHeader onShowCreateSeedAudiencesModal={onShowCreateSeedAudiencesModal} />
    }
  >
    <SeedAudiencesTable
      seedAudiences={seedAudiences}
      onShowRenameSeedAudienceModal={onShowRenameSeedAudienceModal}
      onShowRenameLookalikeAudienceModal={onShowRenameLookalikeAudienceModal}
      onShowAudienceSettingsModal={onShowAudienceSettingsModal}
      onShowDeleteSeedAudienceModal={onShowDeleteSeedAudienceModal}
      onShowDeleteLookalikeAudienceModal={onShowDeleteLookalikeAudienceModal}
      onShowCreateLookalikeAudienceModal={onShowCreateLookalikeAudienceModal}
    />
  </SectionLayout>
);

export default AudiencesView;
