import styled from '@emotion/styled';

const BrandListResultsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
`;

export default BrandListResultsWrapper;
