import styled from '@emotion/styled';
import { colors, ProximaLogoNova, Text } from '@innovationdepartment/proxima-ui';
import React from 'react';
import ProximaLink from 'ui/ProximaLink';

const NavbarWrapper = styled.nav`
  width: 100%;
  background-color: ${colors.neutral100};
  height: 68px;
  padding: 32px;
  display: flex;
  align-items: center;
`;

const NavbarMainWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  height: 20px;
`;

type HorizontalNavbarProps = {
  label: string;
};

/* TODO(Jenky): might create navbar in ui lib */
const HorizontalNavbar: React.FC<HorizontalNavbarProps> = (props) => {
  const { children, label } = props;

  return (
    <NavbarWrapper>
      <NavbarMainWrapper>
        <ProximaLink to="/brands">
          <ProximaLogoNova />
        </ProximaLink>
        <Text variant="h4" color="white">
          {label}
        </Text>
      </NavbarMainWrapper>
      {children}
    </NavbarWrapper>
  );
};

export default HorizontalNavbar;
