import styled from '@emotion/styled';
import { BrandFilterViewProps } from '../types';
import { Button, Pill, Text } from '@innovationdepartment/proxima-ui';
import { Close } from '@mui/icons-material';
import pluralize from 'pluralize';

const BrandsFilterWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const BrandFilterPillsWrapper = styled.div`
  & > * {
    margin: 0 12px 12px 0;
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
  }
`;

const BrandFiltersBrandListFunctionsWrapper = styled.div`
  height: 32px;
  margin-top: 4px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TemporaryClose = styled(Close)`
  font-size: 12px;
  cursor: pointer;
`;

const BrandFilterBrandsList = (props: BrandFilterViewProps) => {
  const { selectedBrands, onBrandSelect, onClearAllBrands } = props;
  const selectedBrandsLabel = `${pluralize(`brands`, selectedBrands.size, true)} selected`;
  return (
    <BrandsFilterWrapper className="brand-filter-brand-list">
      <BrandFiltersBrandListFunctionsWrapper>
        <Text variant="tag1Semibold" color="neutral70">
          {selectedBrandsLabel}
        </Text>
        {selectedBrands.size > 0 && (
          <Button
            startIcon={<Close />}
            label="Clear all"
            onClick={onClearAllBrands}
            variant="text"
          />
        )}
      </BrandFiltersBrandListFunctionsWrapper>
      <BrandFilterPillsWrapper>
        {[...selectedBrands.values()].map((brand) => (
          <Pill key={brand.brandId!}>
            <Text ellipsis variant="tag1Semibold">
              {brand.name}
            </Text>
            <TemporaryClose onClick={() => onBrandSelect(brand)} />
          </Pill>
        ))}
      </BrandFilterPillsWrapper>
    </BrandsFilterWrapper>
  );
};

export default BrandFilterBrandsList;
