import { FacebookSmall, ShopifySmall, TikTokSmall } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'types/integrations';

const PLATFORMS: { [key in IntegrationType]: React.ReactNode } = {
  [IntegrationType.Facebook]: <FacebookSmall />,
  [IntegrationType.TikTok]: <TikTokSmall />,
  [IntegrationType.Shopify]: <ShopifySmall />,
};

export default PLATFORMS;
