const BadCheck = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_218_107867"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_218_107867)">
      <path
        d="M4.66665 8.66666H11.3333V7.33333H4.66665V8.66666ZM7.99998 14.6667C7.07776 14.6667 6.21109 14.4917 5.39998 14.1417C4.58887 13.7917 3.88331 13.3167 3.28331 12.7167C2.68331 12.1167 2.20831 11.4111 1.85831 10.6C1.50831 9.78888 1.33331 8.92222 1.33331 7.99999C1.33331 7.07777 1.50831 6.21111 1.85831 5.39999C2.20831 4.58888 2.68331 3.88333 3.28331 3.28333C3.88331 2.68333 4.58887 2.20833 5.39998 1.85833C6.21109 1.50833 7.07776 1.33333 7.99998 1.33333C8.9222 1.33333 9.78887 1.50833 10.6 1.85833C11.4111 2.20833 12.1166 2.68333 12.7166 3.28333C13.3166 3.88333 13.7916 4.58888 14.1416 5.39999C14.4916 6.21111 14.6666 7.07777 14.6666 7.99999C14.6666 8.92222 14.4916 9.78888 14.1416 10.6C13.7916 11.4111 13.3166 12.1167 12.7166 12.7167C12.1166 13.3167 11.4111 13.7917 10.6 14.1417C9.78887 14.4917 8.9222 14.6667 7.99998 14.6667Z"
        fill="#94A5A5"
      />
    </g>
  </svg>
);

export default BadCheck;
