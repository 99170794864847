import { PropsOf } from '@emotion/react';
import { useLocation } from 'react-router-dom';

import { useIntegrationsStore } from 'stores';
import { IntegrationType } from 'types/integrations';
import { IntegrationStatus } from 'types/stores/integration-store';
import IntegrationDisconnectionWarningBannerView from './IntegrationDisconnectionWarningBanner.View';
import { useEffect } from 'react';

type ViewBannerProps = PropsOf<typeof IntegrationDisconnectionWarningBannerView>;
type IntegrationDisconnectBannerProps = Partial<Omit<ViewBannerProps, 'onClick'>> & {
  onAddPadding: (add: boolean) => void;
};

// TODO(Jenky): logic here only works for Facebook, might need to change to include Shopify
const ROUTES_TO_SKIP = ['/', '/brands', '/brand'];

const IntegrationDisconnectionWarningBanner = ({
  footer = true,
  type = IntegrationType.Facebook,
  onAddPadding,
}: IntegrationDisconnectBannerProps) => {
  const location = useLocation();
  const { integrations, getAllIntegrations } = useIntegrationsStore();

  const shouldSkipBanner = () => ROUTES_TO_SKIP.some((path) => location.pathname === path);
  const fbIntegrations = getAllIntegrations({ type: IntegrationType.Facebook, active: true });
  const hasNeverIntegrated = integrations.length === 0;
  const hasNeverIntegratedFb = fbIntegrations.length === 0;
  const hasActiveFBIntegration = fbIntegrations.some(
    (integration) => integration.status === IntegrationStatus.Active,
  );

  const addPadding = Boolean(
    !shouldSkipBanner() && !hasNeverIntegrated && !hasNeverIntegratedFb && !hasActiveFBIntegration,
  );

  useEffect(() => {
    onAddPadding(addPadding);
  }, [addPadding]);

  // dont show on specific routes regardless of the status of the integration
  if (shouldSkipBanner()) return null;

  // if no integrations, it means brand is new, so don't show the banner
  if (hasNeverIntegrated) return null;

  // if brand has manually disconnected or no fb integrations associated to it, don't show the banner
  if (hasNeverIntegratedFb) return null;

  // if has healthy fb integration, don't show the banner
  if (hasActiveFBIntegration) return null;

  return <IntegrationDisconnectionWarningBannerView type={type} footer={footer} />;
};

export default IntegrationDisconnectionWarningBanner;
