import { LookalikeSizePreviewProps } from 'types/audiences';
import ContentContainer from 'ui/Modals/CreateSeedAudiencesModal/Layout/ContentContainer';
import LookalikeSeedAudiencePreview from './LookalikeSizePreview.LookalikeAudience';

const LookalikeSizePreviewView = (props: LookalikeSizePreviewProps) => {
  const { galaxy, size } = props;

  return (
    <ContentContainer title="Your lookalike">
      <LookalikeSeedAudiencePreview galaxy={galaxy} size={size} />
    </ContentContainer>
  );
};

export default LookalikeSizePreviewView;
