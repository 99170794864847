import { Form } from '@innovationdepartment/proxima-ui';
import { useState } from 'react';
import ModalBase from 'ui/ModalBase';
import EditBrandProfileModalView from './EditBrandProfileModal.View';
import { FlavorCategory } from '@innovationdepartment/proxima-sdk-axios';

type SelectOption = { label: string; value: string };

type EditBrandProfileModalProps = {
  open: boolean;
  currentFlavorCategory: FlavorCategory;
  flavorCategoryOptions: SelectOption[];
  onClose: () => void;
  onUpdateBrandCategory: (category: FlavorCategory) => void;
};

const EditBrandProfileModal = (props: EditBrandProfileModalProps) => {
  const { open, currentFlavorCategory, flavorCategoryOptions, onClose, onUpdateBrandCategory } =
    props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formData: { category: string }) => {
    const { category } = formData;

    const flavorCategory = flavorCategoryOptions?.find(
      (flavorCategoryOption) => flavorCategoryOption.value === category,
    );
    const mappedFlavorCategory = {
      id: flavorCategory!.value,
      name: flavorCategory!.label,
    } satisfies FlavorCategory;

    setIsSubmitting(true);
    await onUpdateBrandCategory(mappedFlavorCategory);
    setIsSubmitting(false);
    onClose();
  };

  if (!currentFlavorCategory) return null;

  return (
    <ModalBase open={open} title="Edit brand profile" onClose={onClose} allowCloseOnClickOutside>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ category: currentFlavorCategory.id as string }}
      >
        <EditBrandProfileModalView
          isSubmitting={isSubmitting}
          onClose={onClose}
          flavorCategoryOptions={flavorCategoryOptions}
        />
      </Form>
    </ModalBase>
  );
};

export default EditBrandProfileModal;
