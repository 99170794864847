import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import UserProfile from 'components/UserProfile';
import NotFound from 'components/NotFound';
import BrandList from 'components/BrandList';

// Sub-routes
import FacebookTable from 'components/FacebookTable';
import Audiences from 'components/Audiences';
import Insights from 'components/Insights/Charts';
import Trends from 'components/Insights/Trends';
import Debug from 'components/Debug';

import ProtectedRoute from './ProtectedRoute';
import Logout from './LogoutRoute';
import RouterWrapper from './RouterWrapper';
import BrandLoader from './BrandLoader';
import BrandOverview from 'components/BrandOverview';
import BrandWarningsModal from 'components/BrandWarnings/BrandWarnings.Container';
import PerformanceAds from 'components/PerformanceAds';
import { useFeatureFlag } from 'hooks';

const Router = () => {
  const { performanceLibrary } = useFeatureFlag();
  return (
    <RouterWrapper>
      <Routes>
        {/* SINGLE ROUTE MODS */}
        <Route element={<ProtectedRoute component={Outlet} />}>
          <Route index element={<BrandList />} />
          <Route path="brands" element={<BrandList />} />
          <Route path="brand">
            <Route index element={<Navigate to="/brands" />} />
            <Route path=":brandId" element={<BrandLoader />}>
              <Route index element={<BrandOverview />} />
              <Route path="overview" element={<BrandOverview />} />

              <Route path="manage" element={<FacebookTable />} />
              <Route path="audiences" element={<Audiences />} />
              <Route path="insights">
                <Route index element={<Navigate to="chart" />} />
                <Route path="chart" element={<Insights />} />
                <Route path="trends" element={<Trends />} />
              </Route>
              <Route path="warnings" element={<BrandWarningsModal />} />
            </Route>
          </Route>

          <Route path="profile" element={<UserProfile />} />
          <Route path="debug" element={<Debug />} />
          {performanceLibrary && <Route path="creatives" element={<PerformanceAds />} />}
          <Route path="*" element={<Navigate to="/brands" />} />
        </Route>
        {/* AUX ROUTE TO LOG USER OUT */}
        <Route path="logout" element={<Logout />} />
        {/* FALLBACK ROUTE */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RouterWrapper>
  );
};

export default Router;
