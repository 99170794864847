import { Form } from '@innovationdepartment/proxima-ui';
import { DeleteAudienceModalProps } from 'types/audiences';
import ModalBase from 'ui/ModalBase';
import DeleteSeedAudienceModalView from './DeleteSeedAudienceModal.View';

const DeleteSeedAudienceModal = (props: DeleteAudienceModalProps) => {
  const { open, seedAudience, onClose, onDeleteAudience } = props;

  const initialValues = {
    seedAudienceId: seedAudience?.id,
  };

  const handleSubmit = async (formData: typeof initialValues) => {
    const { seedAudienceId } = formData;

    if (!seedAudienceId) {
      return;
    }

    await onDeleteAudience();
  };

  return (
    <ModalBase open={open} title="Delete custom audience" onClose={onClose}>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <DeleteSeedAudienceModalView onClose={onClose} />
      </Form>
    </ModalBase>
  );
};

export default DeleteSeedAudienceModal;
