import styled from '@emotion/styled';
import { FacebookTableViewProps } from 'types/fbTable';
import { Header } from 'ui/Header';
import LoggedInAsBrandBadge from 'ui/LoggedInAsBrandBadge';
import FacebookTableAdAccountId from './FacebookTable.AdAccountId.Container';
import FacebookTableLastUpdatedAt from './FacebookTable.LastUpdatedAt.Container';

const HeaderWrapper = styled.div`
  padding-left: 8px;
`;

const HeaderItemsWrapper = styled.div`
  margin-left: 16px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FacebooktableHeaderView = ({
  loading,
  adAccount,
  lastUpdate,
}: Pick<FacebookTableViewProps, 'loading' | 'adAccount' | 'lastUpdate'>) => (
  <HeaderWrapper>
    <Header title="Manage" titleAppendText={<LoggedInAsBrandBadge />}>
      <HeaderItemsWrapper>
        <FacebookTableAdAccountId loading={loading} adAccount={adAccount} />
        <FacebookTableLastUpdatedAt loading={loading} lastUpdate={lastUpdate} />
      </HeaderItemsWrapper>
    </Header>
  </HeaderWrapper>
);

export default FacebooktableHeaderView;
