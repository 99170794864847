import { ToggleSwitch, DatePicker } from '@innovationdepartment/proxima-ui';
import FiltersHeaderBarControls from './Layout/Filters/FiltersHeaderBarControls';
import { PerformanceAdsViewProps } from './types';
import BrandFilter from './Layout/Filters/BrandFilter';

import styled from '@emotion/styled';

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PerformanceAdsFilters = (props: PerformanceAdsViewProps) => {
  const {
    showFilters,
    onShowFilters,
    view,
    toggleView,
    viewOptions,
    onApplyFilters,
    dateRange,
    onDateRangeChange,
  } = props;
  return (
    <FiltersHeaderBarControls>
      <BrandFilter
        open={showFilters.brand}
        onSaveSelection={(selected) => onApplyFilters({ brandId: selected })}
        onOpen={() => onShowFilters('brand')}
        onClose={() => onShowFilters('brand', false)}
      />
      <RightAligned>
        <DatePicker
          disableFutureDates
          dateRange={dateRange}
          withRange
          showSelectWithDateRange={false}
          onDateRangeSelect={onDateRangeChange}
        />
        <ToggleSwitch options={viewOptions} value={view} onChange={toggleView} />
      </RightAligned>
    </FiltersHeaderBarControls>
  );
};

export default PerformanceAdsFilters;
