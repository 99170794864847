import styled from '@emotion/styled';
import { useFormState } from 'react-final-form';
import {
  Button,
  FormField,
  SelectProps,
  Text,
  SpaceDivider,
} from '@innovationdepartment/proxima-ui';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import LoadingSpinner from 'ui/LoadingSpinner';

type EditBrandProfileModalViewProps = {
  onClose: () => void;
  flavorCategoryOptions: SelectProps['options'];
  isSubmitting: boolean;
};

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 422px;
`;

const EditBrandProfileModalView = (props: EditBrandProfileModalViewProps) => {
  const { isSubmitting, flavorCategoryOptions, onClose } = props;
  const formState = useFormState();
  const { valid, dirty } = formState;

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <ActionButtonsWrapper>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            color="secondary"
            label="Cancel"
            onClick={onClose}
          />
          <Button
            startIcon={isSubmitting && <LoadingSpinner />}
            submit
            size="small"
            disabled={!valid || !dirty || isSubmitting}
            label="Save changes"
          />
        </ActionButtonsWrapper>
      }
    >
      <ContentWrapper>
        <Text variant="body1Semibold" color="neutral100">
          Business categories
        </Text>
        <SpaceDivider y={2} />
        <Text variant="body2Semibold" color="neutral80">
          Primary category
        </Text>
        <FormField
          name="category"
          type="select"
          placeholder="Select"
          options={flavorCategoryOptions}
          disabled={isSubmitting}
          fullWidth
        />
        <SpaceDivider y={1} />
      </ContentWrapper>
    </ModalBaseChildrenWrapper>
  );
};

export default EditBrandProfileModalView;
