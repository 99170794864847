import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

type PageContentWrapperViewProps = {
  title: string;
};

const MainWrapper = styled.div`
  width: 100%;

  & > :first-child {
    margin-bottom: 24px;
  }
`;

const PageContentWrapperView: React.FC<PageContentWrapperViewProps> = ({ title, children }) => (
  <MainWrapper>
    <Text variant="h5" isTermina={false}>
      {title}
    </Text>
    {children}
  </MainWrapper>
);

export default PageContentWrapperView;
