import { BrandWarning } from '@innovationdepartment/proxima-sdk-axios';
import BrandWarningSectionView from './BrandWarningSection.View';

type BrandWarningSectionProps = {
  brandWarnings: BrandWarning[];
};

const BrandWarningSection = (props: BrandWarningSectionProps) => (
  <BrandWarningSectionView {...props} />
);

export default BrandWarningSection;
