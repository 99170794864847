import { Form, Modal } from '@innovationdepartment/proxima-ui';
import { EditDataIngestionPermissionsModalProps } from 'types/brandCustomer';

import EditDataIngestionPermissionsModalView from './EditDataIngestionPermissionsModal.View';
import { useState } from 'react';

const EditDataIngestionPermissionsModal = (props: EditDataIngestionPermissionsModalProps) => {
  const { isFileUploadAllowed, open, onClose, onUpsertBrandCustomerSettings } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initialValues = {
    isFileUploadAllowed,
  };

  const handleSubmit = async (formData: typeof initialValues) => {
    const { isFileUploadAllowed: updatedIsFileUploadAllowed } = formData;

    setIsSubmitting(true);
    await onUpsertBrandCustomerSettings(updatedIsFileUploadAllowed);
    setIsSubmitting(false);

    onClose();
  };

  return (
    <Modal open={open} title="Edit data ingestion permissions" noPadding={false}>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <EditDataIngestionPermissionsModalView isSubmitting={isSubmitting} onClose={onClose} />
      </Form>
    </Modal>
  );
};

export default EditDataIngestionPermissionsModal;
