import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

const FiltersHeaderBarControls = styled.div`
  padding: 16px 20px;
  background-color: ${colors.white};
  display: flex;
  width: 100%;
  flex-align: center;
  border-bottom: 1px solid ${colors.neutral20};
  justify-content: space-between;
`;

export default FiltersHeaderBarControls;
