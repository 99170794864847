import { Form } from '@innovationdepartment/proxima-ui';
import { useState, useEffect } from 'react';
import { AudienceSettingsModalProps } from 'types/audiences';
import ModalBase from 'ui/ModalBase';
import AudienceSettingsModalView from './AudienceSettingsModal.View';

const AudienceSettingsModal = (props: AudienceSettingsModalProps) => {
  const { seedAudience, open, onClose, onAudienceSettingsUpdate } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const initialValues = {
    seedAudience,
    seedAudienceId: seedAudience?.id,
    optedIntoUpdates: !seedAudience?.optedOutOfUpdatesAt,
    updateDayFrequency: seedAudience?.updateDayFrequency ?? '7',
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formData.updateDayFrequency) {
      errors.updateDayFrequency = 'Please select an update day frequency';
    }

    return errors;
  };

  const handleSubmit = async (formData: typeof initialValues) => {
    const { seedAudienceId, optedIntoUpdates, updateDayFrequency } = formData;

    if (!seedAudienceId) {
      return;
    }

    setIsSubmitting(true);
    await onAudienceSettingsUpdate({
      seedAudienceId,
      updateDayFrequency,
      optedIntoUpdates,
    });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (open) {
      setShowContent(true);
    } else {
      setTimeout(() => setShowContent(false), 250);
    }
  }, [open]);

  return (
    <ModalBase open={open} title="Audience settings" onClose={onClose}>
      {showContent && (
        <Form initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
          <AudienceSettingsModalView isSubmitting={isSubmitting} onClose={onClose} />
        </Form>
      )}
    </ModalBase>
  );
};

export default AudienceSettingsModal;
