import styled from '@emotion/styled';
import { Button, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

type UnlockConfirmationViewProps = {
  confirm: () => void;
  close: () => void;
  brandName: string;
};

const ButtonGroup = styled.div`
  display: inline-flex;
  justify-content: end;
  width: 100%;
  button:first-of-type {
    margin-right: 1rem;
  }
`;

const Container = styled.div`
  padding: 24px;
`;

const UnlockConfirmationView = ({ confirm, close, brandName }: UnlockConfirmationViewProps) => (
  <>
    <Container>
      <SpaceDivider y={2} />
      <Text>
        Are you sure you want to unlock access for brand{' '}
        <Text color="neutral100" variant="body1Semibold">
          {brandName}
        </Text>
        ? Doing so will allow the brand to create new audiences.
      </Text>
    </Container>
    <SpaceDivider y={4} />
    <ButtonGroup>
      <Button size="small" variant="outlined" label="Back" onClick={close} />
      <Button size="small" startIcon={<LockOpenOutlinedIcon />} label="Unlock" onClick={confirm} />
    </ButtonGroup>
  </>
);

export default UnlockConfirmationView;
