import { Form } from '@innovationdepartment/proxima-ui';
import { useState, useEffect } from 'react';
import { RenameAudienceModalProps } from 'types/audiences';
import ModalBase from 'ui/ModalBase';
import RenameAudienceModalView from './RenameSeedAudienceModal.View';

const RenameSeedAudienceModal = (props: RenameAudienceModalProps) => {
  const { open, seedAudience, onClose, onRenameAudience } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const initialValues = {
    seedAudienceId: seedAudience?.id,
    name: seedAudience?.name,
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formData.name) {
      errors.name = 'Please enter an audience name';
    }

    return errors;
  };

  const handleSubmit = async (formData: typeof initialValues) => {
    const { seedAudienceId, name } = formData;

    if (!seedAudienceId) {
      return;
    }

    setIsSubmitting(true);
    await onRenameAudience(name!);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (open) {
      setShowContent(true);
    } else {
      setTimeout(() => setShowContent(false), 250);
    }
  }, [open]);

  return (
    <ModalBase open={open} title="Rename audience" onClose={onClose}>
      {showContent && (
        <Form initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
          <RenameAudienceModalView isSubmitting={isSubmitting} onClose={onClose} />
        </Form>
      )}
    </ModalBase>
  );
};

export default RenameSeedAudienceModal;
