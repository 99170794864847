import { AllowedColors, IMenuItem, MoreMenu, Text, colors } from '@innovationdepartment/proxima-ui';
import {
  AudienceTableCellRendererProps,
  SeedAudienceTableRowProps,
  SeedAudienceTableRowExtraFunctions,
  SeedAudienceStatus,
} from 'types/audiences';

const AudienceRowCellActions = (
  props: AudienceTableCellRendererProps<SeedAudienceTableRowProps> &
    SeedAudienceTableRowExtraFunctions,
) => {
  const {
    row,
    onShowAudienceSettingsModal,
    onShowDeleteSeedAudienceModal,
    onShowRenameSeedAudienceModal,
    onShowCreateLookalikeAudienceModal,
  } = props;

  if (!row) return null;

  const { status } = row;
  const audienceDelivered = status === SeedAudienceStatus.Delivered;

  const handleClick = (action: string) => {
    switch (action) {
      case 'createLookalike':
        if (audienceDelivered) onShowCreateLookalikeAudienceModal(row);
        break;

      case 'rename':
        onShowRenameSeedAudienceModal(row);
        break;

      case 'settings':
        onShowAudienceSettingsModal(row);
        break;

      case 'delete':
        onShowDeleteSeedAudienceModal(row);
        break;

      default:
        break;
    }
  };

  const items: IMenuItem[] = [
    {
      disabled: !audienceDelivered,
      label: 'Create new lookalike',
      value: 'createLookalike',
      tooltipProps: !audienceDelivered
        ? {
            title: (
              <Text variant="tag2Medium" color="white">
                Initial audiences are still processing.
              </Text>
            ),
            arrow: true,
            placement: 'top',
          }
        : undefined,
    },
    { label: 'Rename', value: 'rename', disabled: !audienceDelivered },
    {
      disabled: !audienceDelivered,
      label: 'Settings',
      value: 'settings',
    },
    {
      label: 'Delete',
      value: 'delete',
      textColor: colors.red70 as AllowedColors,
    },
  ];

  return (
    <MoreMenu
      items={items}
      hide={!row?.isHovered}
      onClick={(value) => handleClick(value.toString())}
    />
  );
};

export default AudienceRowCellActions;
