import { Form } from '@innovationdepartment/proxima-ui';
import { useState, useEffect } from 'react';
import { RenameAudienceModalProps } from 'types/audiences';
import ModalBase from 'ui/ModalBase';
import RenameLookalikeAudienceModalView from './RenameLookalikeAudienceModal.View';

const RenameLookalikeAudienceModal = (props: RenameAudienceModalProps) => {
  const { open, lookalikeAudience, onClose, onRenameAudience } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const editableName = lookalikeAudience?.name.replace(
    `Lookalike (${lookalikeAudience.targetCountry}, ${Math.round(
      lookalikeAudience.size * 100,
    )}%) - Proxima - `,
    '',
  );

  const initialValues = {
    lookalikeAudienceId: lookalikeAudience?.id,
    name: editableName,
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formData.name) {
      errors.name = 'Please enter an audience name';
    }

    return errors;
  };

  const handleSubmit = async (formData: typeof initialValues) => {
    const { lookalikeAudienceId, name } = formData;

    if (!lookalikeAudienceId) {
      return;
    }

    setIsSubmitting(true);
    await onRenameAudience(name!);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (open) {
      setShowContent(true);
    } else {
      setTimeout(() => setShowContent(false), 250);
    }
  }, [open]);

  return (
    <ModalBase open={open} title="Rename lookalike" onClose={onClose}>
      {showContent && (
        <Form initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
          <RenameLookalikeAudienceModalView isSubmitting={isSubmitting} onClose={onClose} />
        </Form>
      )}
    </ModalBase>
  );
};

export default RenameLookalikeAudienceModal;
