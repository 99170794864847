import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import React from 'react';

type PageLayoutSectionViewContentRow = {
  label: React.ReactNode;
  content: React.ReactNode;
};

type PageLayoutSectionViewProps = {
  alignRight?: boolean;
  sections: PageLayoutSectionViewContentRow[];
};

const PageLayoutSectionWrapper = styled.div<Pick<PageLayoutSectionViewProps, 'alignRight'>>`
  display: grid;
  grid-template-columns: ${({ alignRight }) => {
    if (alignRight) return `3fr 1fr`;
    return `minmax(200px, 1fr) 1.5fr`;
  }};

  justify-items: center;
  align-items: center;
  grid-gap: 16px;
  padding: 24px 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & > * {
    justify-self: flex-start;
  }
`;

const PageLayoutSectionViewTitle = ({
  label,
}: {
  label: PageLayoutSectionViewContentRow['label'];
}) => {
  if (typeof label === 'object') {
    return <>{label}</>;
  }

  return (
    <Text variant="body2Semibold" color="neutral60">
      {label}
    </Text>
  );
};

const PageLayoutSectionViewContent = ({
  content,
}: {
  content: PageLayoutSectionViewContentRow['content'];
}) => {
  if (typeof content === 'object') {
    return <>{content}</>;
  }

  return (
    <Text variant="body2" color="neutral80">
      {content}
    </Text>
  );
};

const PageLayoutSectionView = ({ sections, alignRight = false }: PageLayoutSectionViewProps) => (
  <PageLayoutSectionWrapper alignRight={alignRight}>
    {sections.map(({ label, content }) => (
      <React.Fragment key={typeof label === 'string' ? label : label?.toString()}>
        <PageLayoutSectionViewTitle label={label} />
        <PageLayoutSectionViewContent content={content} />
      </React.Fragment>
    ))}
  </PageLayoutSectionWrapper>
);

export default PageLayoutSectionView;
