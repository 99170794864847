import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';

import { useBrandStore } from 'stores';

const HighlightName = styled.span`
  font-weight: bold;
  color: ${colors.legacy.white};
`;

const LoggedInAsBrand = () => {
  const { brand } = useBrandStore();

  if (!brand?.brandId) return null;

  return (
    <Text color="white" variant="body1">
      Current brand: <HighlightName>{brand.name}</HighlightName>
    </Text>
  );
};

export default LoggedInAsBrand;
