import BrandOverviewHeaderItemView from './BrandOverview.HeaderItem.View';
import { useCopyToClipboard } from 'hooks';

type BrandOverviewHeaderItemProps = {
  /**
   * allows to copy the content to clipboard
   */
  canCopy?: boolean;
  label: string;
  /**
   * use to readjust when title is pushed by its content
   */
  top?: number;
  content: string | React.ReactNode;
};

const BrandOverviewHeaderItem = (props: BrandOverviewHeaderItemProps) => {
  const { label, content, canCopy } = props;
  const copyToClipboard = useCopyToClipboard();

  const copyToClipboardOnClick = async () => {
    if (typeof content !== 'string') return;
    copyToClipboard(content, `${label} copied to clipboard!`);
  };

  return (
    <BrandOverviewHeaderItemView
      {...props}
      onCopyToClipboard={canCopy ? copyToClipboardOnClick : undefined}
    />
  );
};

export default BrandOverviewHeaderItem;
