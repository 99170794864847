import styled from '@emotion/styled';
import { Table, TableConfig } from '@innovationdepartment/proxima-ui';
import { AddOutlined } from '@mui/icons-material';
import { User } from 'types/users';
import { Header } from 'ui/Header';

interface IUsersProps {
  users: User[];
  tableConfig: TableConfig<User>;
  addUserButtonClick: () => void;
}

const SectionWrapper = styled.div`
  & > :last-child {
    height: unset;
  }

  & .MuiTableCell-body {
    vertical-align: unset;
  }
`;

const UsersView = ({ users, tableConfig, addUserButtonClick }: IUsersProps) => {
  const button = {
    label: 'Invite user',
    onClick: addUserButtonClick,
    icon: <AddOutlined />,
  };

  return (
    <SectionWrapper>
      <Header isTermina={false} title="Users" button={button} />
      <Table variant="altered" data={users} config={tableConfig} />
    </SectionWrapper>
  );
};

export default UsersView;
