import { BackArrow, Input, Lens, Text } from '@innovationdepartment/proxima-ui';
import { BrandFilterViewProps } from '../types';
import styled from '@emotion/styled';

const BackArrowWrapper = styled.div`
  cursor: pointer;

  animation: show 0.2s;

  @keyframes show {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
`;

const BrandFilterTitle = () => (
  <Text isTermina={false} variant="h6">
    Brands
  </Text>
);

const BrandFilterSearch = (props: BrandFilterViewProps) => {
  const { focused, onFocus, rerender, onBackClick, searchCriteria, setSearchCriteria } = props;

  return (
    <>
      <BrandFilterTitle />
      <Input
        key={rerender}
        fullWidth
        variant="underline"
        onFocus={onFocus}
        placeholder="Search for brands"
        value={searchCriteria}
        onChange={setSearchCriteria}
        startAdornment={
          focused ? (
            <BackArrowWrapper onClick={onBackClick}>
              <BackArrow />
            </BackArrowWrapper>
          ) : undefined
        }
        endAdornment={<Lens />}
      />
    </>
  );
};

export default BrandFilterSearch;
