import styled from '@emotion/styled';
import ContentSection from 'ui/ContentSection';
import { IntegrationsProcessing, Text } from '@innovationdepartment/proxima-ui';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 12px;
`;

const LookalikeSizeSubmitting = () => (
  <ContentSection>
    <ContentWrapper>
      <HeaderWrapper>
        <Text variant="h6">Sending audiences to Facebook...</Text>
        <Text variant="body2">We’ll email you when they’re ready</Text>
      </HeaderWrapper>
      <IntegrationsProcessing />
    </ContentWrapper>
  </ContentSection>
);

export default LookalikeSizeSubmitting;
