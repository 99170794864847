import { MouseEvent } from 'react';
import styled from '@emotion/styled';
import { AllowedColors, MoreMenu, colors } from '@innovationdepartment/proxima-ui';

type ActionProps = {
  isHovering: boolean;
  brandInfo: { brandId: string; status: string };
  showMenu: boolean;
  confirmDeleteBrand: (brandId: string) => void;
  confirmLockBrand: (brandId: string) => void;
  confirmUnlockBrand: (brandId: string) => void;
};

const MoreMenuWrapper = styled.div<{ showMenu: boolean; isHovering: boolean }>`
  display: ${(props) => (props.showMenu ? 'block' : 'none')};
  opacity: ${(props) => (props.isHovering ? 'inherit' : 0)};
`;

const Action = ({
  brandInfo,
  showMenu,
  confirmDeleteBrand,
  confirmLockBrand,
  confirmUnlockBrand,
  isHovering,
}: ActionProps) => {
  const lockUnlockOption =
    brandInfo.status === 'locked'
      ? { label: 'Unlock access', value: 'unlock' }
      : { label: 'Lock access', value: 'lock', textColor: colors.red70 as AllowedColors };

  const menuItems = [lockUnlockOption, { label: 'Delete brand', value: 'delete' }];

  const handleClick = (action: string) => {
    if (action === 'delete') {
      confirmDeleteBrand(brandInfo.brandId);
    }
    if (action === 'lock') {
      confirmLockBrand(brandInfo.brandId);
    }
    if (action === 'unlock') {
      confirmUnlockBrand(brandInfo.brandId);
    }
  };

  return (
    <MoreMenuWrapper
      id={`more-menu-${brandInfo.brandId}`}
      isHovering={isHovering}
      showMenu={showMenu}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <MoreMenu
        onClick={(e) => handleClick(e.toString())}
        items={menuItems}
        anchorOrigin="bottom-left"
        transformOrigin="center-right"
      />
    </MoreMenuWrapper>
  );
};
export default Action;
