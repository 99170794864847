import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Text, colors } from '@innovationdepartment/proxima-ui';

const ContentSectionTitleWrapper = styled.div`
  border-bottom: 1px solid ${colors.neutral10};
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    margin-bottom: -8px;

    & svg {
      width: 20px;
    }

    & * {
      color: ${colors.neutral100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
`;

const ContentSectionTitle = ({
  title,
  buttonProps,
}: {
  title: string | JSX.Element;
  buttonProps?: PropsOf<typeof Button>;
}) => (
  <ContentSectionTitleWrapper>
    <Text variant="body1Semibold">{title}</Text>
    {buttonProps && <Button {...buttonProps} variant="text" size="small" />}
  </ContentSectionTitleWrapper>
);

export default ContentSectionTitle;
