import styled from '@emotion/styled';
import { Modal } from '@innovationdepartment/proxima-ui';
import ModalBaseHeader from './ModalBase.Header';
import { ModalBaseProps } from './types';

const ContentWrapper = styled.div<{ full: boolean }>`
  display: ${({ full }) => (full ? 'block' : 'flex')};
  height: 100%;
  overflow: auto;
`;

const ModalBaseView: React.FC<ModalBaseProps> = (props) => {
  const { children, open, onClose, allowCloseOnClickOutside = false, full = false } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      noPadding
      resizable={!full}
      full={full}
      allowCloseOnClickOutside={allowCloseOnClickOutside}
    >
      <ModalBaseHeader {...props} />
      <ContentWrapper full={full}>{children}</ContentWrapper>
    </Modal>
  );
};

export default ModalBaseView;
