import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { BrandFilterViewProps } from '../types';

const Scroller = styled.div`
  margin: -4px 0;
  width: 100px;
  position: relative;
`;

const BrandFilterInfiniteScroll = (props: BrandFilterViewProps) => {
  const { onGetNextBrandBatch, brands, searchCriteria } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onGetNextBrandBatch?.();
          }
        });
      },
      { root: null, rootMargin: '100px', threshold: 1 },
    );

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer?.unobserve(ref.current as Element);
    };
  }, [searchCriteria, brands]);

  return <Scroller ref={ref} />;
};

export default BrandFilterInfiniteScroll;
