import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  CreateSeedAudiencesCOLALFormSchema,
  CreateSeedAudiencesFormSchema,
  CreateSeedAudiencesNamesProps,
} from 'types/audiences';
import CreateSeedAudiencesNamesView from './CreateSeedAudiencesNames.View';

const CreateSeedAudiencesNames = (props: CreateSeedAudiencesNamesProps) => {
  const { tab, loading, flavorCategory } = props;
  const [loaded, setLoaded] = useState(false);
  const { values } = useFormState<
    CreateSeedAudiencesFormSchema & CreateSeedAudiencesCOLALFormSchema
  >();
  const form = useForm();

  useEffect(() => {
    const getFieldName = (galaxy: CreateSeedAudiencesFormSchema['galaxies'][number]) => {
      if (tab === 'categoryShoppers' && flavorCategory) {
        const [galaxyName] = galaxy.flavor.name.split(' - ');
        const galaxyNameNoSpaces = galaxyName.replace(/\s/g, '');
        return `${flavorCategory.name}-${galaxyNameNoSpaces}`;
      }

      if (tab === 'colal') {
        const { audienceDefaultName } = values;
        return audienceDefaultName;
      }

      /* think through default (unlikely) return value ??? */
      return '';
    };

    const createNameFields = () => {
      // generate names for each galaxy
      const { galaxies } = values;

      galaxies.forEach((galaxy, index) => {
        const name = getFieldName(galaxy);

        const fieldName = `names[${index}]`;

        // let's use the object type from the schema
        const field = { name, galaxyId: galaxy.id };

        // fields are in the form on loading, we gotta register to avoid crash while formatting the default name
        form.registerField(fieldName, () => {}, {});
        // set the field value
        form.change(fieldName, field);
        // lets reset the field state so that the field is not marked as touched
        form.resetFieldState(fieldName);
      });

      setLoaded(true);
    };

    if (!loading) {
      if (loaded) return;

      /* categoryShoppers tab needs flavorCategory to register fields */
      /* TODO(Jenky): need to fix bug where form.change does not update field after mounted */
      if (tab === 'categoryShoppers' && Boolean(flavorCategory)) {
        createNameFields();
      } else if (tab === 'colal') {
        createNameFields();
      }
    }
  }, [loading, loaded, flavorCategory]);

  return <CreateSeedAudiencesNamesView {...props} loading={!loaded && loading} />;
};

export default CreateSeedAudiencesNames;
