import { PropsOf } from '@emotion/react';
import { DatePicker } from '@innovationdepartment/proxima-ui';
import FacebookTableSearchFilterView from './FacebookTableSearchFilter.View';

type FacebookTableSearchFilterProps = {
  dateRange: PropsOf<typeof DatePicker>['dateRange'];
  onDateRangeChange: PropsOf<typeof DatePicker>['onDateRangeSelect'];
};
const FacebookTableSearchFilter: React.FC<FacebookTableSearchFilterProps> = (props) => (
  <FacebookTableSearchFilterView {...props} />
);

export default FacebookTableSearchFilter;
