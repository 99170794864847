import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { PerformanceAdsViewProps } from 'components/PerformanceAds/types';
import LoadingBar from 'ui/LoadingBar';

const ResultsBarWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );

  padding: 16px;
  z-index: 1;
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const LoadingBarWrapper = styled.div`
  position: absolute;
  left: -16px;
  top: 0px;
  width: 100%;
`;

const PerformanceAdsTilesResultsBar = (props: PerformanceAdsViewProps) => {
  const { loading } = props;

  return (
    <ResultsBarWrapper>
      {/* TODO(Jenky): Possibly might convert to "utilities" wrapper, for sorting elements, etc. */}
      <LoadingBarWrapper>
        <LoadingBar loading={loading} />
      </LoadingBarWrapper>
      <TextWrapper>
        <Text variant="h6"> Results</Text>
      </TextWrapper>
    </ResultsBarWrapper>
  );
};

export default PerformanceAdsTilesResultsBar;
