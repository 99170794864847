import { PropsOf } from '@emotion/react';
import {
  COLALProps,
  CreateSeedAudienceSchema,
  CreateSeedAudiencesCOLALFormSchema,
} from 'types/audiences';
import { SeedAudienceType } from '@innovationdepartment/proxima-sdk-axios';
import { Form, useToaster } from '@innovationdepartment/proxima-ui';
import COLALView from './COLALs.View';
import { useAdAccountStore, useBrandStore, useIntegrationsStore } from 'stores';
import { IntegrationStatus, IntegrationType } from 'types/integrations';
import { useProximaSDK, useShowSpinner } from 'hooks';
import { AxiosError } from 'axios';
import { formatDateToIsoWithoutTime } from 'utils';

const COLAL = (props: COLALProps) => {
  const { galaxies: allGalaxies, onSeedAudiencesCreation, onClose } = props;
  const { getAllIntegrations } = useIntegrationsStore();
  const { metaAdAccount } = useAdAccountStore();
  const { brand } = useBrandStore();
  const brandsApi = useProximaSDK('BrandsApi');
  const integrationsApi = useProximaSDK('IntegrationsApi');
  const audiencesApi = useProximaSDK('AudiencesApi');
  const { showToaster } = useToaster();
  const isLoading = brandsApi.loading || integrationsApi.loading || audiencesApi.loading;

  /* get only active shopify integration */
  const [shopifyIntegration] = getAllIntegrations({ type: IntegrationType.Shopify, active: true });

  // example of output:
  // brand name: Random Brand Name just becaue
  // output: Random_Brand_Name_just_because-COLAL-02/02/2022
  const brandName = brand.name.split(' ').join('_');
  const audienceDefaultName = `${brandName}-COLAL-${formatDateToIsoWithoutTime(new Date())}`;

  const galaxies = allGalaxies
    .filter((galaxy) => galaxy.isInternal)
    .map((galaxy) => ({
      ...galaxy,
      flavor: {
        ...galaxy.flavor,
        /* renames to "Audience name" instead of "Flavor name"; see CreateSeedAudiencesNames.View.tsx:31 */
        name: 'Audience name',
      },
    }));

  const initialValues: CreateSeedAudiencesCOLALFormSchema = {
    galaxies,
    audienceDefaultName,
    dataSource: 'original',
    brandId: brand.brandId,
    lookalikeSize: '0.1' as unknown as number,
    integrationType: IntegrationType.Facebook,
    names: [],
    referenceBrandProportionTarget: 25,
  };

  const canCreate = initialValues.galaxies.length > 0 && Boolean(metaAdAccount);

  type FormProps = PropsOf<typeof Form<CreateSeedAudiencesCOLALFormSchema>>;

  useShowSpinner({ show: audiencesApi.loading });

  const onSubmit: FormProps['onSubmit'] = async (formData, formApi) => {
    /* if no ad account, do not allow to submit */
    if (!canCreate) return undefined;

    const shopifyIsRequired = formData.dataSource === 'original' && !shopifyIntegration;

    if (shopifyIsRequired) {
      showToaster({
        message: 'Shopify integration is required',
        variant: 'error',
      });

      return undefined;
    }

    if (formData.dataSource === 'alternative') {
      // TODO(Jenky): discuss with Product
      // if (formData.brandId === brand.brandId) {
      //   return { brandId: 'Brand ID must not be same as current brand.' };
      // }

      /* check if brand id is available */
      const [brandValid, integrationValid] = await Promise.all([
        brandsApi
          .getBrand({ brandId: formData.brandId })
          .then(() => true)
          .catch(() => false),
        integrationsApi
          .getIntegrationsForBrand({ brandId: formData.brandId })
          .then(({ data }) =>
            data.some(
              (integration) =>
                integration.type === IntegrationType.Shopify &&
                integration.status === IntegrationStatus.Active,
            ),
          )
          .catch(() => false),
      ]);
      if (!brandValid) return { brandId: 'Invalid brand ID' };
      if (!integrationValid) return { brandId: 'Brand is not connected to Shopify' };
    }

    let referenceBrandTargetPercentage: string | number | undefined =
      formData.referenceBrandProportionTarget ?? '25';

    if (!shopifyIntegration) {
      /* if not connected to shopify, remove target percentage value */
      referenceBrandTargetPercentage = undefined;
    } else {
      /* convert to percentage */
      referenceBrandTargetPercentage = +referenceBrandTargetPercentage / 100;
    }

    const data: CreateSeedAudienceSchema = {
      galaxyIds: formData.galaxies.map((galaxy) => galaxy.id),
      integrations: [
        {
          adAccountId: metaAdAccount?.accountId,
          integrationType: formData.integrationType,
          size:
            formData.lookalikeSize ??
            0.01 /* unlikely to get here without validation but TS complains */,
        },
      ],
      seedAudienceType: SeedAudienceType.BrandOverlap,
      referenceBrandProportionTarget: referenceBrandTargetPercentage,
      names: formData.names,
    };

    if (formData.dataSource === 'alternative') {
      data.config = { alternateBrandId: formData.brandId };
    }

    try {
      await audiencesApi.createSeedAudience(
        {
          brandId: brand.brandId,
          createSeedAudienceRequest: data,
        },
        { skipErrorToaster: true },
      );

      showToaster({
        message: 'Seed audience created',
        variant: 'success',
      });

      onSeedAudiencesCreation();
      onClose();
      formApi.reset();
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errors: { path: string; message: string }[] = err.response?.data ?? [];
        return errors.reduce(
          (acc, error) => ({ ...acc, [error.path]: error.message }),
          {} as Record<string, string>,
        );
      }

      showToaster({
        message: 'Failed to create seed audience',
        variant: 'error',
      });
    }

    return undefined;
  };

  const validate: FormProps['validate'] = async (formData) => {
    type ValidationErrorValue = string | string[] | { name: string }[];
    type ValidationError = { [key in keyof typeof initialValues]?: ValidationErrorValue };
    const errors: ValidationError = {};

    const { referenceBrandProportionTarget } = formData;

    const proportionTargetString = +(referenceBrandProportionTarget ?? '');

    const proportionTargetOutOfValidRange =
      proportionTargetString > 100 || proportionTargetString < 0;

    if (!proportionTargetString) errors.referenceBrandProportionTarget = 'Data cap required';
    else if (proportionTargetOutOfValidRange)
      errors.referenceBrandProportionTarget = 'Must be between 0-100%';

    if (!formData.brandId) errors.brandId = 'Brand ID required';

    /* validate names of seed audiences */
    formData.names?.forEach((name, index) => {
      const setError = (error: string) => {
        if (!errors.names) errors.names = [];
        /* lets set the errors in the same format as the form data */
        (errors.names as { name: string }[])[index] = { name: error };
      };

      if (!name.name) setError('Audience name required');
      /* TODO(Jenky): discuss with Product */ else if (name.name.length > 100)
        setError('Seed audience name is too long (max 100 chars)');
      else if (name.name.length < 10) setError('Seed audience name is too short (min 10 chars)');
    });

    return errors;
  };

  return (
    <Form validate={validate} validateOnBlur initialValues={initialValues} onSubmit={onSubmit}>
      <COLALView {...props} canCreate={canCreate} loading={isLoading} />
    </Form>
  );
};

export default COLAL;
