import { AudienceTableCellRendererProps, SeedAudienceTableRowProps } from 'types/audiences';
import CellWrapper from './AudienceRowCell.Wrapper';
import { PLATFORMS } from './utils';

const SeedAudienceRowCellPlatform = ({
  row,
}: AudienceTableCellRendererProps<SeedAudienceTableRowProps>) => (
  <CellWrapper gap={4} flexDirection="row">
    {row?.integrationTypes
      ? row?.integrationTypes.map((integrationType) => PLATFORMS[integrationType])
      : null}
  </CellWrapper>
);

export default SeedAudienceRowCellPlatform;
