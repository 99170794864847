import { Form } from '@innovationdepartment/proxima-ui';
import { useEffect, useState } from 'react';
import { useAudiencesApi } from 'hooks';
import {
  CreateLookalikeAudienceModalProps,
  LookalikeSizeSchema,
  SeedAudienceSchema,
} from 'types/audiences';
import ModalBase from 'ui/ModalBase';
import { IntegrationType } from 'types/integrations';
import CreateLookalikeAudienceModalView from './CreateLookalikeAudienceModal.View';

const CreateLookalikeAudienceModal = (props: CreateLookalikeAudienceModalProps) => {
  const { open, seedAudience, onCreateLookalikeAudience, onClose } = props;
  const [sizes, setSizes] = useState<LookalikeSizeSchema[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seedAudienceToUse, setSeedAudienceToUse] = useState<SeedAudienceSchema | undefined>(
    seedAudience,
  );
  const { loading, getCurrentLookalikeAudienceSizes } = useAudiencesApi();

  const initialValues = {
    lookalikeSize: undefined as number | undefined,
    integrationType: IntegrationType.Facebook,
  };

  const onSubmit = async (formValues: typeof initialValues) => {
    if (!seedAudience) return;

    setIsSubmitting(true);
    onCreateLookalikeAudience({
      seedAudienceId: seedAudience.id,
      size: formValues.lookalikeSize ?? 0, // unlikely to happen, but TS complains otherwise
      integrationType: formValues.integrationType,
    });
  };

  const validate = (formData: typeof initialValues) => {
    const errors: { [key in keyof typeof initialValues]?: string } = {};

    if (!formData.lookalikeSize) errors.lookalikeSize = 'Please select a lookalike size';

    return errors;
  };

  useEffect(() => {
    const fetchSizes = async () => {
      if (!seedAudience) return;

      try {
        const sizeResponse = await getCurrentLookalikeAudienceSizes({
          seedAudienceId: seedAudience.id,
        });
        setSizes(sizeResponse.data);
      } catch (err) {
        /* */
      }
    };
    if (seedAudience) fetchSizes();
  }, [seedAudience]);

  useEffect(() => {
    // handles not to disappear the data when modal is closing
    // as seedAudience is being used as the prop to show the modal
    if (seedAudience) {
      setSeedAudienceToUse(seedAudience);
      setIsSubmitting(false);
    }
  }, [seedAudience]);

  return (
    <ModalBase
      open={open}
      header={{ padding: 'small' }}
      title="Create a lookalike"
      onClose={onClose}
    >
      <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        <CreateLookalikeAudienceModalView
          isSubmitting={isSubmitting}
          onClose={onClose}
          loading={loading}
          sizes={sizes}
          seedAudience={seedAudienceToUse}
        />
      </Form>
    </ModalBase>
  );
};

export default CreateLookalikeAudienceModal;
