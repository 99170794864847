import { PropsOf } from '@emotion/react';
import { Banner, Button } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'types/integrations';

// TODO(Jenky): replace this once we accept more than one integration
type Types = IntegrationType.Facebook;

const contentDefaults: {
  [key in Types]: { banner: PropsOf<typeof Banner>; button: PropsOf<typeof Button> };
} = {
  facebook: {
    banner: {
      title: 'We lost connection to this Facebook ad account',
      body: 'Please contact the customer to reintegrate.',
      type: 'error',
    },
    button: {
      label: 'Reconnect Facebook',
      variant: 'outlined',
      size: 'small',
      onClick: () => {},
    },
  },
};

type IntegrationDisconnectionWarningBannerProps = {
  type: Types;
  footer: boolean;
};

const IntegrationDisconnectionWarningBannerView = (
  props: IntegrationDisconnectionWarningBannerProps,
) => {
  const { type, footer } = props;
  const { banner } = contentDefaults[type];

  return <Banner {...banner} footer={footer} />;
};

export default IntegrationDisconnectionWarningBannerView;
