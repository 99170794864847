import { CreateSeedAudienceRequest } from '@innovationdepartment/proxima-sdk-axios';
import { useHandleApi, useProximaSDK } from 'hooks';
import { useState } from 'react';
import { useBrandStore } from 'stores';
import {
  FlavorCategorySchema,
  AudienceSettingsSchema,
  AudienceUpdateSettingsSchema,
  SeedAudienceSchema,
  CreateLookalikeAudienceSchema,
  RetryLookalikeAudienceSchema,
  UpdateBrandAudienceSettingsInput,
} from 'types/audiences';

/* TODO(Jenky): implement loading when making requests via sdk */
const useAudiencesApi = () => {
  const { loading: apiLoading, handleApi } = useHandleApi();
  const audiencesApi = useProximaSDK('AudiencesApi');
  const { brand } = useBrandStore();
  const [isLoading, setIsLoading] = useState(false);

  const loading = isLoading || apiLoading;

  const getSeedAudiences = async () => {
    const { brandId } = brand;
    const response = audiencesApi.getSeedAudiences({ brandId });
    return response;
  };

  const getAvailableFlavorCategories = async () => {
    const endpoint = '/flavor-category';
    return handleApi({ endpoint });
  };

  const getAvailableGalaxies = async () => {
    const { brandId } = brand;
    setIsLoading(true);
    const response = await audiencesApi.getAvailableGalaxies({
      brandId,
      includeAllowMultipleAudiences: true,
      includeInternal: true,
    });
    setIsLoading(false);
    return response;
  };

  const createSeedAudience = async (data: CreateSeedAudienceRequest) => {
    const { brandId } = brand;
    setIsLoading(true);
    const response = await audiencesApi.createSeedAudience({
      brandId,
      createSeedAudienceRequest: data,
    });
    setIsLoading(false);

    return response;
  };

  const getBrandAudienceSettings = async () => {
    const { brandId } = brand;
    const endpoint = `/brand/${brandId}/audience-settings`;
    return handleApi({ endpoint });
  };

  const updateBrandAudienceSettings = async (data: UpdateBrandAudienceSettingsInput) => {
    const { brandId } = brand;
    const endpoint = `/brand/${brandId}/audience-settings`;

    return handleApi({ endpoint, method: 'PATCH', data });
  };

  const updateAudienceSettings = async (settingsData: Partial<AudienceSettingsSchema>) => {
    const { brandId } = brand;
    const { seedAudienceId, ...data } = settingsData;
    const endpoint = `/brand/${brandId}/seed-audience/${seedAudienceId}`;

    return handleApi({ endpoint, method: 'PATCH', data });
  };

  const updateAudienceUpdateSettings = async (data: AudienceUpdateSettingsSchema) => {
    const { brandId } = brand;
    const endpoint = `/brand/${brandId}/audience-update`;

    return handleApi({ endpoint, method: 'PUT', data });
  };

  const renameSeedAudience = async ({
    seedAudienceId,
    newName,
  }: {
    seedAudienceId: string;
    newName: string;
  }) => {
    const { brandId } = brand;
    setIsLoading(true);
    const response = await audiencesApi.renameSeedAudience({
      brandId,
      seedAudienceId,
      renameAudienceRequest: { newName },
    });
    setIsLoading(false);
    return response;
  };

  const renameLookalikeAudience = async ({
    seedAudienceId,
    lookalikeAudienceId,
    newName,
  }: {
    seedAudienceId: string;
    lookalikeAudienceId: string;
    newName: string;
  }) => {
    const { brandId } = brand;
    setIsLoading(true);
    const response = await audiencesApi.renameLookalikeAudience({
      brandId,
      seedAudienceId,
      lookalikeAudienceId,
      renameAudienceRequest: { newName },
    });
    setIsLoading(false);
    return response;
  };

  const deleteSeedAudience = async (seedAudienceId: string) => {
    const { brandId } = brand;
    setIsLoading(true);
    const response = await audiencesApi.deleteSeedAudience({
      brandId,
      seedAudienceId,
    });
    setIsLoading(false);

    return response;
  };

  const deleteLookalikeAudience = async ({
    seedAudienceId,
    lookalikeAudienceId,
  }: {
    seedAudienceId: string;
    lookalikeAudienceId: string;
  }) => {
    const { brandId } = brand;
    setIsLoading(true);
    const response = await audiencesApi.deleteLookalikeAudience({
      brandId,
      seedAudienceId,
      lookalikeAudienceId,
    });
    setIsLoading(false);

    return response;
  };

  const getCurrentLookalikeAudienceSizes = async ({
    seedAudienceId,
  }: {
    seedAudienceId: SeedAudienceSchema['id'];
  }) => {
    setIsLoading(true);
    const response = await audiencesApi.getLookalikeAudienceSizes({
      brandId: brand.brandId,
      seedAudienceId,
    });
    setIsLoading(false);
    return response;
  };

  const createLookalikeAudience = async (sizeData: CreateLookalikeAudienceSchema) => {
    setIsLoading(true);
    const response = await audiencesApi.createLookalikeAudience({
      brandId: brand.brandId,
      seedAudienceId: sizeData.seedAudienceId,
      createLookalikeAudienceRequest: {
        // @ts-ignore
        integrationType: sizeData.integrationType!,
        size: sizeData.size,
      },
    });
    setIsLoading(false);
    return response;
  };

  const retryLookalikeAudience = async ({
    seedAudienceId,
    lookalikeAudienceId,
  }: RetryLookalikeAudienceSchema) => {
    setIsLoading(true);
    const response = await audiencesApi.retryLookalikeAudience({
      brandId: brand.brandId,
      seedAudienceId,
      lookalikeAudienceId,
    });
    setIsLoading(false);
    return response;
  };

  /* helper methods */
  const getBrandFlavorCategory = async () => {
    const [
      { data: { flavorCategoryId } = { flavorCategoryId: null } },
      { data: flavorCategories = [] },
    ] = await Promise.all([getBrandAudienceSettings(), getAvailableFlavorCategories()]);

    if (!flavorCategoryId || !flavorCategories) return undefined;
    const flavorCategory = (flavorCategories as FlavorCategorySchema[]).find(
      (category) => category.id === flavorCategoryId,
    );
    return flavorCategory;
  };

  return {
    loading,
    getSeedAudiences,
    getBrandAudienceSettings,
    updateBrandAudienceSettings,
    getAvailableFlavorCategories,
    updateAudienceSettings,
    updateAudienceUpdateSettings,
    renameSeedAudience,
    renameLookalikeAudience,
    getAvailableGalaxies,
    createSeedAudience,
    deleteSeedAudience,
    deleteLookalikeAudience,
    getCurrentLookalikeAudienceSizes,
    createLookalikeAudience,
    retryLookalikeAudience,
    /* helper methods */
    getBrandFlavorCategory,
  };
};

export default useAudiencesApi;
