import styled from '@emotion/styled';
import { Button, Text, SpaceDivider } from '@innovationdepartment/proxima-ui';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';

type DeleteSeedAudienceModalViewProps = {
  onClose: () => void;
};

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 640px;
`;

const DeleteSeedAudienceModalView = (props: DeleteSeedAudienceModalViewProps) => {
  const { onClose } = props;

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <ActionButtonsWrapper>
          <Button variant="outlined" color="secondary" label="Cancel" onClick={onClose} />
          <Button submit size="small" color="error" label="Delete" />
        </ActionButtonsWrapper>
      }
    >
      <ContentWrapper>
        <SpaceDivider y={2} />
        <Text variant="body1" color="neutral100">
          If you delete this Custom audience, all related lookalike audiences will be deleted as
          well. This audience will no longer be available to be used in your Meta Ad Sets.
        </Text>
        <SpaceDivider y={1} />
        <Text variant="body1" color="neutral100">
          Are you sure you want to delete?
        </Text>
        <SpaceDivider y={4} />
      </ContentWrapper>
    </ModalBaseChildrenWrapper>
  );
};

export default DeleteSeedAudienceModalView;
