import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

type ModalBaseChildrenWrapperProps = {
  submitButton: React.ReactNode;
};

const ModalWrapper = styled.div`
  position: relative;
  height: 100%;
  max-width: 1398px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
`;

const ButtonGroup = styled.div`
  background-color: ${colors.white};
  display: flex;
  border-top: 1px solid ${colors.neutral20};
  padding: 16px;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
`;

const ModalBaseChildrenWrapper: React.FC<ModalBaseChildrenWrapperProps> = ({
  children,
  submitButton,
}) => (
  <ModalWrapper>
    <ContentWrapper>{children}</ContentWrapper>
    <ButtonGroup>{submitButton}</ButtonGroup>
  </ModalWrapper>
);

export default ModalBaseChildrenWrapper;
