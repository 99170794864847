import Plan from './PlanFilter.Container';
import { Button, Text } from '@innovationdepartment/proxima-ui';
import SpendActivityFilter from './SpendActivityFilter.Container';
import ClearIcon from '@mui/icons-material/Clear';

type FiltersViewProps = {
  onFilterPlan: (selectedPlans: string[]) => void;
  onFilterSpend: (selectedItems: string[]) => void;
  clearFilters: () => void;
  hasSelectedFilters: boolean;
};

const FiltersView = ({
  onFilterPlan,
  onFilterSpend,
  clearFilters,
  hasSelectedFilters,
}: FiltersViewProps) => (
  <>
    <Text variant="body1"> FILTERS: </Text>
    <SpendActivityFilter onFilterSpend={onFilterSpend} />
    <Plan onFilterPlan={onFilterPlan} />
    {hasSelectedFilters && (
      <Button
        startIcon={<ClearIcon style={{ fill: 'black' }} />}
        variant="outlined"
        label="Clear filters"
        color="secondary"
        overrideColors={{ backgroundColor: 'transparent' }}
        onClick={clearFilters}
      />
    )}
  </>
);

export default FiltersView;
