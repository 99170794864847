import { SeedAudiencesTableViewProps } from 'types/audiences';
import styled from '@emotion/styled';
import { Table } from '@innovationdepartment/proxima-ui';

const Container = styled.div<{ extend?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 1px;
  flex: 1;

  ${({ extend }) => {
    if (!extend) return '';

    return `
      & table {
        height: inherit;
      }
    `;
  }}

  * > :nth-child(2) {
    align-self: stretch;
  }
`;

const SeedAudiencesTableView = (props: SeedAudiencesTableViewProps) => {
  const { seedAudiences, config } = props;

  return (
    <Container extend={seedAudiences.length === 0}>
      <Table data={seedAudiences} config={config} />
    </Container>
  );
};

export default SeedAudiencesTableView;
