import { Integration, IntegrationType } from 'types/integrations';

export enum IntegrationStatus {
  Active = 'active',
  Expired = 'expired',
  Unauthorized = 'unauthorized',
  // Deleted = 'deleted',
}

export type IntegrationStore = {
  integrations: Integration[];
  clear: () => void;
  deleteIntegration: (integrationId: string) => void;
  addIntegration: (integration: Integration | Integration[]) => void;
  getAllIntegrations: (props?: { type?: IntegrationType; active?: boolean }) => Integration[];
};
