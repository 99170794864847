import { Text, colors } from '@innovationdepartment/proxima-ui';
import { Close } from '@mui/icons-material';
import styled from '@emotion/styled';
import { ModalBaseProps } from './types';

const HeaderWrapper = styled.div<Pick<ModalBaseProps, 'header'>>`
  background-color: ${colors.neutral5};
  border-bottom: 1px solid ${colors.neutral20};
  ${({ header }) => {
    let padding = `16px`;
    let paddingBottom = '';

    if (header?.padding === 'normal') padding = '24px 32px';
    if (header?.bottomContent) paddingBottom = '0';

    return `
      padding: ${padding};
      ${paddingBottom && `padding-bottom: ${paddingBottom}`};
    `;
  }}
`;

const HeaderContentWrapper = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral20};
  border-radius: 4px;
  display: grid;
  place-content: center;
  margin-right: 16px;
`;

const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const ModalBaseHeader: React.FC<ModalBaseProps> = (props) => {
  const { title, icon, onClose, header } = props;
  let headerTitle: React.ReactNode = (
    <Text color="neutral100" variant="h6">
      {title}
    </Text>
  );

  if (typeof title !== 'string') headerTitle = title;

  return (
    <HeaderWrapper header={header}>
      <HeaderContentWrapper>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <TitleWrapper>{headerTitle}</TitleWrapper>
        {onClose && (
          <CloseIconWrapper onClick={onClose}>
            <Close />
          </CloseIconWrapper>
        )}
      </HeaderContentWrapper>
      {header?.bottomContent}
    </HeaderWrapper>
  );
};

export default ModalBaseHeader;
