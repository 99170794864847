import { DatePickerProps } from '@innovationdepartment/proxima-ui';
import { Brand } from './stores/brand-store';
import {
  AudienceSpendData,
  AudienceSpendSummaryData,
  BrandWarning,
  FlavorCategory,
  GetLookalikeAudiencesStatsResponse,
  Subscription,
  SubscriptionType,
} from '@innovationdepartment/proxima-sdk-axios';
import { DataIngestionViewProps } from './brandCustomer';

type BrandOverviewSection =
  | 'user'
  | 'brand'
  | 'billing'
  | 'audiencesSpend'
  | 'audiencesSpendSummary'
  | 'audiencesStats'
  | 'flavorCategory'
  | 'flavorCategories'
  | 'brandAudienceSettings'
  | 'brandWarnings'
  | 'campaigns';

export const BillingMappings = {
  [SubscriptionType.FreeTrial]: 'Trial',
  [SubscriptionType.Pro]: 'Pro',
  [SubscriptionType.Yearly]: 'Yearly',
  [SubscriptionType.Monthtly]: 'Monthly',
} as const;

type BrandOverviewLoadingState = { [key in BrandOverviewSection]?: boolean };

export type CommonLoadingProps = {
  apiLoading: BrandOverviewLoadingState;
  loading: boolean;
};

export type BrandWarningsProps = {
  brandWarnings: BrandWarning[];
};

export type PaginationMapBrandWarnings = Record<
  number,
  { prevToken: string | undefined; nextToken: string | undefined }
>;

export type BrandInfoProps = CommonLoadingProps & {
  brand: Brand;
  brandSubscription: Subscription;
  last30DaysOfAllBrandSpend: number | undefined;
  audienceSpendSummary: AudienceUsageProps['audienceSpendSummary'];
};

export type BrandProfileProps = CommonLoadingProps & {
  flavorCategory?: FlavorCategory;
  flavorCategoryOptions: { value: string; label: string }[];
  onEditBrandCategory: () => void;
  onEditDataIngestionPermissions: () => void;
};

export type AudienceUsageProps = CommonLoadingProps & {
  audienceSpend: AudienceSpendData;
  audienceSpendSummary: AudienceSpendSummaryData;
  audienceStats: GetLookalikeAudiencesStatsResponse;
  dateRange: DatePickerProps['dateRange'];
  last30DaysOfAllBrandSpend: number | undefined;
  onAudienceDateRangeSelect: (dateRange: DatePickerProps['dateRange']) => void;
};

/* components types */
export type AudiencesSectionProps = AudienceUsageProps;

export type BrandSectionProps = BrandProfileProps & BrandInfoProps;

export type BrandOverviewProps = BrandProfileProps &
  BrandInfoProps &
  AudiencesSectionProps &
  BrandWarningsProps &
  DataIngestionViewProps;

/* useBrandOverview hook types */
export type BrandOverviewHookProps = Omit<
  BrandOverviewProps,
  'onEditBrandCategory' | 'onEditDataIngestionPermissions'
> & {
  onUpdateBrandCategory: (newFlavorCategory: FlavorCategory) => Promise<void>;
  onUpsertBrandCustomerSettings: (updatedIsFileUploadAllowed: boolean) => Promise<void>;
};
