import styled from '@emotion/styled';
import { PerformanceAdsViewProps } from 'components/PerformanceAds/types';
import { BaseTable } from '@innovationdepartment/proxima-fbtable';
import { PERFORMANCE_ADS_COLUMN_DEFINITIONS } from './definitions';
import cellRenderer from './performanceAdsCellRender';

const TableWrapper = styled.div`
  height: 100%;
  /* TODO(Jenky): remove this wrapper once table styles are fixed or provides custom styling */
  table :is(th, tr) {
    /* name column */
    & td:first-of-type {
      max-width: 800px;
    }
  }
`;

const PerformanceAdsTable = (props: PerformanceAdsViewProps) => {
  const { ads, loaded, loading, onRequestNext } = props;

  return (
    <TableWrapper>
      <BaseTable
        onInfiniteScroll={loaded ? onRequestNext : undefined}
        columns={PERFORMANCE_ADS_COLUMN_DEFINITIONS}
        data={ads.data}
        summary={ads.summary}
        loading={loading}
        cellRender={cellRenderer}
      />
    </TableWrapper>
  );
};

export default PerformanceAdsTable;
