import { useState } from 'react';
import { Form, Modal, useToaster } from '@innovationdepartment/proxima-ui';

import { validateEmail, validateRequired } from 'utils';
import { useBrandStore } from 'stores';

import { useProximaSDK, useShowSpinner } from 'hooks';
import InviteUserModalView from './InviteUserModal.View';

interface IInviteUserModalProps {
  open: boolean;
  onClose: (email?: string) => void;
}

const InviteUserModal = (props: IInviteUserModalProps) => {
  const brandsApi = useProximaSDK('BrandsApi');

  const { open, onClose } = props;
  const { showToaster } = useToaster();
  const [loading, setLoading] = useState(open);

  useShowSpinner({ show: loading });

  const { brand } = useBrandStore();
  const initialValues = { email: '' };

  const { name: brandName } = brand;

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const handleSubmit = async ({ email }: typeof initialValues) => {
    setLoading(true);
    setSubmitDisabled(true);
    let response;
    try {
      response = await brandsApi.updateAccess({
        brandId: brand.brandId,
        updateAccessRequest: { email: email.trim(), roleId: 'admin' },
      });
    } catch (e) {
      setSubmitDisabled(false);
      setLoading(false);
      return;
    }

    setSubmitDisabled(false);
    setLoading(false);

    if (response.status === 204) {
      onClose(email);
      showToaster({
        message: (
          <>
            Invited&nbsp;<strong>{email}</strong>.
          </>
        ),
        variant: 'success',
      });
    }
  };

  const validate = (formValues: typeof initialValues) => {
    const errors: { [key: string]: string } = {};

    if (validateRequired(formValues.email.trim())) errors.email = 'Email is required';
    else if (validateEmail(formValues.email.trim())) errors.email = 'Email is invalid';

    return errors;
  };

  return (
    <Modal
      resizable
      noPadding={false}
      open={open}
      title={`Invite user to ${brandName}`}
      onClose={onClose}
      allowCloseOnClickOutside
    >
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validate}>
        <InviteUserModalView disabled={submitDisabled} onClose={onClose} />
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
