import { Modal } from '@innovationdepartment/proxima-ui';
import UnlockConfirmationView from './UnlockConfirmation.View';

export type UnlockConfirmationProps = {
  open: boolean;
  close: () => void;
  confirm: () => void;
  brandName: string;
};
const UnlockConfirmation = ({ open, confirm, close, brandName }: UnlockConfirmationProps) => (
  <Modal
    noPadding={false}
    open={open}
    title="Unlock brand access"
    onClose={close}
    allowCloseOnClickOutside
  >
    <UnlockConfirmationView confirm={confirm} close={close} brandName={brandName} />
  </Modal>
);

export default UnlockConfirmation;
