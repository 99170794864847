import { BrandWarning } from '@innovationdepartment/proxima-sdk-axios';
import { useProximaSDK } from 'hooks';
import { useEffect, useState } from 'react';
import { useBrandStore } from 'stores';
import { PaginationMapBrandWarnings } from 'types/brandOverview';

function useBrandWarnings() {
  const [loading, setLoading] = useState(false);
  /* brand profile state */

  const [brandWarnings, setBrandWarnings] = useState<BrandWarning[]>([]);
  const [pageNumberBrandWarnings, setPageNumberBrandWarnings] = useState<number>(1);
  const [paginationMapBrandWarnings, setPaginationMapBrandWarnings] =
    useState<PaginationMapBrandWarnings>({});

  const brandsApi = useProximaSDK('BrandsApi');
  const { brand } = useBrandStore();

  const fetchBrandWarnings = async (startToken?: string) => {
    if (!brand?.brandId) return;
    setLoading(true);
    try {
      const response = await brandsApi.getBrandWarnings({
        brandId: brand.brandId,
        nextToken: startToken,
      });
      setBrandWarnings(response.data.brandWarnings);
      const nextToken = response.data?.nextToken;
      let pageNumber = pageNumberBrandWarnings;
      const currNextToken = paginationMapBrandWarnings[pageNumberBrandWarnings]?.nextToken;

      // Determine the page number
      if (!startToken) pageNumber = 1;
      else if (currNextToken && startToken === currNextToken) pageNumber += 1;
      else pageNumber -= 1;

      setPageNumberBrandWarnings(pageNumber);

      // Set prev token
      let prevToken = paginationMapBrandWarnings[pageNumber - 1]?.nextToken;
      if (pageNumber === 2) prevToken = undefined;

      // Update pagination map so we know how to traverse
      if (!paginationMapBrandWarnings[pageNumber])
        setPaginationMapBrandWarnings({
          ...paginationMapBrandWarnings,
          [pageNumber]: { prevToken, nextToken },
        });
    } catch (err) {
      /*  */
    }
    setLoading(false);
  };

  /* fetch brand errors */
  useEffect(() => {
    fetchBrandWarnings();
  }, []);

  return {
    brandWarnings,
    pageNumberBrandWarnings,
    paginationMapBrandWarnings,
    fetchBrandWarnings,
    loading,
  };
}

export default useBrandWarnings;
