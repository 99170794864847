import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

const UserProfile = () => {
  const { user = null, getAccessTokenSilently } = useAuth0();
  const [tokenPayload, setTokenPayload] = useState<string | ReturnType<typeof jwtDecode> | null>(
    {},
  );
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const getUserMetadata = async () => {
      const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
      try {
        const accessToken = await getAccessTokenSilently({ audience });
        if (accessToken) setTokenPayload(jwtDecode(accessToken));
      } catch (e: any) {
        setError(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently]);

  return (
    <div>
      <h2>UserProfile:</h2>
      <p> user: {JSON.stringify(user)} </p>
      <p> tokenPayload: {JSON.stringify(tokenPayload)} </p>
      {!!error && <p> Error: {error} </p>}
    </div>
  );
};

export default UserProfile;
