import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StarYellow, StarOutline, colors } from '@innovationdepartment/proxima-ui';
import LoadingSpinner from 'ui/LoadingSpinner';
import { useProximaSDK } from 'hooks';
import { useAuth0 } from '@auth0/auth0-react';

type FavoriteActionProps = {
  brandId: string;
  refreshFavorites: () => void;
  isFavorite: boolean;
};

const FavoriteActionWrapper = styled.div`
  .emptystar:hover path {
    fill: ${colors.legacy.mistDarkest};
  }
`;

const BrandListFavoriteAction = ({
  brandId,
  refreshFavorites,
  isFavorite,
}: FavoriteActionProps) => {
  const { user } = useAuth0();
  const usersFavoriteApi = useProximaSDK('UsersApi');

  const [loading, setLoading] = useState(false);

  const favoriteApiCall = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const userId = user?.sub;

    if (!userId) return;

    setLoading(true);
    try {
      if (isFavorite) {
        await usersFavoriteApi.deleteFavorite({ userId, userFavoriteRequest: { brandId } });
      } else {
        await usersFavoriteApi.addFavorite({ userId, userFavoriteRequest: { brandId } });
      }
    } catch (error) {
      // TODO: Handle error
    }

    setLoading(false);
    // @ts-ignore
    await refreshFavorites(brandId);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <FavoriteActionWrapper onClick={favoriteApiCall}>
      {isFavorite ? <StarYellow /> : <StarOutline />}
    </FavoriteActionWrapper>
  );
};

export default BrandListFavoriteAction;
