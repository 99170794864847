import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import MagnifyingGlass from 'assets/MagnifyingGlass';

const NoSearchResultWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;
const MagnifyingGlassContainer = styled(MagnifyingGlass)`
  display: flex;
  padding: 0px;
  gap: 10px;
  width: 80px;
  height: 80px;
  background: ${colors.neutral10};
  border-radius: 200px;
`;

const MessageContainer = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 32px;
  color: ${colors.neutral100};
`;

const NoSearchResult = () => {
  const message = "Sorry, we couldn't find any results.";

  return (
    <NoSearchResultWrapper>
      <MagnifyingGlassContainer />
      <MessageContainer>{message}</MessageContainer>
    </NoSearchResultWrapper>
  );
};
export default NoSearchResult;
