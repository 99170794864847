import { Text } from '@innovationdepartment/proxima-ui';
import { ConnectionBadge } from './Layout';

const CreateSeedAudiencesDataSourceConnectionBadge = ({
  isConnected,
}: {
  isConnected: boolean;
}) => (
  <ConnectionBadge connected={isConnected}>
    <Text color="neutral80" variant="tag2Medium">
      {isConnected ? 'Connected' : 'Not connected'}
    </Text>
  </ConnectionBadge>
);

export default CreateSeedAudiencesDataSourceConnectionBadge;
