import { FormField, RadioButtonGroupOption } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'types/integrations';
import ContentContainer from '../ContentContainer';
import styled from '@emotion/styled';

const FormFieldWrappers = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  & > * {
    flex: 1;
  }
`;

const platformOptions: RadioButtonGroupOption[] = [
  {
    label: 'Facebook',
    value: IntegrationType.Facebook,
  },
];

const CreateSeedAudienceAdPlatformView = () => (
  <ContentContainer title="Ad platform">
    <FormFieldWrappers>
      <FormField type="radio" variant="boxed" name="integrationType" options={platformOptions} />
      {/* helps to fix layout */}
      <div />
    </FormFieldWrappers>
  </ContentContainer>
);

export default CreateSeedAudienceAdPlatformView;
