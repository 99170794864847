import styled from '@emotion/styled';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const GradientContainer = styled.div`
  align-items: center;
  background: linear-gradient(270deg, #66e0d1 0%, #a8c2fe 100%);
  border-radius: 64px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 128px;
  justify-content: center;
  margin: 0 auto 16px auto;
  padding: 8px;
  width: 128px;
`;

const LockIcon = () => (
  <GradientContainer>
    <LockOutlinedIcon style={{ fontSize: '72px', margin: '0 auto' }} />
  </GradientContainer>
);

export default LockIcon;
