import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';
import { Link } from 'react-router-dom';

const StyledLink = styled(Text)`
  color: ${colors.blue70};
  transition: color 0.1s ease-in-out;
  display: inline;

  &:hover {
    color: ${colors.blue80} !important;
  }
`;

const StyledAnchor = styled.a`
  &,
  &:is(:visited, :active) {
    color: ${colors.blue80};
  }
`;

const ProximaLink: React.FC<{ to: string | `/${string}` }> = ({ children, to }) => {
  const isExternal = !to.startsWith('/');

  let content = children;

  if (typeof children === 'string') {
    content = <StyledLink variant="body2">{children}</StyledLink>;
  }

  if (isExternal) {
    return (
      <StyledAnchor href={to} target="_blank" rel="noreferrer">
        {content}
      </StyledAnchor>
    );
  }

  return <Link to={to}>{content}</Link>;
};

export default ProximaLink;
