import pick from 'lodash/pick';
import { useFormState } from 'react-final-form';
import { GalaxySchema, SeedAudienceSchema } from 'types/audiences';
import LookalikeSizePreviewView from './LookalikeSizePreview.View';

const GALAXY_PROPS = ['name', 'galaxyId', 'createdAt', 'updatedAt', 'flavorId'] as const;
const FLAVOR_PROPS = ['categoryName', 'categoryId', 'flavorId'] as const;

const LookalikeSizePreview = (props: { seedAudience?: SeedAudienceSchema }) => {
  const { seedAudience } = props;
  const formState = useFormState();

  const { lookalikeSize: size } = formState.values;

  if (!seedAudience) return null;

  const galaxyProps = pick(seedAudience, GALAXY_PROPS);
  const flavorProps = pick(seedAudience, FLAVOR_PROPS);
  const galaxy = {
    isInternal: false,
    allowMultipleAudiences: false,
    ...galaxyProps,
    id: galaxyProps.galaxyId,
    flavor: {
      ...flavorProps,
      name: galaxyProps.name,
      id: galaxyProps.flavorId,

      /* just to shut ts up */
      createdAt: '',
      updatedAt: '',
    },
  } satisfies GalaxySchema;

  return <LookalikeSizePreviewView galaxy={galaxy} size={size} />;
};

export default LookalikeSizePreview;
