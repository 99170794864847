import styled from '@emotion/styled';
import { useFormState } from 'react-final-form';
import { Button } from '@innovationdepartment/proxima-ui';
import { CreateNewLookalikeModalViewProps } from 'types/audiences';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import LookalikeSize from 'ui/Modals/CreateSeedAudiencesModal/Layout/CreateSeedAudiencesLookalikeSize';
import ButtonGroup from 'ui/ButtonGroup';
import { LookalikeSizePreview, LookalikeSizeSubmitting, AdPlatformSelection } from './Layout';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 640px;
`;

const CreateLookalikeAudienceModalView = (
  props: Omit<CreateNewLookalikeModalViewProps, 'open'>,
) => {
  const { seedAudience, loading, isSubmitting, sizes, onClose } = props;
  const formState = useFormState();

  const { valid } = formState;
  const { integrationType } = formState.values;

  const sizesForPlatform =
    sizes.find((size) => size.integrationType === integrationType)?.sizes ?? [];

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <ButtonGroup>
          <Button label={isSubmitting ? 'Close' : 'Cancel'} variant="outlined" onClick={onClose} />
          {!isSubmitting && <Button submit disabled={!valid} label="Generate & send to platform" />}
        </ButtonGroup>
      }
    >
      <ContentWrapper>
        {isSubmitting ? (
          <LookalikeSizeSubmitting />
        ) : (
          <>
            <AdPlatformSelection />
            <LookalikeSize loading={loading} sizes={sizesForPlatform} />
          </>
        )}
        <LookalikeSizePreview seedAudience={seedAudience} />
      </ContentWrapper>
    </ModalBaseChildrenWrapper>
  );
};

export default CreateLookalikeAudienceModalView;
