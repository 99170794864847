import { DatePicker } from '@innovationdepartment/proxima-ui';
import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';

type FacebookTableSearchFilterProps = {
  dateRange: PropsOf<typeof DatePicker>['dateRange'];
  onDateRangeChange: PropsOf<typeof DatePicker>['onDateRangeSelect'];
};

const SearchFilterWrapper = styled.div`
  display: flex;
  margin: 16px 0 8px;
  justify-content: flex-end;
`;

const FacebookTableSearchFilterView: React.FC<FacebookTableSearchFilterProps> = (props) => {
  const { dateRange, onDateRangeChange } = props;
  return (
    <SearchFilterWrapper>
      <DatePicker disableFutureDates dateRange={dateRange} onDateRangeSelect={onDateRangeChange} />
    </SearchFilterWrapper>
  );
};

export default FacebookTableSearchFilterView;
