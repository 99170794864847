import { useState } from 'react';
import { TableProps } from '@innovationdepartment/proxima-ui';
import {
  AudienceTableCellRendererProps,
  LookalikeAudienceSchema,
  LookalikeAudienceTableColumnHeader,
  LookalikeAudienceTableRowExtraFunctions,
  LookalikeAudienceTableRowProps,
  LookalikeAudiencesTableProps,
} from 'types/audiences';
import LookalikeAudiencesTableView from './LookalikeAudiencesTable.View';
import lookalikeAudiencesRowRenderer from './lookalikeAudiencesRowRenderer';

const LookalikeAudiencesTable = (props: LookalikeAudiencesTableProps) => {
  const [hoverId, setHoverId] = useState<string | null>(null);
  const {
    lookalikeAudiences,
    onShowRenameLookalikeAudienceModal,
    onShowDeleteLookalikeAudienceModal,
  } = props;

  type RowRendererProps = AudienceTableCellRendererProps<LookalikeAudienceTableRowProps> &
    LookalikeAudienceTableRowExtraFunctions;

  const config: TableProps<LookalikeAudienceSchema>['config'] = {
    columns: {
      order: [
        'lookalikeAudience',
        'platform',
        'displayStatus',
        'dateCreated',
        'actions',
      ] satisfies LookalikeAudienceTableColumnHeader[],
      lookalikeAudience: {
        label: 'Lookalike Audience',
      },
      platform: {
        label: 'Platform',
        width: 160,
      },
      displayStatus: {
        label: 'Status',
        width: 160,
      },
      dateCreated: {
        label: 'Date Created',
        width: 140,
        align: 'right',
      },
      actions: {
        hideLabel: true,
        width: 50,
      },
    },
    cellRenderer(row) {
      return lookalikeAudiencesRowRenderer({
        ...row,
        onShowRenameLookalikeAudienceModal,
        onShowDeleteLookalikeAudienceModal,
      } as RowRendererProps);
    },
    onRowMouseEnter: ({ id }) => {
      setHoverId(id);
    },
    onRowMouseLeave: () => {
      setHoverId(null);
    },
    formatter(row: LookalikeAudienceSchema) {
      return {
        ...row,
        isHovered: row.id === hoverId,
      };
    },
  };

  // TODO(Jenky): ask product about this
  const lookalikes = lookalikeAudiences.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    // (a, b) => a.size - b.size
  );

  return <LookalikeAudiencesTableView lookalikeAudiences={lookalikes} config={config} />;
};

export default LookalikeAudiencesTable;
