import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ProximaTheme, colors, Spinner, ToasterProvider } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';

import Router from 'Router';
import NavBar from 'components/Navbar';
import { UserStoreLoader } from 'components/Loaders';
import ErrorBoundaries from 'components/ErrorBoundaries';
import SpinnerWrapper from 'ui/SpinnerWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'hooks';
import FeatureFlagProvider from 'ui/FeatureFlagProvider';

const AppWrapper = styled('div')`
  background-color: ${colors.bigBangBlack};
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionStorage = useSessionStorage();

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      sessionStorage.setItem('redirectTo', location.pathname, 1000 * 60 * 30); // 30 mins
      loginWithRedirect({ internal: true });
      return;
    }

    /* if user is logged out when pasting an url and is prompted to log in, this takes them back to pasted url  */
    const redirectTo = sessionStorage.getItem('redirectTo', true);
    if (redirectTo) navigate(redirectTo, { replace: true });
  }, [isAuthenticated, isLoading]);

  if (isLoading) return <Spinner loading />;

  return (
    <FeatureFlagProvider>
      <ToasterProvider>
        <ErrorBoundaries>
          <ProximaTheme>
            <SpinnerWrapper>
              <AppWrapper>
                <UserStoreLoader>
                  <NavBar />
                  <Router />
                </UserStoreLoader>
              </AppWrapper>
            </SpinnerWrapper>
          </ProximaTheme>
        </ErrorBoundaries>
      </ToasterProvider>
    </FeatureFlagProvider>
  );
};

export default App;
