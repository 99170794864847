import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

const AdAccountIdWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

type FacebookTableAdAccountIdProps = {
  adAccountId: React.ReactNode;
};

const FacebookTableAdAccountIdView = (props: FacebookTableAdAccountIdProps) => {
  const { adAccountId } = props;

  return (
    <AdAccountIdWrapper>
      <Text color="neutral60" variant="body3">
        Ad account ID:
      </Text>
      <Text color="neutral80" variant="body2">
        {adAccountId}
      </Text>
    </AdAccountIdWrapper>
  );
};

export default FacebookTableAdAccountIdView;
