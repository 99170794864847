import styled from '@emotion/styled';
import { Button, Text } from '@innovationdepartment/proxima-ui';
import { ReactElement } from 'react';

const Container = styled.header`
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

const TitleTextGrid = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface IHeaderProps {
  title: string;
  isTermina?: boolean;
  titleAppendText?: React.ReactNode;
  button?: {
    label: string;
    onClick: (args: any) => any;
    icon?: ReactElement<any, any>;
  };
}

const Header: React.FC<IHeaderProps> = ({
  title,
  isTermina = true,
  titleAppendText,
  button,
  children,
}) => (
  <Container>
    <TitleTextGrid>
      <Text isTermina={isTermina} color="neutral100" variant="h5">
        {title}
      </Text>
      {titleAppendText}
    </TitleTextGrid>
    {children}
    {button && (
      <Button label={button.label} onClick={button.onClick} size="small" startIcon={button.icon} />
    )}
  </Container>
);

Header.defaultProps = {
  button: undefined,
  titleAppendText: undefined,
};

export default Header;
