import { MoreMenu, IMenuItem, useToaster } from '@innovationdepartment/proxima-ui';
import { useBrandStore } from 'stores';

import { useProximaSDK } from 'hooks';

import { UserActionProps } from 'types/users';

type UserMenuProps = { menu: UserActionProps };

const UsersActionMenu = ({ menu }: UserMenuProps) => {
  const brandsApi = useProximaSDK('BrandsApi');
  const { showToaster } = useToaster();
  const { onClose, userId, disabled, fetchBrandUsers, setLoading, showActions } = menu;

  const { brand } = useBrandStore();

  const removeItem: IMenuItem = {
    disabled,
    label: 'Remove',
    value: 'remove',
    textColor: 'red60',
  };

  const items = [removeItem];

  const removeUser = async () => {
    setLoading(true);
    let response;

    try {
      response = await brandsApi.deleteUserFromBrand({ brandId: brand.brandId, userId });
    } catch (e) {
      setLoading(false);
      return;
    }

    setLoading(false);

    if (response.status === 204) {
      await fetchBrandUsers();
      showToaster({ message: 'User removed successfully.', variant: 'success' });
    }
  };

  const handleClick = (value: string | number) => {
    switch (value) {
      case 'remove':
        removeUser();
        break;
      default:
        break;
    }
  };

  return <MoreMenu hide={!showActions} onClose={onClose} items={items} onClick={handleClick} />;
};

export default UsersActionMenu;
