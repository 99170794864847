import { Button, Table, Text } from '@innovationdepartment/proxima-ui';
import { BrandWarning } from '@innovationdepartment/proxima-sdk-axios';
import ButtonGroup from 'ui/ButtonGroup';
import LoadingSpinner from 'ui/LoadingSpinner';
import SectionLayout from 'ui/SectionLayout';
import BrandOverviewHeader from 'components/BrandOverview/Layout/BrandOverviewHeader';
import { Dictionary } from 'lodash';
import { Integration } from 'types/integrations';

type BrandWarningsModalViewProps = {
  brandId: string | undefined;
  adAccountId: string | undefined;
  integrationsWithStatus: Dictionary<Integration[]>;
  brandWarnings: BrandWarning[];
  tableConfig: any;
  hasNext: boolean;
  hasPrev: boolean;
  handlePagination: (action: 'prev' | 'next') => void;
  loading: boolean;
};

const BrandWarningsView = ({
  brandWarnings,
  tableConfig,
  hasNext,
  hasPrev,
  handlePagination,
  loading,
  brandId,
  adAccountId,
  integrationsWithStatus,
}: BrandWarningsModalViewProps) => (
  <SectionLayout
    title="Warning log"
    titleAppendText={
      <BrandOverviewHeader
        brandId={brandId}
        metaAdAccountId={adAccountId}
        integrations={integrationsWithStatus}
      />
    }
  >
    <Text variant="h4"> All warnings </Text>
    <Table variant="normal" data={brandWarnings} config={tableConfig} />
    <ButtonGroup>
      {hasPrev && <Button label="Previous" onClick={() => handlePagination('prev')} />}
      {hasNext && <Button label="Next" onClick={() => handlePagination('next')} />}
    </ButtonGroup>
    {loading && <LoadingSpinner size={100} />}
  </SectionLayout>
);

export default BrandWarningsView;
