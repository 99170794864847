import React from 'react';
import styled from '@emotion/styled';
import { BrandFilterViewProps } from '../types';
import { Checkbox } from '@innovationdepartment/proxima-ui';

import BrandFilterInfiniteScroll from './BrandFilter.InfiniteScroll';
import LoadingBar from 'ui/LoadingBar';

const BrandsFilterWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const BrandsFilterItemsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 8px;
  overflow-y: auto;
`;

const INFINITE_SCROLL_THRESHOLD = 4;

const BrandFilterFilter = (props: BrandFilterViewProps) => {
  const { selectedBrands, listViewRef, onBrandSelect, hasNext, brands, loading } = props;

  return (
    <BrandsFilterWrapper ref={listViewRef} className="brand-filter-filter">
      <LoadingBar loading={loading} />
      <BrandsFilterItemsWrapper>
        {brands.map((brand, index) => {
          const shouldInfiniteScroll =
            hasNext && index === brands.length - INFINITE_SCROLL_THRESHOLD;
          return (
            <React.Fragment key={brand.brandId!}>
              <Checkbox
                checked={selectedBrands.has(brand.brandId!)}
                label={brand.name}
                onChange={() => onBrandSelect({ brandId: brand.brandId!, name: brand.name })}
              />
              {shouldInfiniteScroll && <BrandFilterInfiniteScroll {...props} />}
            </React.Fragment>
          );
        })}
      </BrandsFilterItemsWrapper>
    </BrandsFilterWrapper>
  );
};

export default BrandFilterFilter;
