import { Edit, Text } from '@innovationdepartment/proxima-ui';
import ContentSection, { ContentSectionTitle, ContentSectionWrapper } from 'ui/ContentSection';

import { BrandOverviewProps } from 'types/brandOverview';
import { Link } from 'react-router-dom';

import PageContentWrapper from '../PageContentWrapper.View';
import BrandInfo from './BrandInfo/BrandInfo.View';
import BrandProfile from './BrandProfile';
import AudienceUsage from './AudienceUsage';
import BrandMetrics from './BrandMetrics';
import BrandPlan from './BrandOverviewPlan';
import BrandWarningSection from '../../../BrandWarnings/BrandWarningSection.Container';
import styled from '@emotion/styled';
import DataIngestionView from './DataIngestion';

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const BrandSectionView: React.FC<
  Omit<
    BrandOverviewProps,
    'fetchBrandWarnings' | 'pageNumberBrandWarnings' | 'paginationMapBrandWarnings'
  >
> = (props) => {
  const { onEditBrandCategory, onEditDataIngestionPermissions, brandWarnings, brand } = props;

  return (
    <PageContentWrapper title="Brand">
      <ContentSectionWrapper>
        {/* left column */}
        <ContentSectionWrapper column maxWidth={500}>
          <ContentSection>
            <ContentSectionTitle title="Brand info" />
            <BrandInfo {...props} />
            <ContentSectionTitle
              title="Brand profile"
              buttonProps={{
                label: <Text color="neutral100">Edit</Text>,
                startIcon: <Edit />,
                onClick: onEditBrandCategory,
              }}
            />
            <BrandProfile {...props} />
            <ContentSectionTitle title="Plan" />
            <BrandPlan {...props} />
          </ContentSection>
          <ContentSection>
            <ContentSectionTitle
              title="Data ingestion permissions"
              buttonProps={{
                label: <Text color="neutral100">Edit</Text>,
                startIcon: <Edit />,
                onClick: onEditDataIngestionPermissions,
              }}
            />
            <DataIngestionView {...props} />
          </ContentSection>
        </ContentSectionWrapper>
        {/* right column */}
        <ContentSectionWrapper column>
          <ContentSection>
            <ContentSectionTitle title="Brand metrics" />
            <BrandMetrics {...props} />
          </ContentSection>
          {brandWarnings && brandWarnings.length > 0 && (
            <ContentSection>
              <UnstyledLink to={`/brand/${brand.brandId}/warnings`}>
                <ContentSectionTitle title="Warnings" />
                <BrandWarningSection brandWarnings={brandWarnings} />
              </UnstyledLink>
            </ContentSection>
          )}
          <ContentSection>
            <ContentSectionTitle title="Audience usage" />
            <AudienceUsage {...props} />
          </ContentSection>
        </ContentSectionWrapper>
      </ContentSectionWrapper>
    </PageContentWrapper>
  );
};

export default BrandSectionView;
