import styled from '@emotion/styled';
import {
  Button,
  Divider,
  Form,
  FormField,
  RadioButtonGroupOption,
  SpaceDivider,
  Text,
} from '@innovationdepartment/proxima-ui';
import { BrandLockAudienceOption } from 'types/audiences';

type LockAccessViewProps = {
  audienceOption: BrandLockAudienceOption;
  brandName: string;
  createdAt?: string;
  isAdAccountActive: boolean;
  onClose: () => void;
  handleContinueClick: (audienceOption: BrandLockAudienceOption) => void;
  onSetAudienceOption: (audienceOption: BrandLockAudienceOption) => void;
};

const ButtonGroup = styled.div`
  display: inline-flex;
  justify-content: end;
  width: 100%;
  button:first-of-type {
    margin-right: 1rem;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoWrapper = styled(FlexColumn)`
  height: 64px;
  width: 196px;
`;

const InfoSectionWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;

const WarningText = styled(Text)`
  padding-left: 30px;
`;

const LockAccessView = ({
  audienceOption,
  brandName,
  createdAt,
  isAdAccountActive,
  onClose,
  handleContinueClick,
  onSetAudienceOption,
}: LockAccessViewProps) => {
  const audienceOptions: RadioButtonGroupOption[] = [
    {
      label: 'Keep shared audiences active',
      value: BrandLockAudienceOption.KeepAudiences,
    },
    {
      label: 'Remove active audiences',
      value: BrandLockAudienceOption.RemoveAudiences,
      disabled: !isAdAccountActive,
    },
  ];

  const onChange = (formData: any) => {
    onSetAudienceOption(formData.values.audienceOption);
  };

  return (
    <>
      <SpaceDivider y={2} />
      <InfoSectionWrapper>
        <InfoWrapper>
          <Text color="neutral80" variant="body2Semibold">
            Brand name
          </Text>
          <Text color="neutral100" variant="body2Semibold" ellipsis>
            {brandName}
          </Text>
        </InfoWrapper>
        <InfoWrapper>
          <Text color="neutral80" variant="body2Semibold">
            Date joined
          </Text>
          <Text color="neutral100" variant="body2Semibold">
            {createdAt}
          </Text>
        </InfoWrapper>
      </InfoSectionWrapper>
      <Divider margin={24} />
      <Text color="neutral80" variant="body2Semibold">
        Active audiences
      </Text>
      <SpaceDivider y={2} />
      <Form
        initialValues={audienceOption}
        onChange={onChange}
        onSubmit={(value) => handleContinueClick(value)}
      >
        <FlexColumn>
          <FormField type="radio" name="audienceOption" options={audienceOptions} />
          {audienceOption === BrandLockAudienceOption.RemoveAudiences && (
            <WarningText color="red60" variant="body2">
              This brand will not be able to recreate audiences
            </WarningText>
          )}
        </FlexColumn>
        <SpaceDivider y={4} />
        <ButtonGroup>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            label="Cancel"
            onClick={onClose}
          />
          <Button size="small" label="Continue" disabled={!audienceOption} submit />
        </ButtonGroup>
      </Form>
    </>
  );
};

export default LockAccessView;
