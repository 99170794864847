import { Table, TableData } from '@innovationdepartment/proxima-ui';
import { BrandListProps } from 'types/brandList';
import BrandListResultsWrapper from './BrandList.ResultsWrapper';

const BrandListFavorites = <T extends TableData>(props: BrandListProps<T>) => {
  const { favorites, config } = props;

  return (
    <BrandListResultsWrapper>
      <Table data={favorites} config={config as any} />
    </BrandListResultsWrapper>
  );
};

export default BrandListFavorites;
