import groupBy from 'lodash/groupBy';
import { colors } from '@innovationdepartment/proxima-ui';
import { LookalikeAudienceStatus, SeedAudienceSchema } from 'types/audiences';

const STATUS_ORDER = [
  LookalikeAudienceStatus.Active,
  LookalikeAudienceStatus.Processing,
  LookalikeAudienceStatus.Deleted,
  LookalikeAudienceStatus.Delivered,
  LookalikeAudienceStatus.Inactive,
  LookalikeAudienceStatus.Error,
];

const SLICE_LIMIT = 3;

// TODO(Jenky): discuss with product/Chad about these possible statuses and which one to prioritize
const formatStatus = ({
  displayStatus: status,
}: SeedAudienceSchema['lookalikeAudiences'][number]): LookalikeAudienceStatus => {
  if (
    status === LookalikeAudienceStatus.Generating ||
    status === LookalikeAudienceStatus.Seeding ||
    status === LookalikeAudienceStatus.New
  )
    return LookalikeAudienceStatus.Processing;

  return status;
};

const groupByStatus = (lookalikes: SeedAudienceSchema['lookalikeAudiences']) =>
  groupBy(lookalikes, (lookalike) => formatStatus(lookalike));

const sortStatus = (lookalikesStatuses: LookalikeAudienceStatus[]) =>
  lookalikesStatuses.slice().sort((a, b) => STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b));

const statusColor = {
  [LookalikeAudienceStatus.Active]: colors.green10,
  [LookalikeAudienceStatus.Error]: colors.red10,
  [LookalikeAudienceStatus.Processing]: colors.yellow10,
  default: colors.neutral10,
};

export default {
  sort: sortStatus,
  group: groupByStatus,
  color: statusColor,
  limit: SLICE_LIMIT,
  format: formatStatus,
};
