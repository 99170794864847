import { Form } from '@innovationdepartment/proxima-ui';
import { DeleteAudienceModalProps } from 'types/audiences';
import ModalBase from 'ui/ModalBase';
import DeleteLookalikeAudienceModalView from './DeleteLookalikeAudienceModal.View';

const DeleteLookalikeAudienceModal = (props: DeleteAudienceModalProps) => {
  const { open, lookalikeAudience, onClose, onDeleteAudience } = props;

  const initialValues = {
    lookalikeAudienceId: lookalikeAudience?.id,
  };

  const handleSubmit = async (formData: typeof initialValues) => {
    const { lookalikeAudienceId } = formData;

    if (!lookalikeAudienceId) {
      return;
    }

    await onDeleteAudience();
  };

  return (
    <ModalBase open={open} title="Delete lookalike audience" onClose={onClose}>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <DeleteLookalikeAudienceModalView onClose={onClose} />
      </Form>
    </ModalBase>
  );
};

export default DeleteLookalikeAudienceModal;
