import { create } from 'zustand';
import { IntegrationStatus, IntegrationStore } from 'types/stores/integration-store';
import { Integration } from 'types/integrations';

const emptyState: Integration[] = [];

const useIntegrationsStore = create<IntegrationStore>((set, get) => ({
  integrations: emptyState,
  clear: () => {
    set({ integrations: [] });
  },
  deleteIntegration: (integrationId: string) => {
    set((prevState) => ({
      integrations: prevState.integrations.filter(
        (integration) => integration.integrationId !== integrationId,
      ),
    }));
  },
  addIntegration: (integration: Integration | Integration[]) => {
    set((prevState) => ({
      integrations: prevState.integrations.concat(
        ...(!Array.isArray(integration) ? [integration] : integration),
      ),
    }));
  },
  getAllIntegrations({ type, active } = {}) {
    const { integrations } = get();

    return (
      integrations
        .filter(
          (integration) =>
            (active ? integration.status === IntegrationStatus.Active : true) &&
            (type !== undefined ? integration.type === type : true),
        )
        /* sort by last updated */
        .sort((a, b) => new Date(b.lastUpdateAt).getTime() - new Date(a.lastUpdateAt).getTime())
    );
  },
}));

export default useIntegrationsStore;
