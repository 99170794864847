import styled from '@emotion/styled';
import { Button, Text, FormField } from '@innovationdepartment/proxima-ui';
import { useFormState } from 'react-final-form';
import ButtonGroup from 'ui/ButtonGroup';
import FormLabeledGroup from 'ui/FormLabeledGroup';

const InputGrid = styled.div`
  display: grid;
  grid-row-gap: 20px;
  padding: 24px 0 48px 0;
`;

interface IInviteUserModalViewProps {
  disabled: boolean;
  onClose: () => void;
}

const InviteUserModalWrapper = styled.div`
  width: 488px;
`;

const InviteUserModalView = (props: IInviteUserModalViewProps) => {
  const { disabled, onClose } = props;
  const { valid } = useFormState();

  const isDisabled = !valid || disabled;

  return (
    <InviteUserModalWrapper>
      <InputGrid>
        <FormLabeledGroup>
          <Text variant="body2Semibold" color="neutral80">
            User&apos;s email address
          </Text>
          <FormField
            disabled={disabled}
            type="input"
            name="email"
            placeholder="email@address.com"
            fullWidth
          />
        </FormLabeledGroup>
      </InputGrid>

      <ButtonGroup>
        <Button size="small" label="Cancel" onClick={onClose} variant="outlined" />
        <Button size="small" label="Send invitation" submit disabled={isDisabled} />
      </ButtonGroup>
    </InviteUserModalWrapper>
  );
};

export default InviteUserModalView;
