import SectionLayout from 'ui/SectionLayout';
import BrandOverviewHeader from './Layout/BrandOverviewHeader';
import BrandSection from './Layout/BrandSection';
import useBrandOverview from './useBrandOverview';
import EditBrandProfileModal from 'ui/Modals/EditBrandProfileModal';
import { useState } from 'react';
import Users from 'components/Users';
import { useAdAccountStore, useIntegrationsStore } from 'stores';
import { useParams } from 'react-router-dom';
import { BrandListIntegrationStatusProps } from 'types/integrations';
import { useBrandWarnings } from 'hooks';
import EditDataIngestionPermissionsModal from 'ui/Modals/EditDataIngestionPermissionsModal';

const BrandOverview = () => {
  const {
    brand,
    brandSubscription,
    flavorCategory,
    flavorCategoryOptions,
    isFileUploadAllowed,
    onUpdateBrandCategory,
    onUpsertBrandCustomerSettings,
    apiLoading,
    loading,
    brandCustomerSettingsLoading,
    dateRange,
    audienceSpend,
    audienceSpendSummary,
    audienceStats,
    last30DaysOfAllBrandSpend,
    onAudienceDateRangeSelect,
  } = useBrandOverview();
  const { brandWarnings } = useBrandWarnings();
  const { getAllIntegrations } = useIntegrationsStore();
  const { brandId } = useParams();
  const { metaAdAccount, tiktokAdAccount } = useAdAccountStore();
  const activeIntegrations = getAllIntegrations();

  const [showEditCategoryModal, setShowCategoryModal] = useState(false);
  const [showDataIngestionPermissionsModal, setShowDataIngestionPermissionsModal] = useState(false);

  /*
    example: integrationsWithStatus = { facebook: [integration1, integration2], shopify: [integration3] }
  */
  const integrationsWithStatus = activeIntegrations.reduce(
    (acc, integration) => {
      if (!acc[integration.type]) acc[integration.type] = [];
      acc[integration.type].push(integration);
      return acc;
    },
    {} as BrandListIntegrationStatusProps['integrations'],
  );

  return (
    <SectionLayout
      title="Overview"
      titleAppendText={
        <BrandOverviewHeader
          brandId={brandId}
          metaAdAccountId={metaAdAccount?.accountId}
          tiktokAdAccountId={tiktokAdAccount?.accountId}
          integrations={integrationsWithStatus}
        />
      }
    >
      <BrandSection
        loading={loading}
        apiLoading={apiLoading}
        brand={brand}
        brandWarnings={brandWarnings}
        brandCustomerSettingsLoading={brandCustomerSettingsLoading}
        isFileUploadAllowed={isFileUploadAllowed}
        last30DaysOfAllBrandSpend={last30DaysOfAllBrandSpend}
        brandSubscription={brandSubscription}
        flavorCategory={flavorCategory}
        flavorCategoryOptions={flavorCategoryOptions}
        dateRange={dateRange}
        audienceSpend={audienceSpend}
        audienceSpendSummary={audienceSpendSummary}
        audienceStats={audienceStats}
        onAudienceDateRangeSelect={onAudienceDateRangeSelect}
        onEditBrandCategory={() => setShowCategoryModal(true)}
        onEditDataIngestionPermissions={() => setShowDataIngestionPermissionsModal(true)}
      />

      <Users />

      {/* ==== modals ==== */}
      <EditBrandProfileModal
        open={showEditCategoryModal}
        currentFlavorCategory={flavorCategory!}
        flavorCategoryOptions={flavorCategoryOptions}
        onClose={() => setShowCategoryModal(false)}
        onUpdateBrandCategory={onUpdateBrandCategory}
      />
      <EditDataIngestionPermissionsModal
        isFileUploadAllowed={isFileUploadAllowed}
        open={showDataIngestionPermissionsModal}
        onClose={() => setShowDataIngestionPermissionsModal(false)}
        onUpsertBrandCustomerSettings={onUpsertBrandCustomerSettings}
      />
    </SectionLayout>
  );
};

export default BrandOverview;
