import styled from '@emotion/styled';
import { Text, FormField, SpaceDivider } from '@innovationdepartment/proxima-ui';

const UpdateDayFrequencyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UpdateDayFrequencyFormField = styled(FormField)`
  min-width: 120px;
`;

// Generate a list of numbers to populate the day frequency selector.
const dayNumbers = Array.from({ length: 30 }, (_, index) => index + 1);

// Default audience update day frequency setting.
const defaultFrequency = 7;

const dayFrequencies = dayNumbers.map((index) => ({
  label: index !== defaultFrequency ? index.toString() : `${index} (default)`,
  value: index.toString(),
}));

const AudienceUpdateFrequencyView = () => (
  <>
    <Text variant="body2Semibold" color="neutral80">
      Frequency
    </Text>
    <Text variant="body2" color="neutral60">
      How often your audience will be updated
    </Text>
    <SpaceDivider />
    <UpdateDayFrequencyWrapper>
      <Text variant="body2" color="neutral100">
        Every
      </Text>
      <UpdateDayFrequencyFormField
        name="updateDayFrequency"
        type="select"
        options={dayFrequencies}
      />
      <Text variant="body2" color="neutral100">
        days
      </Text>
    </UpdateDayFrequencyWrapper>
  </>
);

export default AudienceUpdateFrequencyView;
