import PageLayoutSectionView from '../../PageSectionLayout.View';
import LoadingSpinner from 'ui/LoadingSpinner';
import { LabelStatus } from '@innovationdepartment/proxima-ui';
import { DataIngestionViewProps } from 'types/brandCustomer';

const DataIngestionView = ({
  isFileUploadAllowed,
  brandCustomerSettingsLoading,
}: DataIngestionViewProps) => (
  <PageLayoutSectionView
    sections={[
      {
        label: 'Customer lists',
        content: brandCustomerSettingsLoading ? (
          <LoadingSpinner />
        ) : (
          <LabelStatus
            label={isFileUploadAllowed ? 'Enabled' : 'Disabled'}
            variant={isFileUploadAllowed ? 'success' : 'default'}
          />
        ),
      },
    ]}
  />
);

export default DataIngestionView;
