import styled from '@emotion/styled';

const CreateSeedAudiencesModalSection = styled.div<{ flex?: number }>`
  /* TODO(Jenky): Figure out why this needs to be here but not in Proxima??? */
  width: calc(100vw - 96px);
  gap: 16px;
  display: flex;
  flex-flow: column;
  flex-basis: ${({ flex = 1 }) => flex * 350}px; /* bad math */
  flex-grow: ${({ flex = 1 }) => flex};
  flex-shrink: 0;
`;

export default CreateSeedAudiencesModalSection;
