import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';

const LogoutRoute = () => {
  const { logout } = useAuth0();
  const { clearBrandStore } = useBrandStore();

  useEffect(() => {
    logout({ returnTo: window.location.origin });
    clearBrandStore();
  });

  return null;
};

export default LogoutRoute;
