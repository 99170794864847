import { useAuth0 } from '@auth0/auth0-react';
import { useAccessToken, useShowSpinner } from 'hooks';
import jwtDecode from 'jwt-decode';
import NoAccessBox from 'ui/NoAccessBox';
import { useEffect, useRef, useState } from 'react';

const ENTITLEMENTS_NAMESPACE = 'https://dojomojo.com/jwt/entitlements';

const UserStoreLoader: React.FC = (props) => {
  const { children } = props;
  const { user, isLoading } = useAuth0();
  const { getAccessToken } = useAccessToken();
  const [accessToken, setAccessToken] = useState('');
  const loaded = useRef(false);
  let hasEntitlements = false;

  useShowSpinner({ show: isLoading });

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessToken();
      loaded.current = true;
      setAccessToken(token.accessToken);
    };

    getToken();
  }, []);

  if (accessToken) {
    const decodedToken = jwtDecode(accessToken) as { [ENTITLEMENTS_NAMESPACE]: {} };

    hasEntitlements = Object.keys(decodedToken[ENTITLEMENTS_NAMESPACE] ?? {}).length !== 0;
  }

  if (!user || isLoading || !loaded.current) return null;

  if (!hasEntitlements) return <NoAccessBox />;

  return <>{children}</>;
};

export default UserStoreLoader;
