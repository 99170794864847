import styled from '@emotion/styled';
import { SearchBar, Tabs, colors } from '@innovationdepartment/proxima-ui';
import { BrandListProps, BrandTab } from 'types/brandList';
import HideCard from 'ui/HideCard';
import BrandListHeader from './BrandList.Header';
import BrandListFavorites from './BrandList.Favorites';
import BrandListBrands from './BrandList.Brands';
import Filters from './Filters/Filters.Container';

const BrandListWrapper = styled.div`
  background-color: ${colors.bigBangBlack};
  height: 100vh;
`;

const BrandListTablesContainer = styled.div`
  height: calc(100% - 68px);
  border-radius: 16px 16px 0px 0px;
  background-color: ${colors.neutral5};
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableWrapper = styled.div`
  position: relative;
  height: calc(100% - 116px);
`;

const SearchAndFilterWrapper = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline-flex' : 'none')};
  border-radius: 8px;
  width: 100%;
  vertical-align: middle;
  align-items: center;
  gap: 16px;
`;

const SearchBarWrapper = styled.div`
  max-width: 400px;
  width: 300px;
  background-color: white;
`;

const BrandListView = <T,>(props: BrandListProps<T>) => {
  const {
    tabs,
    selectedTab,
    onTabChange,
    onFilterBrands,
    onFilterPlans,
    onFilterSpend,
    clearFilters,
    onPerformanceAdsClick,
    filterStateCounter,
    hasSelectedFilters,
    isPerformanceAdsEnabled,
  } = props;

  const isBrandsTab = selectedTab === 'all';
  const isFavoritesTab = selectedTab === 'favorites';

  return (
    <BrandListWrapper>
      <BrandListHeader
        isPerformanceLibraryEnabled={isPerformanceAdsEnabled}
        onPerformanceAdsClick={onPerformanceAdsClick}
      />
      <BrandListTablesContainer>
        <Tabs tabs={tabs} onChange={(key: string) => onTabChange(key as BrandTab)} />
        <SearchAndFilterWrapper show={isBrandsTab}>
          <SearchBarWrapper data-testid="search-wrapper">
            <SearchBar onChange={onFilterBrands} />
          </SearchBarWrapper>
          <Filters
            key={`filters-state-${filterStateCounter}`}
            hasSelectedFilters={hasSelectedFilters}
            clearFilters={clearFilters}
            onFilterPlan={onFilterPlans}
            onFilterSpend={onFilterSpend}
          />
        </SearchAndFilterWrapper>
        <TableWrapper>
          <HideCard hide={!isBrandsTab} useContainerDimension>
            <BrandListBrands {...props} />
          </HideCard>
          <HideCard hide={!isFavoritesTab} useContainerDimension>
            <BrandListFavorites {...props} />
          </HideCard>
        </TableWrapper>
      </BrandListTablesContainer>
    </BrandListWrapper>
  );
};

export default BrandListView;
