import { Modal } from '@innovationdepartment/proxima-ui';
import DeleteConfirmationView from './DeleteConfirmation.View';

export type DisconnectConfirmationProps = {
  open: boolean;
  close: () => void;
  confirm: () => void;
  brandName: string;
  isLoading: boolean;
};
const DeleteConfirmation = ({
  open,
  confirm,
  close,
  brandName,
  isLoading,
}: DisconnectConfirmationProps) => (
  <Modal noPadding={false} open={open} title="Delete brand">
    <DeleteConfirmationView
      confirm={confirm}
      close={close}
      brandName={brandName}
      isLoading={isLoading}
    />
  </Modal>
);

export default DeleteConfirmation;
