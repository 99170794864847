import { BrandInfoProps } from 'types/brandOverview';
import { formatCurrency } from 'utils';
import PageLayoutSectionView from '../../PageSectionLayout.View';
import LoadingSpinner from 'ui/LoadingSpinner';

const BrandMetricsView = ({ last30DaysOfAllBrandSpend, apiLoading }: BrandInfoProps) => (
  <PageLayoutSectionView
    alignRight
    sections={[
      {
        label: 'Last 30 days Meta spend',
        content: apiLoading.campaigns ? (
          <LoadingSpinner />
        ) : (
          formatCurrency(last30DaysOfAllBrandSpend ?? 0)
        ),
      },
      // {
      //   label: 'Last 30 days store revenue',
      //   content: formatCurrency(spend.last30DaysSpend?.shopify ?? 0), // TODO: change accordingly
      // },
    ]}
  />
);

export default BrandMetricsView;
