import { create } from 'zustand';
import pick from 'lodash/pick';

import {
  AdAccount,
  AdAccountStatus,
  AdAccountStore,
  allowedKeys,
} from 'types/stores/ad-account-store';
import { IntegrationType } from 'types/integrations';

const useAdAccountStore = create<AdAccountStore>((set, get) => ({
  metaAdAccount: null,
  tiktokAdAccount: null,
  clear: (type: IntegrationType) =>
    set(() => {
      let store: Partial<AdAccountStore> = {};

      if (type === IntegrationType.Facebook) {
        store = { metaAdAccount: null, metaAdAccountsList: [] };
      }

      if (type === IntegrationType.TikTok) {
        store = { tiktokAdAccount: null, tiktokAdAccountsList: [] };
      }

      return store;
    }),
  updateAdAccount: (adAccount: Partial<AdAccount>, type: IntegrationType) =>
    set((prevState) => {
      const newAdAccount = pick(adAccount, allowedKeys) as AdAccount;
      let store: Partial<AdAccountStore> = {};

      if (type === IntegrationType.Facebook) {
        store = {
          metaAdAccount: newAdAccount,
        };
        if (prevState.metaAdAccount)
          store.metaAdAccount = { ...prevState.metaAdAccount, ...newAdAccount };
      }

      if (type === IntegrationType.TikTok) {
        store = {
          tiktokAdAccount: newAdAccount,
        };
        if (prevState.tiktokAdAccount)
          store.tiktokAdAccount = { ...prevState.tiktokAdAccount, ...newAdAccount };
      }

      return store;
    }),
  // list values
  metaAdAccountsList: [],
  tiktokAdAccountsList: [],
  getActiveAdAccountFromList: (type: IntegrationType) => {
    if (type === IntegrationType.Facebook)
      return get().metaAdAccountsList.find(
        (adAccount) => adAccount.status === AdAccountStatus.Active,
      );

    if (type === IntegrationType.TikTok)
      return get().tiktokAdAccountsList.find(
        (adAccount) => adAccount.status === AdAccountStatus.Active,
      );

    return undefined;
  },
  updateAdAcountsList: (adAccountsList: AdAccount[], type: IntegrationType) => {
    if (type === IntegrationType.Facebook) set({ metaAdAccountsList: adAccountsList });
    if (type === IntegrationType.TikTok) set({ tiktokAdAccountsList: adAccountsList });
  },
}));

export default useAdAccountStore;
