import { useAuth0 } from '@auth0/auth0-react';
import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk';

/* only meant to be used with anything inside  */
const FeatureFlagProvider: React.FC<{}> = ({ children }) => {
  const { isLoading, user } = useAuth0();

  if (isLoading) return null;

  if (!user) return <>{children}</>;

  const providerState: ProviderConfig = {
    clientSideID: import.meta.env.VITE_LD_CLIENT_SIDE_ID,
    context: {
      kind: 'user',
      key: user.sub,
      name: user.name,
    },
    options: {
      bootstrap: 'localStorage',
      streaming: false, // prevents LD to change in real time when flags are updated
    },
    timeout: 4999,
  };

  return <LDProvider {...providerState}>{children}</LDProvider>;
};

export default FeatureFlagProvider;
