import { Divider, SpaceDivider, Text } from '@innovationdepartment/proxima-ui';
import ContentSection from 'ui/ContentSection';

type ContentContainerProps = {
  title: string;
  divider?: boolean;
};
const ContentContainer: React.FC<ContentContainerProps> = ({ children, title, divider = true }) => (
  <ContentSection>
    <Text variant="body1Semibold">{title}</Text>
    {divider ? <Divider margin={16} /> : <SpaceDivider y={2} />}
    {children}
  </ContentSection>
);

export default ContentContainer;
