import { Button } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import FBTable from '@innovationdepartment/proxima-fbtable';
import ButtonGroup from 'ui/ButtonGroup';
import { FacebookTableViewProps } from 'types/fbTable';
import FacebookTableHeader from './FacebookTableHeader';
import FacebookTableSearchFilter from './FacebookTableSearchFilter';
import fbTableCellRender from './fbTableCellRender';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 8px 16px;
  height: 100%;
  overflow-y: hidden;
`;

const FacebookTableView = (props: FacebookTableViewProps) => {
  const {
    tab,
    adAccount,
    initialLoading,
    lastUpdate,
    loading,
    dateRange,
    onDateRangeChange,
    onTabClick,
    onRowSelect,
    campaigns,
    adsets,
    ads,
    onNext,
    onPrevious,
  } = props;

  return (
    <Container>
      <FacebookTableHeader loading={initialLoading} adAccount={adAccount} lastUpdate={lastUpdate} />
      <FacebookTableSearchFilter onDateRangeChange={onDateRangeChange} dateRange={dateRange} />
      <FBTable
        loading={loading}
        tab={tab}
        campaigns={campaigns}
        adsets={adsets}
        ads={ads}
        onTabClick={onTabClick}
        onRowSelect={onRowSelect}
        cellRender={fbTableCellRender}
      />
      <ButtonGroup style={{ marginTop: 16 }}>
        {onPrevious && <Button label="Previous" onClick={onPrevious} />}
        {onNext && <Button label="Next" onClick={onNext} />}
      </ButtonGroup>
    </Container>
  );
};

export default FacebookTableView;
