import { useEffect, useState } from 'react';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useBrandStore } from 'stores';
import { useProximaSDK, useShowSpinner } from 'hooks';
import { IntegrationsStoreLoader } from 'components/Loaders';

const BrandLoader = () => {
  const navigate = useNavigate();
  const { brandId } = useParams();

  const { brand } = useBrandStore();
  const brandApi = useProximaSDK('BrandsApi');
  const { updateBrandStore } = useBrandStore();
  const { showToaster } = useToaster();

  const [loading, setLoading] = useState(true);

  useShowSpinner({ show: loading });

  /* return to brand selection */
  if (!brandId) {
    navigate('/brands');
    return <></>;
  }

  const showError = (msg?: string) => {
    const errorMsg = msg ?? 'Something went wrong, please try again';
    showToaster({ message: errorMsg, variant: 'error' });
  };

  /* let's fetch brand info if all went well */
  useEffect(() => {
    const fetchBrandInfo = async () => {
      /* don't fetch brand data if id is similar than what's in store */
      if (brandId === brand?.brandId) {
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const response = await brandApi.getBrand({ brandId });

        if (response.status === 200) {
          const brandData = response.data;
          updateBrandStore(brandData, true);
        } else {
          showError();
        }
      } catch (err) {
        showError();
        navigate('/brands', { replace: true });
      }
      setLoading(false);
    };

    fetchBrandInfo();
  }, [brandId]);

  if (loading) return <></>;

  return (
    <IntegrationsStoreLoader>
      <Outlet />
    </IntegrationsStoreLoader>
  );
};

export default BrandLoader;
