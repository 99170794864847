import React from 'react';
import styled from '@emotion/styled';
import { FormField, Text } from '@innovationdepartment/proxima-ui';
import { CreateSeedAudiencesFormSchema, CreateSeedAudiencesNamesProps, Tab } from 'types/audiences';
import LoadingSpinner from 'ui/LoadingSpinner';
import { useFormState } from 'react-final-form';
import ContentContainer from '../ContentContainer';

const FieldsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 8px 0px;

  & > div {
    margin-top: 4px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const adornments: { [key in Tab]?: JSX.Element } = {
  colal: (
    <Text variant="body2Medium" color="neutral40">
      Proxima-
    </Text>
  ),
};

const CreateSeedAudienceNamesView = (props: CreateSeedAudiencesNamesProps) => {
  const { loading, canCreate, tab } = props;
  const { values } = useFormState<CreateSeedAudiencesFormSchema>();
  const { galaxies, names } = values;

  const startAdornment = adornments[tab];

  const fields = names?.map((name, index) => (
    <React.Fragment key={`names[${index}]`.toString()}>
      <Text variant="body2Semibold" color="neutral70">
        {galaxies[index].flavor.name.split(' - ')[0]}
      </Text>
      <FormField
        startAdornment={startAdornment}
        fullWidth
        disabled={!canCreate}
        type="input"
        name={`names[${index}].name`}
        placeholder="Enter a name for this audience"
      />
    </React.Fragment>
  ));

  return (
    <ContentContainer title="Audience naming">
      <FieldsWrapper>{loading ? <LoadingSpinner /> : fields}</FieldsWrapper>
    </ContentContainer>
  );
};

export default CreateSeedAudienceNamesView;
