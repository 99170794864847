import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import FBTable from '@innovationdepartment/proxima-fbtable';

type CellRender = PropsOf<typeof FBTable>['cellRender'];

const StatusOrb = styled.div<{ status: 'ACTIVE' | 'PAUSED' | 'OFF' }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  ${({ status }) => {
    if (status === 'ACTIVE') return 'background: #00AD78;';
    return 'background: #ADBBBB;';
  }}
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const fbTableCellRender: CellRender = (props) => {
  const { column, value } = props;

  if (column === 'effectiveStatus') {
    const status = value as 'ACTIVE' | 'PAUSED' | 'OFF';
    const isActive = status === 'ACTIVE';

    return (
      <StatusWrapper>
        <StatusOrb status={status} />
        <span>{isActive ? 'Active' : 'Inactive'}</span>
      </StatusWrapper>
    );
  }

  return <>{value}</>;
};

export default fbTableCellRender;
