import { Divider, Text } from '@innovationdepartment/proxima-ui';
import { CreateSeedAudiencesDataSourceProps } from 'types/audiences';
import ContentContainer from '../ContentContainer';
import { ContentWrapper } from './Layout';
import CreateSeedAudiencesDataSourcesCategoryShoppers from './CreateSeedAudiencesDataSources.CategoryShoppers';
import CreateSeedAudiencesDataSourcesCOLALs from './CreateSeedAudiencesDataSources.COLALs';
import CreateSeedAudiencesDataSourceConnectionBadge from './CreateSeedAudiencesDataSource.ConnectionBadge';
import CreateSeedAudiencesDataSourcesBrandCategory from './CreateSeedAudiencesDataSources.BrandCategory';

const CreateSeedAudiencesDataSourcesView = (
  props: CreateSeedAudiencesDataSourceProps & { disabled: boolean },
) => {
  const { disabled, tab } = props;

  const isConnected = !disabled;

  const isCategoryShoppers = tab === 'categoryShoppers';
  const isCOLALs = tab === 'colal';

  return (
    <ContentContainer title="Data sources">
      <ContentWrapper>
        <ContentWrapper flow="column">
          <Text variant="body2Semibold">Shopify integration</Text>
          <CreateSeedAudiencesDataSourceConnectionBadge isConnected={isConnected} />
        </ContentWrapper>
        <CreateSeedAudiencesDataSourcesBrandCategory {...props} />
      </ContentWrapper>
      <Divider margin={16} />
      {isCategoryShoppers && <CreateSeedAudiencesDataSourcesCategoryShoppers {...props} />}
      {isCOLALs && <CreateSeedAudiencesDataSourcesCOLALs {...props} />}
    </ContentContainer>
  );
};

export default CreateSeedAudiencesDataSourcesView;
