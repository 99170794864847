import SpendActivityFilterView from './SpendActivityFilter.View';

type SpendActivityFilterProps = {
  onFilterSpend: (selectedItems: string[]) => void;
};

const SpendActivityFilter = ({ onFilterSpend }: SpendActivityFilterProps) => (
  <SpendActivityFilterView onFilterSpend={onFilterSpend} />
);

export default SpendActivityFilter;
