import { create } from 'zustand';
import _ from 'lodash';
import { createJSONStorage, persist } from 'zustand/middleware';
import { BrandStore, Brand } from 'types/stores/brand-store';

const emptyState = { brandId: '', name: '', domain: '', createdAt: undefined };
const allowedKeys = Object.keys(emptyState);
const persistKeyName = 'brand-store';

const useBrandStore = create<BrandStore, [['zustand/persist', BrandStore]]>(
  persist(
    (set) => ({
      brand: emptyState,
      clearBrandStore: () => set({ brand: emptyState }),
      updateBrandStore: (brand: Brand, clear = false) => {
        const pickKeys = _.pick(brand, allowedKeys);
        const selectedBrand = { ...pickKeys };

        if (clear) return set({ brand: selectedBrand as BrandStore['brand'] });

        return set((prevState) => ({ brand: { ...prevState.brand, ...selectedBrand } }));
      },
    }),
    { name: persistKeyName, storage: createJSONStorage(() => window.sessionStorage) },
  ),
);

export default useBrandStore;
