import { useBrandStore, useAdAccountStore } from 'stores';
import { AdManager, PAGE_DEFAULT_SIZE, PaginationSettings } from 'types/fbTable';
import { AdAccount } from 'types/stores/ad-account-store';
import prepareQueryString from 'utils/prepareQueryString';
import useHandleApi from './useHandleApi';
import { IntegrationType } from 'types/integrations';

type HandleGetRequestOpts = { path: AdManager; url?: never } | { path?: never; url: string };

// TODO(Jenky): Refactor to use proxima sdk
const useAdManager = () => {
  const { loading, handleApi } = useHandleApi();
  const adAccountStore = useAdAccountStore();
  const { brand } = useBrandStore();

  const retrieveAdAccountFromApi = async (brandId: string) => {
    const response = await handleApi({ endpoint: `/brand/${brandId}/fb/adAccount` });

    if (response.error) {
      return null;
    }

    const adAccounts = (response.data || []) as AdAccount[];

    adAccountStore.updateAdAcountsList(adAccounts, IntegrationType.Facebook);

    const activeAdAccount = adAccounts.find((adAccount) => adAccount.status === 'active');

    return activeAdAccount ?? null;
  };

  const getMetaAdAccount = async (opts?: { refresh?: boolean; brandId: string }) => {
    const { refresh = false, brandId = brand.brandId } = opts ?? {};
    if (refresh) adAccountStore.clear(IntegrationType.Facebook);
    else {
      const currentActiveAdAccount = adAccountStore.getActiveAdAccountFromList(
        IntegrationType.Facebook,
      );
      if (currentActiveAdAccount) return currentActiveAdAccount;
    }

    const activeAdAccount = await retrieveAdAccountFromApi(brandId);

    if (activeAdAccount) adAccountStore.updateAdAccount(activeAdAccount, IntegrationType.Facebook);

    return activeAdAccount;
  };

  const getAdAccountBatch = async (brandIds: string[]) => {
    const searchQuery = new URLSearchParams();
    searchQuery.append('brandId', brandIds.join(','));

    return handleApi({
      endpoint: `/integration/fb/adAccount/batch?${searchQuery.toString()}`,
    });
  };

  const refreshAdAccount = async ({ brandId }: { brandId: string }) =>
    getMetaAdAccount({ refresh: true, brandId });

  const getApiUrl = async () => {
    const adAccount = await getMetaAdAccount();
    return `/brand/${brand.brandId}/fb/adAccount/${adAccount?.accountId}`;
  };

  const handleGetRequest = async (
    getRequestOpts: HandleGetRequestOpts,
    paginationOpts: PaginationSettings,
  ) => {
    const { path, url } = getRequestOpts;
    const { queryItems, ...restOpts } = paginationOpts;
    const queryString = prepareQueryString({
      pageSize: PAGE_DEFAULT_SIZE,
      pageNumber: 1,
      ...restOpts,
      ...queryItems,
    });

    let endpoint = url;
    if (path) {
      const apiUrl = await getApiUrl();
      endpoint = `${apiUrl}/${path}`;
    }

    return handleApi({ endpoint: `${endpoint}?${queryString}` });
  };

  const getCampaigns = async (paginationOpts: PaginationSettings) => {
    const response = await handleGetRequest({ path: 'campaign' }, paginationOpts);
    return response;
  };

  const getAdSets = async (paginationOpts: PaginationSettings) => {
    const response = await handleGetRequest({ path: 'adset' }, paginationOpts);
    return response;
  };

  const getAds = async (paginationOpts: PaginationSettings) => {
    const response = await handleGetRequest({ path: 'ad' }, paginationOpts);
    return response;
  };

  const getPerformanceAds = async (paginationOpts: PaginationSettings) => {
    const response = await handleGetRequest({ url: '/fb/ads' }, paginationOpts);
    return response;
  };

  const getLastUpdatedAt = async () => {
    const adAccount = await getMetaAdAccount();
    if (adAccount?.accountId === undefined) return '-';

    const response = await handleApi({
      endpoint: `/brand/${brand.brandId}/integration/facebook/state/${adAccount?.accountId}/last-update`,
      skipErrorToaster: true,
    });

    // TODO(Jenky): Address this differently
    if (response.error) {
      return '-';
    }

    return response.data.lastUpdatedAt;
  };

  return {
    loading,
    retrieveAdAccountFromApi,
    refreshAdAccount,
    getLastUpdatedAt,
    getAdAccountBatch,
    getMetaAdAccount,
    getPerformanceAds,
    getCampaigns,
    getAdSets,
    getAds,
  };
};

export default useAdManager;
