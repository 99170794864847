import { Table, Button, TableData } from '@innovationdepartment/proxima-ui';
import { BrandListProps } from 'types/brandList';
import ButtonGroup from 'ui/ButtonGroup';
import NoSearchResult from './NoSearchResult';
import BrandListResultsWrapper from './BrandList.ResultsWrapper';

const BrandListBrands = <T extends TableData>(props: BrandListProps<T>) => {
  const { brandTableRef: ref, brands, config, onNext, onPrevious, loading } = props;

  const hasBrands = brands.length > 0;

  let content = <Table data={brands} tableWrapperRef={ref} config={config as any} />;
  if (!loading && !hasBrands) content = <NoSearchResult />;

  return (
    <BrandListResultsWrapper>
      {content}
      <ButtonGroup>
        {onPrevious && <Button label="Previous" onClick={onPrevious} />}
        {onNext && <Button label="Next" onClick={onNext} />}
      </ButtonGroup>
    </BrandListResultsWrapper>
  );
};

export default BrandListBrands;
