import React, { useEffect } from 'react';
import styled from '@emotion/styled';

const Scroller = styled.div`
  width: 10px !important;
  height: 10px !important;
  position: absolute;
  bottom: 0;
`;

type InfiniteScrollProps = {
  onInfiniteScroll?: () => void;
};

const InfiniteScroll = (props: InfiniteScrollProps) => {
  const { onInfiniteScroll } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onInfiniteScroll?.();
            observer.disconnect();
          }
        });
      },
      { root: null, rootMargin: '100px', threshold: 0.01 },
    );

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer?.unobserve(ref.current as Element);
    };
  }, []);

  return <Scroller ref={ref} />;
};

export default InfiniteScroll;
