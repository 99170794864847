import styled from '@emotion/styled';
import { FormField } from '@innovationdepartment/proxima-ui';
import LoadingSpinner from 'ui/LoadingSpinner';
import { CreateSeedAudiencesLookalikeSizeViewProps } from 'types/audiences';
import ContentContainer from '../ContentContainer';

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  & > * {
    flex: 1;
    max-width: 284px;
  }
`;

const CreateSeedAudiencesLookalikeSizeView = ({
  disabled,
  loading,
  options,
}: CreateSeedAudiencesLookalikeSizeViewProps) => (
  <ContentContainer title="Lookalike size">
    <SelectWrapper>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormField
            disabled={disabled}
            name="lookalikeSize"
            type="select"
            fullWidth
            options={options}
            placeholder="Select a lookalike size"
          />
          {/* helps to fix layout */}
          <div />
        </>
      )}
    </SelectWrapper>
  </ContentContainer>
);

export default CreateSeedAudiencesLookalikeSizeView;
