import { Form } from '@innovationdepartment/proxima-ui';
import { useEffect, useState } from 'react';
import { useAdAccountStore, useIntegrationsStore } from 'stores';
import {
  CreateSeedAudienceSchema,
  CreateSeedAudiencesFormSchema,
  FlavorCategorySchema,
  CategoryShoppersProps,
} from 'types/audiences';
import { useAudiencesApi, useShowSpinner } from 'hooks';
import { IntegrationType } from 'types/integrations';
import CategoryShoppersView from './CategoryShoppers.View';
import { PropsOf } from '@emotion/react';
import { SeedAudienceType } from '@innovationdepartment/proxima-sdk-axios';

const CategoryShoppers = (props: CategoryShoppersProps) => {
  const { show, galaxies: allGalaxies, onClose, onSeedAudiencesCreation } = props;
  const { loading: flavorCategoryLoading, getBrandFlavorCategory } = useAudiencesApi();
  const { createSeedAudience } = useAudiencesApi();
  const [submitting, setSubmitting] = useState(false);
  const [flavorCategory, setFlavorCategory] = useState<FlavorCategorySchema | undefined>(undefined);

  const { getAllIntegrations } = useIntegrationsStore();
  const { getActiveAdAccountFromList } = useAdAccountStore();

  useShowSpinner({ show: submitting });

  /* get only active shopify integration */
  const [shopifyIntegration] = getAllIntegrations({ type: IntegrationType.Shopify, active: true });
  const adAccount = getActiveAdAccountFromList(IntegrationType.Facebook);

  const galaxies = allGalaxies.filter((galaxy) => !galaxy.isInternal);

  const initialValues: CreateSeedAudiencesFormSchema = {
    galaxies,
    lookalikeSize: '0.1' as unknown as number,
    integrationType: IntegrationType.Facebook,
    names: [], // this is set in CreateSeedAudiencesNames.Container.tsx
    referenceBrandProportionTarget: 25,
  };

  const canCreate = initialValues.galaxies.length > 0 && Boolean(adAccount);
  const isLoading = flavorCategoryLoading;

  type FormProps = PropsOf<typeof Form<CreateSeedAudiencesFormSchema>>;

  const onSubmit: FormProps['onSubmit'] = async (formData, formApi) => {
    if (!canCreate) return;
    if (!adAccount) return;

    let referenceBrandTargetPercentage: string | number | undefined =
      formData.referenceBrandProportionTarget ?? '25';

    if (!shopifyIntegration) {
      /* if not connected to shopify, remove target percentage value */
      referenceBrandTargetPercentage = undefined;
    } else {
      /* convert to percentage */
      referenceBrandTargetPercentage = +referenceBrandTargetPercentage / 100;
    }

    setSubmitting(true);
    const data: CreateSeedAudienceSchema = {
      galaxyIds: formData.galaxies.map((galaxy) => galaxy.id),
      integrations: [
        {
          adAccountId: adAccount.accountId,
          integrationType: formData.integrationType,
          size:
            formData.lookalikeSize ??
            0.01 /* unlikely to get here without validation but TS complains */,
        },
      ],
      seedAudienceType: SeedAudienceType.SelfService,
      referenceBrandProportionTarget: referenceBrandTargetPercentage,
      names: formData.names,
    };

    await createSeedAudience(data);

    onSeedAudiencesCreation();
    onClose();

    setSubmitting(false);

    /* reset form state after creation */
    formApi.reset();
  };

  const validate: FormProps['validate'] = (formData) => {
    type ValidationErrorValue = string | string[] | { name: string }[];
    type ValidationError = { [key in keyof typeof initialValues]?: ValidationErrorValue };
    const errors: ValidationError = {};

    const { referenceBrandProportionTarget } = formData;

    const proportionTargetString = +(referenceBrandProportionTarget ?? '');

    const proportionTargetOutOfValidRange =
      proportionTargetString > 100 || proportionTargetString < 0;

    if (proportionTargetOutOfValidRange)
      errors.referenceBrandProportionTarget = 'Must be between 0-100%';

    /* validate names of seed audiences */
    formData.names?.forEach((name, index) => {
      const setError = (error: string) => {
        if (!errors.names) errors.names = [];
        /* lets set the errors in the same format as the form data */
        (errors.names as { name: string }[])[index] = { name: error };
      };

      if (!name.name) setError('Please enter a valid name.');
      /* TODO(Jenky): discuss with Product */ else if (name.name.length > 100)
        setError('Seed audience name is too long (max 100 chars)');
      else if (name.name.length < 10) setError('Seed audience name is too short (min 10 chars)');
    });

    return errors;
  };

  /* fetches brand flavor category */
  useEffect(() => {
    if (show) {
      const fetchFlavorCategory = async () => {
        const flavorCategoryResult = await getBrandFlavorCategory();

        setFlavorCategory(flavorCategoryResult);
      };
      fetchFlavorCategory();
    }
  }, [show]);

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <CategoryShoppersView
        {...props}
        galaxies={galaxies}
        canCreate={canCreate}
        loading={isLoading}
        submitting={submitting}
        flavorCategory={flavorCategory}
      />
    </Form>
  );
};

export default CategoryShoppers;
