import { useEffect, useMemo, useRef, useState } from 'react';
import { BrandItem } from '@innovationdepartment/proxima-sdk-axios';
import * as date from 'date-fns';

import { AdData } from 'types/fbTable';

import useProximaSDK from './useProximaSDK';
import useAdManager from './useAdManager';
import useAdManagerNavigation from './useAdManagerNavigation';
import { CreativeAd } from 'components/PerformanceAds/Layout/types';
import useQuery from './useQuery';

type Filters = Map<string, string | string[]>;
type AvailableFilters = 'startDate' | 'endDate' | 'brandId';

const usePerformanceAdManager = () => {
  const brandsApi = useProximaSDK('BrandsApi');
  const { getQueryParams, setQueryParams } = useQuery();
  const queryParams = getQueryParams<AvailableFilters>();
  const loadedRef = useRef<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const isNewBatchRef = useRef<boolean>(true);
  const { getPerformanceAds } = useAdManager();
  const [filters, setFilters] = useState<Filters>(new Map(Object.entries(queryParams)));
  const [brands, setBrands] = useState<Map<string, BrandItem>>();
  const [ads, setAds] = useState<AdData>({ data: [], summary: undefined });
  /* due to closure issues, we use a ref to access the newer values on onRequestNextBatch */
  const filterRef = useRef<typeof filters>(filters);

  const getMethods = useMemo(
    () => ({
      ads: getPerformanceAds,
    }),
    [],
  );

  const {
    ads: nextAdsBatch,
    loaded,
    loading,
    pages,
    dateRange,
    onDateRangeChange,
    onPaginateNext,
  } = useAdManagerNavigation('ads', getMethods, {
    /* TODO(Jenky): filters should be passed here when more filters are added, during the initial call... or defer the initial call and do it here instead  */
    skipLastUpdate: true,
  });

  useEffect(() => {
    const parent = document.getElementsByTagName('table')[0]?.parentElement as HTMLDivElement;
    // @ts-ignore
    tableRef.current = parent;
  }, []);

  /*
    request new data when filters are changed
    PSA: useEffect order matters
  */
  useEffect(() => {
    if (!loadedRef.current) return;

    isNewBatchRef.current = true;
    const queryItems = { ...Object.fromEntries(filters) };
    onPaginateNext('ads', { pageNumber: 1, queryItems });
    /* temporary fix for infinite scrolling closure issue */
    filterRef.current = filters;
  }, [filters]);

  const processNewBatch = async (newBatch: AdData) => {
    /* if new batch, just return as is */
    if (isNewBatchRef.current) {
      isNewBatchRef.current = false;
      /* scroll to top when new search is made */
      tableRef.current?.scroll({ top: 0 });
      setAds({ data: newBatch.data, summary: newBatch.summary });
      return;
    }

    const newAds = [...ads.data, ...newBatch.data];
    const { summary } = ads;

    const newSummary = {
      costPerResult: (summary?.costPerResult ?? 0) + (newBatch.summary?.costPerResult ?? 0),
      cpc: (summary?.cpc ?? 0) + (newBatch.summary?.cpc ?? 0),
      impressions: (summary?.impressions ?? 0) + (newBatch.summary?.impressions ?? 0),
      spend: (summary?.spend ?? 0) + (newBatch.summary?.spend ?? 0),
    };

    setAds({ data: newAds, summary: newSummary });
  };

  /* merges ads on every new batch */
  useEffect(() => {
    if (loading) return;

    processNewBatch(nextAdsBatch);

    loadedRef.current = true;

    /* filter brands that we already have from other batch calls */
    const brandIds = new Set(nextAdsBatch.data.map((ad) => ad.brandId));
    brandIds.forEach((brandId) => {
      if (brands?.has(brandId)) brandIds.delete(brandId);
    });

    if ([...brandIds].length === 0) return;

    /* fetch new brands while more ads are loaded */
    const getBrandsFromApi = async () => {
      const response = await brandsApi.getBrandBatch({ brandId: [...brandIds].join(',') });
      if (response.status === 200) {
        setBrands((prevBrands) => {
          const newBrands = new Map<string, BrandItem>(prevBrands);
          response.data.forEach((brand) => newBrands.set(brand.brandId as string, brand));
          return newBrands;
        });
      }
    };

    getBrandsFromApi();
  }, [nextAdsBatch, loading]);

  const isLoading = loading || brandsApi.loading;

  /* format ads, include all information in mapping */
  const formattedAds = ads.data.map((ad) => ({
    ...ad,
    brand: brands?.get(ad.brandId),
    loading: brandsApi.loading,
  }));

  /* only for infinite scrolling */
  const onRequestNextBatch = async () => {
    let queryItems: Record<string, string | string[]> = {};
    if (filterRef.current) queryItems = { ...Object.fromEntries(filterRef.current) };
    onPaginateNext('ads', { queryItems });
  };

  const onApplyFilters = (updatedFilters: Record<string, string | string[] | undefined>) => {
    const newFilters = new Map(Object.entries(updatedFilters));
    const mergedFilters = new Map(filters);
    newFilters.forEach((value, key) => value !== undefined && mergedFilters.set(key, value));
    setQueryParams(Object.fromEntries(mergedFilters));
    setFilters(mergedFilters);
  };

  const onDateRange = (range: Parameters<typeof onDateRangeChange>[0]) => {
    onApplyFilters({
      startDate: date.format(range.startDate as Date, 'yyyy-MM-dd'),
      endDate: date.format(range.endDate as Date, 'yyyy-MM-dd'),
    });
  };

  return {
    loading: isLoading,
    loaded,
    onRequestNext: pages.ads.hasNext ? onRequestNextBatch : undefined,
    onApplyFilters,
    onDateRangeChange: onDateRange,
    dateRange,
    ads: {
      data: formattedAds as AdData<CreativeAd>['data'],
      summary: ads.summary,
    },
  };
};

export default usePerformanceAdManager;
