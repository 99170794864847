import { formatDate } from 'utils';
import LoadingSpinner from 'ui/LoadingSpinner';
import { FacebookTableViewProps } from 'types/fbTable';
import FacebookTableLastUpdatedAtView from './FacebookTable.LastUpdatedAt.View';

const FacebookTableLastUpdatedAt = ({
  lastUpdate,
  loading,
}: Pick<FacebookTableViewProps, 'lastUpdate' | 'loading'>) => {
  // Don't render if data is not found
  if (loading) return <LoadingSpinner size={16} />;

  if (new Date(`${lastUpdate}`).toString() === 'Invalid Date') return null;

  const lastUpdatedAt = `Updated ${formatDate(lastUpdate as string, true)} ET`;

  return <FacebookTableLastUpdatedAtView lastUpdatedAt={lastUpdatedAt} />;
};

export default FacebookTableLastUpdatedAt;
