import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';
import { LookalikeSizePreviewProps } from 'types/audiences';

const Lookalike = styled.div`
  position: relative;
  padding-left: 12px;

  display: flex;
  flex-flow: column;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    border-left: 4px solid ${colors.proximaGreen20};
    height: 100%;
    border-radius: 4px 0 0 4px;
  }
`;

const LookalikeSizePreviewLookalikeAudience = (props: LookalikeSizePreviewProps) => {
  const { size, galaxy } = props;

  const percentage = size !== undefined ? `${Number.parseInt(`${size * 100}`, 10)}%` : '-';

  const [name] = galaxy.flavor.name.split('-');

  return (
    <Lookalike key={galaxy.id}>
      <Text variant="body2Semibold">{name.trim()}</Text>
      <Text variant="body2" color="neutral60">
        Lookalike audience{percentage ? <>&nbsp;•&nbsp;{percentage}</> : undefined}
      </Text>
    </Lookalike>
  );
};

export default LookalikeSizePreviewLookalikeAudience;
