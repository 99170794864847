import styled from '@emotion/styled';
import { Text, colors } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 16px;
  gap: 8px;
  align-self: stretch;
  background: ${colors.neutral10};
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
  top: 64px;
  z-index: 4;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 630px;
  text-align: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const InsightsNoMetaIntegration = () => (
  <Container>
    <ContentContainer>
      <InnerContainer>
        <Text variant="h2">Meta not connected</Text>
        <Text variant="h6" isTermina={false}>
          No Meta integration detected. The client must connect their ad account to activate this
          feature.
        </Text>
      </InnerContainer>
    </ContentContainer>
  </Container>
);

export default InsightsNoMetaIntegration;
