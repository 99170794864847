import { Text } from '@innovationdepartment/proxima-ui';
import { CpaChangeCell, RoasChangeCell } from './Trends.ChangeCells';
import { formatMonthDayDate } from 'utils/formatDate';
import { InsightsColumnData, TrendsTableCellRendererProps } from 'types/insights';
import { dollarUnitSymbol, percentUnitSymbol } from 'components/Insights/insightsHelpers';

const trendsRowRenderer = (props: TrendsTableCellRendererProps<InsightsColumnData>) => {
  const { header, cell } = props;

  if (header === 'date')
    return (
      <Text variant="tag1Semibold">{formatMonthDayDate(cell as InsightsColumnData['date'])}</Text>
    );

  if (header === 'cpaChange')
    return <CpaChangeCell cpaChange={cell as InsightsColumnData['cpaChange']} />;

  if (header === 'roasChange')
    return <RoasChangeCell roasChange={cell as InsightsColumnData['roasChange']} />;

  return (
    <Text variant="body2Semibold">{`${dollarUnitSymbol(header!)}${Number(cell).toFixed(
      2,
    )}${percentUnitSymbol(header!)}`}</Text>
  );
};

export default trendsRowRenderer;
