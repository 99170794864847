import { useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import IMask from 'imask';
import { FormField, Text } from '@innovationdepartment/proxima-ui';
import {
  CreateSeedAudiencesCOLALFormSchema,
  CreateSeedAudiencesDataSourceProps,
} from 'types/audiences';
import { ContentWrapper, FieldWrapper } from './Layout';
import { useEffect, useReducer } from 'react';

const OPTIONS = [
  {
    label: 'Original source ID',
    value: 'original',
  },
  {
    label: 'Alternative ID',
    value: 'alternative',
  },
];

const CreateSeedAudiencesDataSourcesCOLALs = (props: CreateSeedAudiencesDataSourceProps) => {
  const { canCreate } = props;
  const form = useForm<CreateSeedAudiencesCOLALFormSchema>();
  const { values } = useFormState();
  const { brandId } = useParams<{ brandId: string }>();
  const { dataSource } = values;
  const [key, rerender] = useReducer(() => Date.now(), Date.now());

  const disableInput = dataSource === 'original';

  const swapBackToOriginal = values.brandId !== brandId && dataSource === 'original';

  useEffect(() => {
    form.batch(() => {
      form.change('brandId', brandId);
      form.resetFieldState('brandId');
      setTimeout(rerender);
    });
  }, [swapBackToOriginal]);

  return (
    <>
      <FormField
        type="radio"
        name="dataSource"
        options={OPTIONS.map((option) => ({ ...option, disabled: !canCreate }))}
        alignment="horizontal"
        variant="boxed"
      />
      <ContentWrapper gap={16}>
        <ContentWrapper flow="column">
          <Text variant="body2Semibold">Brand ID</Text>
          <FormField
            /* TODO(Jenky): figure out why it does not update on form.change */
            key={key}
            type="input"
            fullWidth
            name="brandId"
            disabled={disableInput}
          />
        </ContentWrapper>
        <ContentWrapper flow="column" style={{ flex: 0.45 }}>
          <Text variant="body2Semibold">Brand data cap</Text>
          <FieldWrapper shrink>
            <FormField
              type="input"
              fullWidth
              name="referenceBrandProportionTarget"
              format={(value: string) => {
                const mask = new IMask.MaskedNumber({});
                mask.resolve(`${value}`);
                return mask.value;
              }}
              endAdornment={
                <Text variant="body2" color="neutral70">
                  %
                </Text>
              }
            />
          </FieldWrapper>
        </ContentWrapper>
      </ContentWrapper>
    </>
  );
};

export default CreateSeedAudiencesDataSourcesCOLALs;
