const conversion: Record<number, string> = {
  4: 'Best',
  3: 'Good',
  2: 'Average',
  1: 'Below Average',
  0: 'Worst',
};
const reverseConversion: Record<number, string> = {
  0: 'Best',
  1: 'Good',
  2: 'Average',
  3: 'Below Average',
  4: 'Worst',
};

export default function formatQuentile(value: number, reverseOrder = false): string {
  return reverseOrder ? reverseConversion[value] : conversion[value];
}
