import { AudiencesIcon, Tabs } from '@innovationdepartment/proxima-ui';
import ModalBase from 'ui/ModalBase';
import { CreateSeedAudiencesModalViewProps, Tab } from 'types/audiences';
import CategoryShoppers from './CategoryShoppers';
import COLAL from './COLALs';

const CreateSeedAudiencesModalView = (props: CreateSeedAudiencesModalViewProps) => {
  const { show, onClose, tab, tabs, onTabChange } = props;

  return (
    <ModalBase
      open={show}
      onClose={onClose}
      icon={<AudiencesIcon />}
      title="Generate new audiences"
      header={{
        padding: 'small',
        bottomContent: (
          <Tabs tabs={tabs} onChange={(nextTab) => onTabChange(nextTab as Tab)} tab={tab} />
        ),
      }}
    >
      {tab === 'categoryShoppers' && <CategoryShoppers {...props} tab="categoryShoppers" />}
      {tab === 'colal' && <COLAL {...props} tab="colal" />}
    </ModalBase>
  );
};

export default CreateSeedAudiencesModalView;
