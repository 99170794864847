import styled from '@emotion/styled';
import { useFormState } from 'react-final-form';
import { Button, Divider, SpaceDivider } from '@innovationdepartment/proxima-ui';
import { AudienceSettingsModalViewProps } from 'types/audiences';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import { AudienceName, AudienceAutoUpdate, AudienceUpdateFrequency } from './Layout';

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 640px;
`;

const AudienceSettingsModalView = (props: AudienceSettingsModalViewProps) => {
  const { isSubmitting, onClose } = props;
  const formState = useFormState();
  const { valid } = formState;

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <ActionButtonsWrapper>
          <Button variant="outlined" color="secondary" label="Cancel" onClick={onClose} />
          <Button submit disabled={!valid || isSubmitting} label="Save changes" />
        </ActionButtonsWrapper>
      }
    >
      <ContentWrapper>
        <AudienceName />
        <Divider margin={16} />
        <AudienceAutoUpdate />
        <SpaceDivider />
        <AudienceUpdateFrequency />
      </ContentWrapper>
    </ModalBaseChildrenWrapper>
  );
};

export default AudienceSettingsModalView;
