function useSessionStorage() {
  return {
    setItem: (key: string, value: string, expireInMs?: number) => {
      window.sessionStorage.setItem(key, value);
      if (expireInMs) {
        const expire = new Date().getTime() + expireInMs;
        window.sessionStorage.setItem(`${key}_expire`, expire.toString());
      }
    },
    getItem: (key: string, remove = false) => {
      const expire = window.sessionStorage.getItem(`${key}_expire`);
      if (expire && new Date().getTime() > parseInt(expire, 10)) {
        window.sessionStorage.removeItem(key);
        window.sessionStorage.removeItem(`${key}_expire`);
        return null;
      }
      const value = window.sessionStorage.getItem(key);
      if (remove) {
        window.sessionStorage.removeItem(key);
        window.sessionStorage.removeItem(`${key}_expire`);
      }
      return value;
    },
  };
}

export default useSessionStorage;
