import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import PerformanceAdsTable from './Layout/PerformanceAdsTable';
import { PerformanceAdsViewProps } from './types';
import PerformanceAdsNavbar from './PerformanceAds.Navbar';
import PerformanceAdsFilters from './PerformanceAds.Filters';
import PerformanceAdsTiles from './Layout/PerformanceAdsTiles';

const ContentWrapper = styled.div`
  nav > button {
    border-color: ${colors.white};
    &:hover {
      background-color: ${colors.neutral100};
    }

    & * {
      color: ${colors.white};
    }
  }
`;

const AdViewWrapper = styled.div`
  height: calc(100vh - 68px - 69px);
`;

const PerformanceAdsView = (props: PerformanceAdsViewProps) => {
  const { view } = props;
  const isTiles = view === 'tiles';
  const isTable = view === 'table';
  return (
    <ContentWrapper>
      <PerformanceAdsNavbar {...props} />
      <PerformanceAdsFilters {...props} />
      <AdViewWrapper>
        {isTable && <PerformanceAdsTable {...props} />}
        {isTiles && <PerformanceAdsTiles {...props} />}
      </AdViewWrapper>
    </ContentWrapper>
  );
};

export default PerformanceAdsView;
