import styled from '@emotion/styled';
import { Text, FormField, MagicStars, colors } from '@innovationdepartment/proxima-ui';

const GradientWrapper = styled.div`
  background: linear-gradient(to bottom right, #1ce8ab, #5091f3);
  border-radius: 8px;
  display: grid;
  place-items: center;
  margin-top: 8px;
`;

const TextWrapper = styled.div`
  border-radius: 8px;
  padding: 12px 12px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background-color: ${colors.white};
  display: flex;
  align-items: start;

  svg:first-of-type {
    margin-right: 12px;
    width: 20px;
    height: auth;
  }
`;

const AudienceAutoUpdateView = () => (
  <>
    <FormField
      name="optedIntoUpdates"
      type="switch"
      position="right"
      label={
        <Text variant="body2Semibold" color="neutral80">
          Auto-refresh audiences
        </Text>
      }
      subLabel={
        <Text variant="body2" color="neutral60">
          Refresh your Proxima audiences to maintain high performance
        </Text>
      }
    />
    <GradientWrapper>
      <TextWrapper>
        <MagicStars />
        <Text variant="body2" color="neutral100">
          Refreshing will increase the life expectancy of an audience by approximately 3 weeks
        </Text>
      </TextWrapper>
    </GradientWrapper>
  </>
);

export default AudienceAutoUpdateView;
