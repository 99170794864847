import { Button } from '@innovationdepartment/proxima-ui';
import LoadingSpinner from 'ui/LoadingSpinner';
import { ModalBaseChildrenWrapper } from 'ui/ModalBase';
import CreateSeedAudiencesDataSources from '../Layout/CreateSeedAudiencesDataSources';
import CreateSeedAudienceAdPlatform from '../Layout/CreateSeedAudiencesAdPlatform';
import CreateSeedAudiencesLookalikeSize from '../Layout/CreateSeedAudiencesLookalikeSize';
import CreateSeedAudiencesNames from '../Layout/CreateSeedAudiencesNames';
import CreateSeedAudiencesModalSection from '../Layout/CreateSeedAudiencesModalSection';
import { COLALViewProps } from 'types/audiences';

const COLALView = (props: COLALViewProps) => {
  const { canCreate, galaxies, loading } = props;
  const isLoading = loading;

  let buttonLabel = 'Generate & send to platform';
  const isDisabled = !canCreate || isLoading;
  const hasAvailableGalaxies = galaxies.length > 0;

  if (!hasAvailableGalaxies) buttonLabel = 'Galaxies not availble';
  else if (!canCreate) buttonLabel = 'Ad account not connected';

  return (
    <ModalBaseChildrenWrapper
      submitButton={
        <Button
          submit
          disabled={isDisabled}
          startIcon={loading && <LoadingSpinner />}
          label={buttonLabel}
        />
      }
    >
      <CreateSeedAudiencesModalSection>
        <CreateSeedAudiencesDataSources {...props} />
        <CreateSeedAudienceAdPlatform />
        <CreateSeedAudiencesLookalikeSize loading={loading} disabled={isDisabled} />
      </CreateSeedAudiencesModalSection>
      <CreateSeedAudiencesModalSection>
        <CreateSeedAudiencesNames {...props} loading={loading} />
      </CreateSeedAudiencesModalSection>
    </ModalBaseChildrenWrapper>
  );
};

export default COLALView;
