import { useFormState } from 'react-final-form';
import { Text } from '@innovationdepartment/proxima-ui';

const AudienceNameView = () => {
  const { values } = useFormState();
  const { seedAudience } = values;

  if (!seedAudience) {
    return null;
  }

  return (
    <>
      <Text variant="body2Semibold" color="neutral80">
        Audience name
      </Text>
      <Text variant="body2Semibold" color="neutral100">
        {seedAudience.name}
      </Text>
    </>
  );
};

export default AudienceNameView;
