import * as dateFns from 'date-fns';

// TODO(Jenky): Disabled in favor of new logic (to discuss)
// const formatDate = (date: Date) =>
//   new Date(date).toLocaleDateString('default', {
//     month: 'long',
//     day: 'numeric',
//     year: 'numeric',
//   });

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  timeZone: 'EST',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const dateFormatter = new Intl.DateTimeFormat('en-US', dateFormatOptions).format;
const timeFormatter = new Intl.DateTimeFormat('en-US', timeFormatOptions).format;

const formatDate = (date: string | Date, includeTime = false) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  if (includeTime) return `${dateFormatter(newDate)} at ${timeFormatter(newDate)}`;

  return dateFormatter(newDate);
};

const formatMonthYearDate = (date: string | Date) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  const monthYearFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    year: '2-digit',
  };

  const monthYearFormatter = new Intl.DateTimeFormat('en-US', monthYearFormatOptions).format;
  return monthYearFormatter(newDate);
};

const formatMonthDayDate = (date: string | Date) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  const monthDayFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const monthDayFormatter = new Intl.DateTimeFormat('en-US', monthDayFormatOptions).format;
  return monthDayFormatter(newDate);
};

const formatDateFromIso = (date: string) => formatDate(new Date(date));

const formatDateTimeFromIso = (date: string) =>
  `${formatDateFromIso(date)} ${new Date(date).toLocaleTimeString()}`;

const formatDateToIsoWithoutTime = (date: Date) =>
  dateFns.formatISO(date, { representation: 'date' });

export {
  formatDate,
  formatMonthDayDate,
  formatMonthYearDate,
  formatDateFromIso,
  formatDateTimeFromIso,
  formatDateToIsoWithoutTime,
};
