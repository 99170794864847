import styled from '@emotion/styled';
import { colors, ProximaLogoWithTextBlack, Text } from '@innovationdepartment/proxima-ui';
import React from 'react';
import LockIcon from 'ui/LockIcon';
import ProximaBackground from 'ui/ProximaBackground';

const Container = styled.div`
  background-color: ${colors.white};
  border: 2px solid ${colors.neutral100};
  border-radius: 16px;
  box-shadow: 0px 2px 4px 2px rgba(25, 33, 33, 0.25);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 408px;
  margin: 0 auto;
  padding: 48px;
  text-align: center;
  width: 640px;
`;

const PaddedProximaLogo = styled(ProximaLogoWithTextBlack)`
  margin: 24px 0px 24px 72px;
`;

const NoAccessBox: React.FC = () => (
  <ProximaBackground showBackdrop showCurve={false}>
    <PaddedProximaLogo />
    <Container>
      <LockIcon />
      <Text variant="h4">You do not have access to Nova</Text>
      <Text variant="body1">Ask your manager for access.</Text>
    </Container>
  </ProximaBackground>
);

export default NoAccessBox;
