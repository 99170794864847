import { UserActionProps, User, UserStatus } from 'types/users';

import { formatDateFromIso, formatPhoneNumber, formatUserFullName } from 'utils';

import { Integration, IntegrationStatus } from 'types/integrations';

const USER_ROLES: { [key: string]: string } = {
  admin: 'Admin',
  readOnly: 'Read Only',
};

type UserFormatProps = { isFBPreviousOwner: boolean; isFBOwner: boolean; menu: UserActionProps };

export const formatUserData = (user: User & UserFormatProps) => {
  const {
    userId,
    isFBOwner,
    isFBPreviousOwner,
    email,
    firstName,
    lastName,
    phoneNumber,
    roleId,
    lastLoginAt,
    menu,
    associatedBrands,
  } = user;

  const role = USER_ROLES[roleId];

  const getMetaOwnership = () => {
    if (isFBOwner) return ' (Meta Owner)';
    if (isFBPreviousOwner) return ' (Previous Meta Owner)';
    return '';
  };

  return {
    ...user,
    userId,
    name: formatUserFullName({ firstName, lastName }),
    email: email ?? '[pending]',
    phoneNumber: phoneNumber ? `+1 ${formatPhoneNumber(phoneNumber)}` : '',
    role: `${role}${getMetaOwnership()}`,
    status: lastLoginAt ? UserStatus.Active : UserStatus.Invited,
    associatedBrands,
    lastLoginAt: lastLoginAt ? formatDateFromIso(lastLoginAt) : '-',
    menu,
  };
};

export const getCurrentAndPreviousMetaOwner = (
  integrations: Integration[],
  users: User[],
): [string?, string?] => {
  const userIds = users.map((user) => user.userId);
  const integrationMap = new Map<string, Integration>();
  integrations.forEach((integration) => {
    const addIntoMap =
      /* integration was previously added by current users */
      userIds.includes(integration.createdByUserId) &&
      /* integration is not in the map */
      !integrationMap.has(integration.createdByUserId);

    if (addIntoMap) {
      integrationMap.set(integration.createdByUserId!, integration);
    }
  });

  const [currentIntegration, previousIntegration] = integrationMap.values();

  let fbOwner = currentIntegration?.createdByUserId ?? '';
  let fbPreviousOwner = previousIntegration?.createdByUserId ?? '';

  const isConnected = currentIntegration?.status === IntegrationStatus.Active;

  if (!isConnected) {
    fbPreviousOwner = fbOwner;
    fbOwner = '';
  }

  return [fbOwner, fbPreviousOwner];
};
