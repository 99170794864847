import styled from '@emotion/styled';
import { Text, Tooltip, colors } from '@innovationdepartment/proxima-ui';
import React from 'react';
import { BrandListIntegrationStatusProps, IntegrationListStatus } from 'types/integrations';
import useBrandListIntegrationStatus from './useBrandListIntegrationStatus';

const statusColor = {
  connected: colors.green50,
  disconnected: colors.red50,
  incomplete: colors.yellow40,
  'non-started': 'transparent',
} satisfies { [key in IntegrationListStatus]?: string };

const getIndicatorColor = ({
  status,
  includeIndicator,
}: {
  status: IntegrationListStatus;
  includeIndicator: boolean;
}) => {
  const hasNeverIntegrated = status === IntegrationListStatus.NonStarted;

  if (includeIndicator && hasNeverIntegrated) {
    /* special condition for when it's not in brand list table, we want to show red dot regardless */
    status = IntegrationListStatus.Disconnected;
  }

  if (status === IntegrationListStatus.NonStarted) return { display: 'none' };

  const color = statusColor[status];

  return { backgroundColor: color };
};

const IndicatorDot = styled.div<{ status: IntegrationListStatus; includeIndicator: boolean }>`
  position: absolute;
  bottom: 4px;
  right: -2px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px
    ${({ includeIndicator }) => (includeIndicator ? colors.neutral5 : colors.white)};
  ${getIndicatorColor}
`;

const IconWrapper = styled.div`
  position: relative;
`;

const BrandListIntegrationStatus = (props: BrandListIntegrationStatusProps) => {
  const { includeIndicator = false, type } = props;
  const { icon, status, tooltipContent } = useBrandListIntegrationStatus(props);

  const tooltip = tooltipContent && (
    <Text variant="body2Semibold" color="white">
      {tooltipContent}
    </Text>
  );

  const Wrapper = tooltip
    ? ({ children }: any) => (
        <Tooltip arrow placement="top" title={tooltip}>
          {children}
        </Tooltip>
      )
    : React.Fragment;

  return (
    <Wrapper>
      <IconWrapper data-testId={`${type}-integration-status-indicator-${status}`}>
        {icon}
        <IndicatorDot status={status} data-testid={status} includeIndicator={includeIndicator} />
      </IconWrapper>
    </Wrapper>
  );
};

export default BrandListIntegrationStatus;
