import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

type Alignment = 'left' | 'right';
const AdsTableFooterWrapper = styled.div<{ alignment: Alignment }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: ${({ alignment }) => (alignment === 'left' ? 'flex-start' : 'flex-end')};
`;

const PerformanceAdsTableFooterRender = ({
  label,
  value,
  alignment = 'left',
}: {
  value: string;
  label?: string;
  alignment?: Alignment;
}) => (
  <AdsTableFooterWrapper alignment={alignment}>
    <Text color="bigBangBlack" variant="table1">
      {value}
    </Text>
    {label && <Text variant="table2">{label}</Text>}
  </AdsTableFooterWrapper>
);

export default PerformanceAdsTableFooterRender;
