import { IntegrationType } from 'types/integrations';
import ContentContainer from 'ui/Modals/CreateSeedAudiencesModal/Layout/ContentContainer';
import { FormField, RadioButtonGroupOption } from '@innovationdepartment/proxima-ui';

const AdPlatformSelection = () => {
  const platforms: RadioButtonGroupOption[] = [
    { label: 'Facebook', value: IntegrationType.Facebook },
  ];

  return (
    <ContentContainer title="Ad platform">
      <FormField name="integrationType" type="radio" options={platforms} />
    </ContentContainer>
  );
};

export default AdPlatformSelection;
