import { Text } from '@innovationdepartment/proxima-ui';
import pluralize from 'pluralize';
import { AudienceTableCellRendererProps, SeedAudienceTableRowProps } from 'types/audiences';
import VerticalWrapper from './AudienceRowCell.Wrapper';

const AudienceRowCellSeedAudience = ({
  row,
}: AudienceTableCellRendererProps<SeedAudienceTableRowProps>) => {
  const lookalikeText = pluralize('lookalike', row?.lookalikeAudiences?.length ?? 0, true);

  return (
    <VerticalWrapper>
      <Text variant="table1Link" color="neutral100">
        {row?.name}
      </Text>
      <Text variant="table2" color="neutral60">
        {lookalikeText}
      </Text>
    </VerticalWrapper>
  );
};

export default AudienceRowCellSeedAudience;
