import { useToaster } from '@innovationdepartment/proxima-ui';

const useCopyToClipboard = () => {
  const { showToaster } = useToaster({ delay: 2000 });

  const copyToClipboard = async (text: string, toasterMsg = 'Copied to clipboard!') => {
    if (!text) return;
    const type = 'text/plain';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await navigator.clipboard.write(data);

    showToaster({
      message: toasterMsg,
      variant: 'success',
    });
  };

  return copyToClipboard;
};

export default useCopyToClipboard;
