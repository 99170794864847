import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { backgroundSvgBubbles } from 'ui/ProximaBackground/constants';

const ProximaBackground = styled.div<{
  showCurve: boolean;
  showBackdrop: boolean;
  addBottomPadding?: boolean;
}>`
  flex: 1;
  overflow: auto;
  position: relative;
  border-radius: ${({ showCurve }) => (showCurve ? '16px 0 0' : '0px')};
  ${(props) => {
    if (props.showBackdrop) {
      return `
        background-image: url("${backgroundSvgBubbles}");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left top;
      `;
    }

    return `background-color: ${colors.neutral5};`;
  }}
  ${(props) => (props.addBottomPadding ? 'padding-bottom: 80px;' : '')}
`;

export default ProximaBackground;
