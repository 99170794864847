import { useIntegrationsStore } from 'stores';
import { CreateSeedAudiencesDataSourceProps } from 'types/audiences';
import { IntegrationType } from 'types/integrations';
import CreateSeedAudiencesDataSourcesView from './CreateSeedAudiencesDataSources.View';

const CreateSeedAudiencesDataSources = (props: CreateSeedAudiencesDataSourceProps) => {
  const { getAllIntegrations } = useIntegrationsStore();

  // get only active integrations
  const [shopifyIntegration] = getAllIntegrations({ type: IntegrationType.Shopify, active: true });

  return <CreateSeedAudiencesDataSourcesView {...props} disabled={!shopifyIntegration} />;
};

export default CreateSeedAudiencesDataSources;
