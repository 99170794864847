import { IntegrationType } from 'types/integrations';

export const allowedKeys = ['name', 'accountId', 'integrationId', 'status'] as const;

export enum AdAccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type AdAccountStore = {
  metaAdAccount: AdAccount | null;
  tiktokAdAccount: AdAccount | null;
  clear: (type: IntegrationType) => void;
  updateAdAccount: (adAccount: Partial<AdAccount>, type: IntegrationType) => void;

  metaAdAccountsList: AdAccount[];
  tiktokAdAccountsList: AdAccount[];
  getActiveAdAccountFromList: (type: IntegrationType) => AdAccount | undefined;
  updateAdAcountsList: (adAccounts: AdAccount[], type: IntegrationType) => void;
};

type Keys = (typeof allowedKeys)[number];

export type AdAccount = Omit<{ [key in Keys]: string }, 'status'> & { status: AdAccountStatus };
